import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ArrowForward2Icon from '../../../../assets/icons/arrow-forward2.svg';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import {
    useQuery,
    gql,
    useMutation,
    useLazyQuery
} from "@apollo/client";
import ReactMarkdown from "react-markdown";
import Carousel from 'react-elastic-carousel';

import axios from "axios";
import { useForm } from "react-hook-form";

import ErrorIcon from "../../../../assets/icons/error.svg";
import SettingsIcon from "../../../../assets/icons/settings.svg";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import Document1Icon from "../../../../assets/icons/document1.svg";
import PlusSquareIcon from "../../../../assets/icons/plus-square.svg";

const CREATEORDER = gql`
mutation CreateOrder ($amount: String!, $currency: String!, $quantity: String!) {
  createOrder(input:
        { data: {
            amount: $amount,
            currency: $currency,
            quantity: $quantity,
        }}) {
          order {
                id
            }
        }
      }
`;

const GETCALCULATIONCARDS = gql`
query GetCalculationCards($_locale: String!){
    calculationcards(locale: $_locale) {
        cardmaindata{
            title,
            description,
            image{
                formats,
                url
            }
        },
        details{
            title,
            description
        }
        type
    }
  }
`;

const GETPROMOCODE = gql`
query GetPromoCode($promoCode: String!){
    promocodes(where: {promoCode: $promoCode}) {
        importaccountnumber,
        exportaccountnumber,
    }
  }
`;


const Calculate = () => {
    let loc = useLocation();
    const history = useHistory();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [addPackageCondidition, setAddPackageCondidition] = useState(false);
    let [activePackType, setActivePackType] = useState(0);
    let [userData, setUserData] = useState(null);
    let [packageDatas, setPackageDatas] = useState([]);
    let [selectedPackeageType, setSelectedPackeageType] = useState(null);
    let [calculateCards, setCalculateCards] = useState(null);
    let [calculationData, setCalculationData] = useState([]);
    let [calculationTotal, setCalculationTotal] = useState(null);
    let [accountNumber, setAccountNumber] = useState('963310321');
    let [countryList, setCountryList] = useState([]);

    let [shipperZipCodeType, setShipperZipCodeType] = useState('number');
    let [recipientZipCodeType, setRecipientZipCodeType] = useState('number');


    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    
    const [onMutationSubmit, { loading, error, data }] = useMutation(CREATEORDER, {
        onCompleted: data => {
            // reset();
            // setShowSuccess(true);
            // setTimeout(() => {
            //     setShowSuccess(false);
            // }, 3000);
        }
    });

    useQuery(GETCALCULATIONCARDS, {
        variables: {
            _locale: lang
        },
        onCompleted(data) {
            console.log(data);
            if (data?.calculationcards) {
                setCalculateCards(data.calculationcards);
            }
        }
    });

    // https://restcountries.com/v3.1/all

    const [getPromo] = useLazyQuery(GETPROMOCODE, {
        onCompleted(data) {
            if (data.promoCodes?.length > 0) {
                console.log(userData);
                if (userData.Shipper.CountryCode === 'AZ') {
                    setAccountNumber(data.promoCodes[0].exportaccountnumber);
                } else if (userData.Recipient.CountryCode === 'AZ') {
                    setAccountNumber(data.promoCodes[0].importaccountnumber);
                }
            }
        }
    });

    useEffect(() => {
        registerPackSetValue('Weight.Value', 0.5);

        axios.get('https://restcountries.com/v3.1/all')
            .then(function (response) {
                // handle success

                response.data.sort(function (a, b) {
                    return a.name.common.localeCompare(b.name.common); //using String.prototype.localCompare()
                  });

                  console.log(response.data);
                  setCountryList(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }, [])

    useEffect(() => {
        let type = watch('Shipper.CountryCode') === 'GB' ? 'text' : 'number';
       setShipperZipCodeType(type)
    }, [watch('Shipper.CountryCode')])

    useEffect(() => {
        let type = watch('Recipient.CountryCode') === 'GB' ? 'text' : 'number';
       setRecipientZipCodeType(type)
    }, [watch('Recipient.CountryCode')])

    const { register: registerPack, setValue: registerPackSetValue, reset: resetPackageData, setValue: setPackageDetail, formState: { errors: errorsPack }, handleSubmit: handleSubmitPack, } = useForm();

    const onShipSubmit = (data) => {
        console.log(data);
        checkPostalCode(data);
        setAddPackageCondidition(true);
        setUserData(data);
        getPromo({
            variables: {
                promoCode: data.PromoCode || 'default'
            }
        });
    }

    const onPackSubmit = (data) => {
        console.log(data);
        setPackageDatas([
            ...packageDatas,
            {
                Weight: data.Weight.Value,
                Length: +data.RequestedPackages?.Dimensions?.Length,
                Height: +data.RequestedPackages?.Dimensions?.Height,
                Width: +data.RequestedPackages?.Dimensions?.Width
            }
        ]);

        setAddPackageCondidition(false);
        resetPackageData();
        setSelectedPackeageType(activePackType);
    }

    const deletePackageData = (i) => {
        packageDatas.splice(i, 1)
        setPackageDatas([...packageDatas]);
        if (!(packageDatas?.length > 0)) {
            setSelectedPackeageType(null);
        }
    }

    const editPackageData = (i) => {
        setAddPackageCondidition(true);
        setPackageDetail('Weight.Value', packageDatas[i].Weight)
    }

    const handleActivePackTypeChange = (i) => {
        if (selectedPackeageType === null) {
            setActivePackType(i);
        }
    }

    const calculate = () => {
        let calcData = [];

        let reqData = {
            "RateRequest": {
                "ClientDetails": null,
                "RequestedShipment": {
                    "DropOffType": "REGULAR_PICKUP",
                    "NextBusinessDay": "N",
                    "Ship": {
                        "Shipper": {
                            "StreetLines": "ADDRESS",
                            "City": userData.Shipper.City, //baku
                            "PostalCode": userData.Shipper.PostalCode,
                            "CountryCode": userData.Shipper.CountryCode
                        },
                        "Recipient": {
                            "StreetLines": "Main Street",
                            "City": userData.Recipient.City,
                            "PostalCode": userData.Recipient.PostalCode,
                            "CountryCode": userData.Recipient.CountryCode
                        }
                    },
                    "Packages": {
                        "RequestedPackages": []

                    },
                    "ShipTimestamp": new Date(),
                    "UnitOfMeasurement": "SI",
                    "Content": activePackType === 0 ? "DOCUMENTS" : "NON_DOCUMENTS",
                    "PaymentInfo": "DAP",
                    "Account": accountNumber
                }
            }
        }

        packageDatas.forEach((item, i) => {
            reqData.RateRequest.RequestedShipment.Packages.RequestedPackages.push(
                {
                    "@number": "1",
                    "Weight": {
                        "Value": item.Weight
                    },
                    "Dimensions": {
                        "Length": activePackType === 0 ? 35 : item.Length,
                        "Width": activePackType === 0 ? 27.5 : item.Width,
                        "Height": activePackType === 0 ? 2 : item.Height
                    }
                }
            )
        });

        axios.post(`${process.env.REACT_APP_BASE_URL_PURE}/utils/rate`, reqData)
            .then(function (response) {
                localStorage.setItem('orderData', JSON.stringify({...userData, PackageDatas: [...packageDatas]}));
                // let sum = 0;

                let data = response.data?.RateResponse?.Provider[0]?.Service;

                calcData.push(data);
                setCalculationData(calcData);

                // calcData.forEach(item => {
                //     sum += item?.TotalNet?.Amount;
                // })
                setTimeout(() => {
                    setCalculationTotal(data?.TotalNet?.Amount);
                }, 400);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const checkPostalCode = (e) => {
        const reqData = {
            "AddressValidateRequest": {
                "AddressValidate": {
                    "AddressType": "ORIGIN",
                    "PostalCode": "101000",
                    "City":"Moscow",
                    "CountryCode": "RU",
                    "StrictValidation": "N"  
                }            
                
            }
        }

        axios.put(`${process.env.REACT_APP_BASE_URL_PURE}/utils/validate`, reqData)
        .then(function (response) {
           
        })
        .catch(function (error) {
            console.log(error);
        });
    }


    return (
        <div className="page_data">
            <div className="calculate_container container">
                <p className="contact_form_head corp_head">DHL Express çatdırılma qiymətini hesablamaq üçün aşağıdakı məlumatları daxil edin</p>
                <form className="form_first_step_corp" onSubmit={handleSubmit(onShipSubmit)}>
                    <div className="form_item">
                        <p className="form_head">Haradan götürüləcək?</p>
                        <div className="three_inputs">
                            <div>
                                <select className={`form_input ${errors.Shipper?.CountryCode ? "error" : ""}`}
                                    {...register("Shipper.CountryCode", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })}>
                                    {countryList?.map((item, i) => {
                                        return (
                                            <option key={'countryCode-' + i} value={item.cca2}>{item.name.common}</option>
                                        )
                                    })}
                                </select>
                                {errors.Shipper?.CountryCode ?
                                    <p className="d-flex align-items--center">
                                        <img className="error_icon" src={ErrorIcon} alt="" />
                                        Form field error text.
                                    </p> : ''
                                }
                            </div>
                            <div>
                                <input className={`form_input ${errors.Shipper?.City ? "error" : ""}`} type="text" placeholder="Şəhər *"
                                    {...register("Shipper.City", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })} />
                                {errors.Shipper?.City ?
                                    <p className="d-flex align-items--center">
                                        <img className="error_icon" src={ErrorIcon} alt="" />
                                        Form field error text.
                                    </p> : ''
                                }
                            </div>
                            <div>
                                <input className={`form_input ${errors.Shipper?.PostalCode ? "error" : ""}`} type={shipperZipCodeType} placeholder="ZIP Kodu *"
                                    {...register("Shipper.PostalCode", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })} />
                                {errors.Shipper?.PostalCode ?
                                    <p className="d-flex align-items--center">
                                        <img className="error_icon" src={ErrorIcon} alt="" />
                                        Form field error text.
                                    </p> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form_item">
                        <p className="form_head">Hara çatdırılacaq?</p>
                        <div className="three_inputs">
                            <div>
                                <select className={`form_input ${errors.Recipient?.CountryCode ? "error" : ""}`}
                                    {...register("Recipient.CountryCode", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })}>
                                    {countryList?.map((item, i) => {
                                        return (
                                            <option key={'countryCode1-' + i} value={item.cca2}>{item.name.common}</option>
                                        )
                                    })}
                                </select>
                                {errors.Recipient?.CountryCode ?
                                    <p className="d-flex align-items--center">
                                        <img className="error_icon" src={ErrorIcon} alt="" />
                                        Form field error text.
                                    </p> : ''
                                }
                            </div>
                            <div>
                                <input className={`form_input ${errors.Recipient?.City ? "error" : ""}`} type="text" placeholder="Şəhər *"
                                    {...register("Recipient.City", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })} />
                                {errors.Recipient?.City ?
                                    <p className="d-flex align-items--center">
                                        <img className="error_icon" src={ErrorIcon} alt="" />
                                        Form field error text.
                                    </p> : ''
                                }
                            </div>
                            <div>
                                <input className={`form_input ${errors.Recipient?.PostalCode ? "error" : ""}`} type={recipientZipCodeType} placeholder="ZIP Kodu *"
                                    {...register("Recipient.PostalCode", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })} />
                                {errors.Recipient?.PostalCode ?
                                    <p className="d-flex align-items--center">
                                        <img className="error_icon" src={ErrorIcon} alt="" />
                                        Form field error text.
                                    </p> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form_item">
                        <p className="form_head">Telefon</p>
                        <div className="three_inputs">
                            <div className="number_input">
                                <select className="country_code">
                                    <option value="">994</option>
                                    <option value="">000</option>
                                    <option value="">000</option>
                                </select>
                                <input className="form_input number" type="text" placeholder="__ ___ __ __ *" />
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div className="form_item code_check">
                        <input className="form_input code" type="text" placeholder="Promo Code*" {...register("PromoCode")} />
                        <button className="check_action" type="submit">
                            Yoxla
                        </button>
                    </div>

                </form>

                {packageDatas?.length > 0 ?
                    <div className="added_document">
                        <div className="document_list">
                            {packageDatas.map((item, i) => {
                                return (
                                    <div key={`pack-${i}`} className="document_item d-flex align-items--center justify-content--space-between">
                                        <div className="weight_part d-flex">
                                            <img className="document_icon" src={Document1Icon} alt="" />
                                            <div className="weight">
                                                <p className="weight_name">Çəki (kg)</p>
                                                <span>{item.Weight}</span>
                                            </div>
                                        </div>

                                        {activePackType === 1 ?
                                            <div className="size_part">
                                                <div className="weight">
                                                    <p className="weight_name">Ölçü (cm)</p>
                                                    <span>{item.Width + 'x' + item.Height + 'x' + item.Length} cm</span>
                                                </div>
                                            </div>
                                            : ''
                                        }

                                        <div className="actions_part d-flex">
                                            <img className="setting_icon cursor--pointer" src={SettingsIcon} alt="" onClick={() => editPackageData(i)} />
                                            <img className="cursor--pointer" src={DeleteIcon} alt="" onClick={() => deletePackageData()} />
                                        </div>
                                    </div>
                                )
                            })}

                            <div className="add_new_doc cursor--pointer d-flex align-items--center justify-content--center" onClick={() => setAddPackageCondidition(true)}>
                                <img src={PlusSquareIcon} alt="" />
                                <p className="add_new_doc_name">Add item</p>
                            </div>
                        </div>
                        <div className="form_submit_parent">
                            <button className="form_submit" onClick={() => calculate()}>
                                Hesabla
                            </button>
                        </div>
                    </div>
                    : ''
                }

                {
                    addPackageCondidition ?
                        <form className="document" onSubmit={handleSubmitPack(onPackSubmit)}>
                            <p className="contact_form_head corp_head">Göndərmə</p>
                            <div className="document_content">
                                <div className="document_tabs d-flex align-items--center">
                                    <div onClick={() => handleActivePackTypeChange(0)} className={`document_tab_item  d-flex align-items--center justify-content--center ${activePackType === 0 ? "document_tab_item_selected" : ""}`}>
                                        <img className="document_tab_item_icon" src="assets/icons/document1.svg" alt="" />
                                        <p className="document_tab_item_name">Sənəd</p>
                                    </div>

                                    <div onClick={() => handleActivePackTypeChange(1)} className={`document_tab_item  d-flex align-items--center justify-content--center ${activePackType === 1 ? "document_tab_item_selected" : ""}`}>
                                        <img className="document_tab_item_icon" src="assets/icons/box.svg" alt="" />
                                        <p className="document_tab_item_name">Bağlama</p>
                                    </div>
                                </div>
                                <div className="document_tab_content">
                                    {
                                        activePackType === 1 ?
                                            <div className="parcel_trapezium">
                                                <div className="trapezoid"></div>
                                                <div className="calculating_parcel">
                                                    <div className="calc_inputs">
                                                        <input className="form_input sm_input" type="text" placeholder="En (cm) *"
                                                            {...registerPack("RequestedPackages.Dimensions.Width", {
                                                                required: {
                                                                    value: true,
                                                                    message: 'Required'
                                                                }
                                                            })} />
                                                        <input className="form_input sm_input" type="text" placeholder="Hündürlük (cm) *"
                                                            {...registerPack("RequestedPackages.Dimensions.Height", {
                                                                required: {
                                                                    value: true,
                                                                    message: 'Required'
                                                                }
                                                            })} />
                                                        <input className="form_input sm_input" type="text" placeholder="Uzunluq (cm) *"
                                                            {...registerPack("RequestedPackages.Dimensions.Length", {
                                                                required: {
                                                                    value: true,
                                                                    message: 'Required'
                                                                }
                                                            })} />
                                                    </div>
                                                    <p className="calc_valid">Minimum dəyər 1-dən çox olmalıdır</p>
                                                </div>
                                                <div className="calc_info d-flex align-items--center ">
                                                    <img className="info_icon" src="assets/icons/info.svg" alt="" />
                                                    <p>Diqqət! Qablaşdırma möhkəm, etibarlı olmalı və malların zədələnməsindən qorunmalıdır.  Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                </div>
                                            </div>
                                            : ''
                                    }

                                    <div className="weight_calculator">
                                        <input className="form_input calculator_input" type="number" placeholder="Kg *" step="0.01"
                                            {...registerPack("Weight.Value", {
                                                required: {
                                                    value: 0.5,
                                                    message: 'Required'
                                                }
                                            })} />
                                        <p className="calculate_desc">The minimum chargeable weight per piece is 0.3
                                        </p>
                                    </div>

                                    <div className="calculator_actions">
                                        <div className="actions_flex">
                                            <button className=" action track cancel" type="button">Ləğv et</button>
                                            <button className=" action calculate" type="submit"> Yadda saxla</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        : ''
                }

                {calculationData?.length > 0 ?
                    <div className="delivery_methods">
                        <p className="contact_form_head corp_head">Göndərmə seçimlərindən birini seçin</p>

                        {calculateCards?.map((item, i) => {
                            return (
                                <div key={'calculateCard-' + i} className="delivery_methods_item">
                                    <div className="collapsible">
                                        <div className='showHide'>
                                            <input type="checkbox" id={"colllapse_delivery_method-" + i} />

                                            <label htmlFor={"colllapse_delivery_method-" + i} className="collap_delivery">
                                                <div className='expand_delivery'>
                                                    <div className="d-flex align-items--flex-start">
                                                        <img className="delivery_icon" src="assets/icons/building.svg" alt="" />
                                                        <div className="delivery_method_info">
                                                            <p className="delivery_method_info_name">{item.cardmaindata.title}</p>
                                                            <ReactMarkdown className="delivery_method_info_ul">
                                                                {item.cardmaindata?.description}
                                                            </ReactMarkdown>
                                                            <div className="delivery_time d-flex align-content--flex-start">
                                                                <img className="time_icon" src="assets/icons/bytesize_clock.svg" alt="" />
                                                                <div className="delivery_time_count">
                                                                    <p className="delivery_name">Təxmini çatdırılma müddəti:</p>
                                                                    <p className="delivery_count">{new Date(calculationData[0]?.DeliveryTime).getDate() - new Date(calculationData[0]?.CutoffTime).getDate()} iş günü</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="delivery_method_actions">
                                                        <p className="delivery_price">{calculationTotal + ' ' + calculationData[0]?.TotalNet.Currency} </p>
                                                        <NavLink to={item.type === 'office' ? `/${lang}/avia-receipt` : item.type === 'online' ? `/${lang}/avia-receipt/courier` :'/call-center'} className="delivery_order d-flex align-items--center">
                                                            Aviaqaimə hazırla
                                                            <img className="arrow_icon" src="assets/icons/arrowforward.svg" alt="" />
                                                        </NavLink>
                                                        <p className="changeArrowDelivery arrow-dn">Detallar<img className="down_icon" src="assets/icons/bi_chevron-double-down.svg" alt="" /></p>
                                                    </div>
                                                </div>

                                            </label>

                                            <div className="fieldsetContainersDeliveryMethod">
                                                <div className="delivery_method_expanded">

                                                    {item.details?.map((detail, j) => {
                                                        return (
                                                            <div key={'calc-card-detail-' + j} className="expand_left">
                                                                <p className="expand_head">{detail.title}:</p>
                                                                <ReactMarkdown className="expanded_ul">
                                                                    {detail?.description}
                                                                </ReactMarkdown>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    : ''
                }
            </div>
        </div>
    )
};

Calculate.propTypes = {};

Calculate.defaultProps = {};

export default Calculate;
