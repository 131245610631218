import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from '../../../../assets/icons/arrow-forward.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
    useQuery,
    gql,
    useMutation
} from "@apollo/client";

const GETSOCIALS = gql`
query GetSocials{
    socials {
      socialmedia{
        title,
        description,
        image{
          url
        }
      }
    }
  }
`;

const CREATESUBSCRIBER = gql`
mutation CreateSubscriber ($email: String!) {
  createSubscriber(input:
        { data: {
            email: $email
        }}) {
          subscriber {
                id
            }
        }
      }
`;

const Footer = () => {
    let loc = useLocation();
    const lang = loc.pathname.split('/')[1];
    let [socials, setSocials] = useState(null);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

    useQuery(GETSOCIALS, {
        variables: {
            _locale: lang
        },
        onCompleted(data) {
            console.log(data);
            if (data?.socials) {
                setSocials(data.socials);
            }
        }
    });

    const [onMutationSubmit, { loading, error, data }] = useMutation(CREATESUBSCRIBER, {
        onCompleted: data => {
            reset();
        }
    });

    const onFormSubmit = (data) => {
        onMutationSubmit({
            variables: {
                email: data.email
            }
        })
    }

    return (
        <>
            <footer>
                <div className="footer">
                    <div className="container footer_data">
                        <div className="footer_column">
                            <p className="column_head">Haqqımızda</p>
                            <ul className="column_navs">
                                <li className="column_nav">
                                    <NavLink to={`/${lang}/info/about-company/about-us`}>Şirkət haqqında</NavLink>
                                </li>
                                <li className="column_nav">
                                    <NavLink to={`/${lang}/info/about-company/vacancies`}>Vakansiyalar</NavLink>
                                </li>
                                <li className="column_nav">
                                    <NavLink to={`/${lang}/info/usefull/parcel-delivery-terms`}> Faydalı məlumatlar</NavLink>
                                </li>
                                <li className="column_nav">
                                    <NavLink to={`/${lang}/info/about-company/social-responsibility`}>Korporativ sosial məsuliyyət</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="footer_column">
                            <p className="column_head">Çatdırılma</p>
                            <ul className="column_navs">
                                <li className="column_nav">
                                    <NavLink to={`/${lang}/info/delivery/universal-delivery`}>Beynəlxalq çatdırılmalar</NavLink>
                                </li>
                                <li className="column_nav">
                                    <NavLink to={`/${lang}/info/delivery/deliver-transfer-terms`}>Çatdırılma şərtləri</NavLink>
                                </li>
                                <li className="column_nav">
                                    <NavLink to={`/${lang}/info/delivery/customs-declaration`}>Gömrük deklarasiyası işləri</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="footer_column">
                            <p className="column_head">Sosial media</p>
                            <ul className="column_navs">
                                {socials?.map((item,i) => {
                                    return (
                                        <li className="column_nav" key={'footer-social-'+i}>
                                            <a href={item.socialmedia.description} target="_blank">
                                                <img className="nav_icon" src={process.env.REACT_APP_BASE_URL_PURE + '/' + item.socialmedia.image[0].url} alt="" />
                                                {item.socialmedia.title}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="footer_column">
                            <p className="column_head">Yeniliklərə abunə olun</p>
                            <form className="subscribe" onSubmit={handleSubmit(onFormSubmit)}>
                                <input type="email" className="subscribe_input" placeholder="Email adresiniz"
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        },
                                        pattern: {
                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                            message: 'Invalid Email'
                                        }
                                    })} />
                                <button className="subscribe_submit">
                                    <img src={ArrowIcon} alt="" />
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="footer_copyright">
                        <div className="container copyright_data">
                            <p className="copyright_text">© Bütün hüquqlar qorunur 2021.</p>
                            <p className="copyright_text">Bu bir <a href="">Marcom</a> məhsuludur.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
