import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch,
    Redirect,
    useHistory
} from "react-router-dom";
import AboutUs from "../../pages/info/aboutCompany/aboutUs/AboutUs";
import NotFound from "../../pages/error/NotFound/NotFound";
import InfoSidebar from "../infoSidebar/InfoSidebar";
import SocialResponsibility from "../../pages/info/aboutCompany/socialResponsibility/SocialResponsibility";
import Vacancies from "../../pages/info/aboutCompany/vacancies/Vacancies";
import VacancyDetails from "../../pages/info/aboutCompany/vacancies/vacancyDetails/VacancyDetails";
import Import from "../../pages/info/services/import/Import";
import Export from "../../pages/info/services/export/Export";
import CustomDeclarationImport from "../../pages/info/customsDeclaration/import/CustomDeclarationImport";
import CustomDeclarationExport from "../../pages/info/customsDeclaration/export/CustomDeclarationExport";
import CustomsDeclaration from "../../pages/info/delivery/customsDeclaration/CustomsDeclaration";
import UniversalDelivery from "../../pages/info/delivery/universalDelivery/UniversalDelivery";
import ParcelDeliveryTerms from "../../pages/info/usefullInfo/parceDeliveryTerms/ParceDeliveryTerms";
import DocumentDeliveryTerms from "../../pages/info/usefullInfo/documentDeliveryTerms/DocumentDeliveryTerms";
import BlockedProducts from "../../pages/blockedProducts/BlockedProducts";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import AboutUsBanner from "../../pages/info/aboutCompany/aboutUs/aboutUsBanner/AboutUsBanner";
import DeliverTransferTerms from "../../pages/info/delivery/deliverTransferTerms/DeliverTransferTerms";
import CustomsClearance from "../../pages/info/services/customsClearance/CustomsClearance";
import Terminology from "../../pages/info/usefullInfo/terminology/Terminology";
import Faq from "../../pages/info/usefullInfo/faq/Faq";
import Downloads from "../../pages/info/usefullInfo/downloads/Downloads";
import Chances from "../../pages/mediaCenter/chances/Chances";
import ChanceDetail from "../../pages/mediaCenter/chances/chanceDetail/ChanceDetail";
import Videos from "../../pages/mediaCenter/kiv/videos/Videos";

import { NavLink, useLocation } from 'react-router-dom';
import Gallery from "../../pages/mediaCenter/kiv/gallery/Gallery";
import Logo from "../../pages/mediaCenter/kiv/logo/Logo";
import Restrictions from "../../pages/info/services/restrictions/Restrictions";
import ThirdCountryDelivery from "../../pages/info/services/thirdCountryDelivery/ThirdCountryDelivery";
import Contact from "../../pages/contact/contact";
import Home from "../../pages/home/home";
import ForCorporate from "../../pages/forCorporate/forCorporate";
import Calculate from "../../pages/calculate/calculate";
import PlaceOrder from "../../pages/placeOrder/place-order";
import AviaReceipt from "../../pages/aviaReceipt/AviaReceipt";
import CallCenter from "../../pages/callCenter/callCenter";

const LangWrapper = () => {
    let history = useHistory();
    let { path } = useRouteMatch();

    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);

    const [hasBanner, checkBanner] = useState(false);

    useEffect(() => {
        const pathname = history.location.pathname;

        checkBanner(history.location.pathname.includes('about-us'));
    }, [history?.location]);

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={`az/home`} />
                </Route>

                <Route path={`${path}/home`} exact component={Home} />
                <Route path={`${path}/contact`} exact component={Contact} />
                <Route path={`${path}/for-corporate`} exact component={ForCorporate} />
                <Route path={`${path}/calculate`} exact component={Calculate} />
                <Route path={`${path}/avia-receipt`} exact component={AviaReceipt} />
                <Route path={`${path}/avia-receipt/courier`} exact component={AviaReceipt} />
                <Route path={`${path}/call-center`} exact component={CallCenter} />

                <Route exact path={`${path}/media-center`}>
                    <Redirect to={`media-center/chances`} />
                </Route>

                <Route path={`${path}/media-center`}>
                    <Breadcrumbs />
                    <Switch>
                        <Route path={`${path}/media-center/chances`} exact component={Chances} />
                        <Route path={`${path}/media-center/chances/:slug`} exact component={ChanceDetail} />
                    </Switch>
                </Route>

                <Route path={`${path}/kiv`}>
                    <Breadcrumbs />
                    <div className="container kiv">
                        <h2 className="opportunities_head">KİV üçün</h2>
                        <div className="kiv_tab">
                            <NavLink to={`/${lang}/kiv/logo`} activeClassName="selected_tab_item" className="kiv_tab_item">Loqo</NavLink>
                            <NavLink to={`/${lang}/kiv/galleries`} activeClassName="selected_tab_item" className="kiv_tab_item">Şirkətdən görüntülər</NavLink>
                            <NavLink to={`/${lang}/kiv/videos`} activeClassName="selected_tab_item" className="kiv_tab_item">Videolar </NavLink>
                        </div>
                        <select name="" id="kiv_select">
                            <option value="">
                                <NavLink to={`/${lang}/kiv/logo`} activeClassName="selected_tab_item">Loqo</NavLink>
                            </option>
                            <option value="">
                                <NavLink to={`/${lang}/kiv/galleries`} activeClassName="selected_tab_item">Şirkətdən görüntülər</NavLink>
                            </option>
                            <option value="">
                                <NavLink to={`/${lang}/kiv/videos`} activeClassName="selected_tab_item">Videolar</NavLink>
                            </option>
                        </select>
                        <Switch>
                            <Route exact path={`${path}/kiv`}>
                                <Redirect to={`kiv/logo`} />
                            </Route>
                            <Route path={`${path}/kiv/logo`} exact component={Logo} />
                            <Route path={`${path}/kiv/galleries`} exact component={Gallery} />
                            <Route path={`${path}/kiv/videos`} exact component={Videos} />
                            
                        </Switch>
                    </div>
                </Route>

                <Route path={`${path}/info`}>
                    <Breadcrumbs />
                    {hasBanner ? <AboutUsBanner /> : ''}
                    <div className="container page_content">
                        <InfoSidebar />
                        <Switch>
                            <Route exact path={`${path}/info/about-company`}>
                                <Redirect to={`about-company/about-us`} />
                            </Route>
                            <Route path={`${path}/info/about-company/about-us`} exact component={AboutUs} />
                            <Route path={`${path}/info/about-company/social-responsibility`} exact component={SocialResponsibility} />
                            <Route path={`${path}/info/about-company/vacancies`} exact component={Vacancies} />
                            <Route path={`${path}/info/about-company/vacancies/:slug`} exact component={VacancyDetails} />


                            <Route exact path={`${path}/info/services`}>
                                <Redirect to={`services/import`} />
                            </Route>
                            <Route path={`${path}/info/services/import`} exact component={Import} />
                            <Route path={`${path}/info/services/export`} exact component={Export} />
                            <Route path={`${path}/info/services/customs-clearance`} exact component={CustomsClearance} />
                            <Route path={`${path}/info/services/restrictions`} exact component={Restrictions} />
                            <Route path={`${path}/info/services/third-country-delivery`} exact component={ThirdCountryDelivery} />
                            <Route path={`${path}/info/place-order`} exact component={PlaceOrder} />


                            <Route exact path={`${path}/info/delivery`}>
                                <Redirect to={`delivery/universal-delivery`} />
                            </Route>
                            <Route path={`${path}/info/delivery/universal-delivery`} exact component={UniversalDelivery} />
                            <Route path={`${path}/info/delivery/customs-declaration`} exact component={CustomsDeclaration} />
                            <Route path={`${path}/info/delivery/deliver-transfer-terms`} exact component={DeliverTransferTerms} />


                            <Route exact path={`${path}/info/customs-declaration`}>
                                <Redirect to={`customs-declaration/import`} />
                            </Route>
                            <Route path={`${path}/info/customs-declaration/import`} exact component={CustomDeclarationImport} />
                            <Route path={`${path}/info/customs-declaration/export`} exact component={CustomDeclarationExport} />

                            <Route exact path={`${path}/info/usefull`}>
                                <Redirect to={`usefull/parcel-delivery-terms`} />
                            </Route>
                            <Route path={`${path}/info/usefull/parcel-delivery-terms`} exact component={ParcelDeliveryTerms} />
                            <Route path={`${path}/info/usefull/document-delivery-terms`} exact component={DocumentDeliveryTerms} />
                            <Route path={`${path}/info/usefull/terminology`} exact component={Terminology} />
                            <Route path={`${path}/info/usefull/faq`} exact component={Faq} />
                            <Route path={`${path}/info/usefull/downloads`} exact component={Downloads} />


                            <Route path={`${path}/info/blocked-products`} exact component={BlockedProducts} />


                        </Switch>
                    </div>
                </Route>
                <Route path="*" component={NotFound} />
                {/* 404
                </Route> */}
            </Switch>
        </>
    )
}

export default LangWrapper
