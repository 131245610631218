import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import BadgeIcon from '../../../assets/icons/badge.svg';

const SuccessCard = () => {
  return (
    <div className="page_data">
     <div className="success">
      <div className="success_check">
        <img src={BadgeIcon} alt="" />
      </div>
      <div className="success_data">
        <h3 className="success_head">Sorğunuz uğurla qeydə alındı.</h3>
        <p className="success_desc">   Bizi seçdiyiniz üçün təşəkkür edirik. Əməkdaşlarımız ən qısa zamanda sizinlə əlaqə saxlayacaqdır.</p>
        <div className="form_submit_parent success_submit">
          <NavLink to={`/`} className="form_submit">
            Esas Sehife
          </NavLink>
        </div>
      </div>
    </div>
    </div>
  )
};

SuccessCard.propTypes = {};

SuccessCard.defaultProps = {};

export default SuccessCard;
