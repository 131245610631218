import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";
import ReactMarkdown from "react-markdown";

const SERVICESIMPORT = gql`
query GetServicesImport($_locale: String!){
  servicesimport(locale: $_locale) {
      terms{
        title,
        description,
        image{
          formats
        }
      }
    }
  }
`;

const Import = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [servicesImport, setServicesImport] = useState(null);
  
  useQuery(SERVICESIMPORT, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if(data?.servicesimport?.terms){
        setServicesImport(data.servicesimport.terms);
      }
    }
  });

  return (
    <>
      <div className="page_data">
        <h2 className="data_head">{servicesImport?.title}</h2>
        <ReactMarkdown className="data_desc">
          {servicesImport?.description}
        </ReactMarkdown>
      </div>
    </>
  )
};

Import.propTypes = {};

Import.defaultProps = {};

export default Import;
