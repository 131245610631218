import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";
import ReactMarkdown from "react-markdown"
// servicesimport

const SERVICESEXPORT = gql`
query GetServicesExport($_locale: String!){
  servicesexport(locale: $_locale) {
      terms{
        title,
        description,
        image{
          formats
        }
      }
    }
  }
`;

const Export = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [servicesExport, setServicesExport] = useState(null);
  
  useQuery(SERVICESEXPORT, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if(data?.servicesexport?.terms){
        setServicesExport(data.servicesexport.terms);
      }
    }
  });
  return (
    <>
       <div className="page_data">
        <h2 className="data_head">{servicesExport?.title}</h2>
        <ReactMarkdown className="data_desc">
          {servicesExport?.description}
        </ReactMarkdown>
      </div>
    </>
  )
};

Export.propTypes = {};

Export.defaultProps = {};

export default Export;
