import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const CUSTOMDECLARATION = gql`
query GetCustomDeclaration($_locale: String!){
  customdeclaration(locale: $_locale) {
      info{
        title,
        description,
        image{
          formats
        }
      }
    }
  }
`;

// customdeclaration

const CustomsDeclaration = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [customDeclaration, setCustomDeclaration] = useState(null);

  useQuery(CUSTOMDECLARATION, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if(data?.customdeclaration?.info){
        setCustomDeclaration(data.customdeclaration.info);
      }
    }
  });

  return (
    <>
      <div className="page_data">
        <h2 className="data_head">{customDeclaration?.title}</h2>
        <ReactMarkdown className="data_desc">
          {customDeclaration?.description}
        </ReactMarkdown>
      </div>
    </>
  )
};

CustomsDeclaration.propTypes = {};

CustomsDeclaration.defaultProps = {};

export default CustomsDeclaration;
