import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const BLOCKEDPRODUCTS = gql`
query GetBlockedProducts($_locale: String!){
  blockedproduct(locale: $_locale) {
      info{
        title,
        description,
        image{
          formats
        }
      }
    }
  }
`;

// blockedproducts

const BlockedProducts = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [blockedProducts, setBlockedProducts] = useState(null);

  useQuery(BLOCKEDPRODUCTS, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if (data?.blockedproduct?.info) {
        setBlockedProducts(data.blockedproduct.info);
      }
    }
  });

  return (
    <>
      <div className="page_data">
        <h2 className="data_head">{blockedProducts?.title}</h2>
        <ReactMarkdown className="data_desc line-break">
          {blockedProducts?.description}
        </ReactMarkdown>
      </div>
    </>
  )
};

BlockedProducts.propTypes = {};

BlockedProducts.defaultProps = {};

export default BlockedProducts;
