import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";
import './App.scss';
import Footer from "./app/components/layout/footer/Footer";
import Header from "./app/components/layout/header/Header";
import LangWrapper from "./app/components/layout/lang-wrapper/LangWrapper";

function App() {
  return (
    <Router>
      <Header />
      <Switch>

        <Route exact path="/" >
          <Redirect to="/az" />
        </Route>

        <Route path="/:lng(az|en|ru)?">
          <LangWrapper />
        </Route>

      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
