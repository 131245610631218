import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

import ArrowFowardIcon from '../../../../../assets/icons/arrow-forward2.svg';

const CHANCES = gql`
query GetChanges($_locale: String!){
  blogsnews(locale: $_locale) {
      pageName,
      shortDesc,
      slug,
      pageMainContent{
        image{
          formats,
          url
        }
      }
    }
  }
`;

// parceldeliveryterm

const Chances = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [chances, setChances] = useState(null);

  useQuery(CHANCES, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if (data?.blogsnews) {
        setChances(data.blogsnews);
      }
    }
  });

  return (
    <>
      <div className="container opportunities">
        <h2 className="opportunities_head">Fürsətlər</h2>
        <p className="opportunities_desc">DHL Express sizə əlavə fürsətlər təqdim edir.</p>
        <div className="opportunities_list">

          {chances?.map((item, i) => {
            return (
              <div className="opportunities_item" key={'chance-'+i}>
                <div className="opportunities_img">
                  <img src={process.env.REACT_APP_BASE_URL_PURE + '/' + (item.pageMainContent?.image[0]?.formats?.medium?.url || item.pageMainContent?.image[0]?.url)} alt="" />
                </div>
                <div className="opportunities_info">
                  <p className="opp_name">{item.pageName}</p>
                  <p className="opp_desc">{item.shortDesc}</p>
                  <NavLink to={`/${lang}/media-center/chances/${item.slug}`} className="opp_action">
                    Daha ətraflı <img className="forward" src={ArrowFowardIcon} alt="" />
                  </NavLink>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
};

Chances.propTypes = {};

Chances.defaultProps = {};

export default Chances;
