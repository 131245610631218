import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../../../../assets/images/logo.svg';
import ArrowDown from '../../../../assets/icons/arrow_down.svg';
import DeliveryMan from '../../../../assets/icons/delivery-man.svg';
import Hamburger from '../../../../assets/icons/burger_icon.svg';
import Search from '../../../../assets/icons/search.svg';
import User from '../../../../assets/icons/user.svg';
import { NavLink, useLocation } from 'react-router-dom';

const InfoSidebar = () => {
    let loc = useLocation();
    const lang = loc.pathname.split('/')[1];

    return (
        <div className="page_navigation">
            <div className="navigation_group">
                <NavLink to={`/${lang}/info/about-company`} activeClassName="active_head" className="navigation_head">Haqqımızda</NavLink>
                <ul className="navigation_list">
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/about-company/about-us`} activeClassName="active_item">Şirkət haqqında</NavLink>
                    </li>
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/about-company/vacancies`} activeClassName="active_item">Vakansiyalar</NavLink>
                    </li>
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/about-company/social-responsibility`} activeClassName="active_item">Korporativ sosial məsuliyyət</NavLink>
                    </li>
                </ul>
            </div>
            <div className="navigation_group">
                <NavLink to={`/${lang}/info/services`} className="navigation_head" activeClassName="active_head">Xidmətlər</NavLink>
                <ul className="navigation_list">
                    <li className="navigation_item active_item">
                        <NavLink to={`/${lang}/info/services/import`} activeClassName="active_item">İdxal</NavLink>
                    </li>
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/services/export`} activeClassName="active_item">İxrac</NavLink>
                    </li>
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/services/third-country-delivery`} activeClassName="active_item">3-cü ölkədən daşınmalar</NavLink>
                    </li>
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/services/customs-clearance`} activeClassName="active_item">Azərbaycana idxal və ixrac edilən yüklərin gömrük rəsmiləşdirilməsi</NavLink>
                    </li>
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/services/restrictions`} activeClassName="active_item">Alıcı ölkənin göndərişin tərkibinə görə tətbiq etdiyi, məhdudiyyət və qadağalar</NavLink>
                    </li>
                </ul>
            </div>
            <div className="navigation_group">
                <NavLink to={`/${lang}/info/delivery`} activeClassName="active_head" className="navigation_head">Çatdırılma</NavLink>
                <ul className="navigation_list">
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/delivery/universal-delivery`} activeClassName="active_item">Beynəlxalq çatdırılmalar</NavLink>
                    </li>
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/delivery/deliver-transfer-terms`} activeClassName="active_item">Daşınma və çatdırılma şərtləri</NavLink>
                    </li>
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/delivery/customs-declaration`} activeClassName="active_item">Gömrük deklarasiyası işləri</NavLink>
                    </li>
                </ul>
            </div>
            <div className="navigation_group">
                <NavLink to={`/${lang}/info/customs-declaration`} activeClassName="active_head" className="navigation_head">Gömrük bəyannaməsi</NavLink>
                <ul className="navigation_list">
                    <li className="navigation_item ">
                        <NavLink to={`/${lang}/info/customs-declaration/import`} activeClassName="active_item">İdxal</NavLink>
                    </li>
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/customs-declaration/export`} activeClassName="active_item">İxrac</NavLink>
                    </li>
                </ul>
            </div>
            <div className="navigation_group">
                <NavLink to={`/${lang}/info/usefull`} activeClassName="active_head" className="navigation_head">Faydalı məlumatlar</NavLink>
                <ul className="navigation_list">
                    <li className="navigation_item ">
                        <NavLink to={`/${lang}/info/usefull/parcel-delivery-terms`} activeClassName="active_item">Daşınma şərtləri - bağlamalar üçün</NavLink>
                    </li>
                    <li className="navigation_item">
                        <NavLink to={`/${lang}/info/usefull/document-delivery-terms`} activeClassName="active_item">Daşınma şərtləri - sənədlər üçün</NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
};

InfoSidebar.propTypes = {};

InfoSidebar.defaultProps = {};

export default InfoSidebar;
