import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const DOCUMENTDELIVERYTERMS = gql`
query GetDocumentDeliveryTerms($_locale: String!){
  documentdeliveryterm(locale: $_locale) {
      info{
        title,
        description,
        image{
          formats
        }
      }
    }
  }
`;

// documentdeliveryterms

const DocumentDeliveryTerms = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [documentDeliveryTerms, setDocumentDeliveryTerms] = useState(null);

  useQuery(DOCUMENTDELIVERYTERMS, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if(data?.documentdeliveryterm?.info){
        setDocumentDeliveryTerms(data.documentdeliveryterm.info);
      }
    }
  });

  return (
    <>
      <div className="page_data">
        <h2 className="data_head">{documentDeliveryTerms?.title}</h2>
        <ReactMarkdown className="data_desc">
          {documentDeliveryTerms?.description}
        </ReactMarkdown>
      </div>
    </>
  )
};

DocumentDeliveryTerms.propTypes = {};

DocumentDeliveryTerms.defaultProps = {};

export default DocumentDeliveryTerms;
