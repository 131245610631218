import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

import ArrowIconDown from '../../../../../../assets/icons/arrowd.svg';
import ArrowIconUp from '../../../../../../assets/icons/arrowa.svg';

const DELIVERYTRANSFERTERMS = gql`
query GetDeliveryTransferTerms($_locale: String!){
  deliverytransferterm(locale: $_locale) {
      info{
        title,
        description,
        image{
          formats
        }
      },
      accordion{
        name,
        description
      }
    }
  }
`;

const DeliverTransferTerms = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [deliveryTransferTerms, setDeliveryTransferTerms] = useState(null);

  useQuery(DELIVERYTRANSFERTERMS, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if (data?.deliverytransferterm) {
        setDeliveryTransferTerms(data.deliverytransferterm);
      }
    }
  });

  return (
    <>
      <div className="page_data">
        <h2 className="data_head">{deliveryTransferTerms?.info?.title}</h2>
        <ReactMarkdown className="data_desc">
          {deliveryTransferTerms?.info?.description}
        </ReactMarkdown>

        <div className="collapsible_list">
          {deliveryTransferTerms?.accordion?.map((item, i) => {
            return (
              <div className="collapsible" key={'accordion-' + i}>
                <div className='showHide'>
                  <input type="checkbox" id={"colllapse-"+i} />

                  <label htmlFor={"colllapse-"+i} className="collap">
                    <div className='expand'>
                      <div className="count">
                        <span>{i+1}</span>
                      </div>
                      <p className="collap_head">{item.name}</p>
                    </div>
                    <span className="changeArrow arrow-up"><img src={ArrowIconDown} alt="" /></span>
                    <span className="changeArrow arrow-dn"><img src={ArrowIconUp} alt="" /></span>
                  </label>

                  <div className="fieldsetContainers">
                    <ReactMarkdown className="collap_desc">
                      {item?.description}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
};

DeliverTransferTerms.propTypes = {};

DeliverTransferTerms.defaultProps = {};

export default DeliverTransferTerms;
