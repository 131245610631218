import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

// import Logo from '../../../../../assets/images/logo.svg'; 
import { NavLink, useLocation } from 'react-router-dom';

const NotFound = () => {
  let loc = useLocation();
  const lang = loc.pathname.split('/')[1];

  return (
    <>
      <div className="not_found container d-flex align-items--center flex-direction--column">
        <h1 className="not_found_head">
          404
        </h1>
        <p className="not_found_desc">Axtardığınız səhifə tapılmadı.</p>
        <p className="not_found_desc2">Saytın əsas menyusundan istifadə edin və ya əsas səhifəyə keçin.</p>
        <div className="not_found_actions">
        <NavLink to={`/${lang}`} className="action to_home">ƏSAS SƏHİFƏ</NavLink>
        <NavLink to={`/${lang}/contact-us`} className="action to_contact">ƏLAQƏ</NavLink>
          {/* <button className="action to_home">ƏSAS SƏHİFƏ</button>
          <button className="action to_contact">ƏLAQƏ</button> */}
        </div>
      </div>
    </>
  )
};

NotFound.propTypes = {};

NotFound.defaultProps = {};

export default NotFound;
