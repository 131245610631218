import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import {
    useQuery,
    gql,
    useMutation,
    useLazyQuery
} from "@apollo/client";

import InfoLightIcon from '../../../../assets/icons/info_light.svg';
import ErrorIcon from "../../../../assets/icons/error.svg"

import { useLocation } from "react-router";
import { useForm } from "react-hook-form";

import axios from "axios";
import SuccessCard from "../../successCard/SuccessCard";

const PLACEORDER = gql`
mutation PlacedOrder (
  $parcelHeight: String!,
  $parcelInvoiceCost: String!,
  $parcelLength: String!,
  $parcelSrcAddress: String!,
  $parcelSrcPostalCode: String!,
  $parcelWeight: String!,
  $parcelWidth: String!,
  $receiverAddress: String!,
  $receiverCompanyName: String!,
  $receiverFullname: String!,
  $receiverPostalCode: String!,
  $receiverRelatedPersonEmail: String!,
  $receiverRelatedPersonFullname: String!,
  $receiverRelatedPersonPhone: String!,
  $relatedPersonEmail: String!,
  $relatedPersonFullname: String!,
  $relatedPersonPhone: String!,
  $senderCompanyName: String!,
  $senderFullname: String!
) {
  createPlacedorder(input:
        { data: {
          parcelHeight: $parcelHeight, 
          parcelInvoiceCost: $parcelInvoiceCost, 
          parcelLength: $parcelLength, 
          parcelSrcAddress: $parcelSrcAddress, 
          parcelSrcPostalCode: $parcelSrcPostalCode, 
          parcelWeight: $parcelWeight, 
          parcelWidth: $parcelWidth, 
          receiverAddress: $receiverAddress, 
          receiverCompanyName: $receiverCompanyName, 
          receiverFullname: $receiverFullname, 
          receiverPostalCode: $receiverPostalCode, 
          receiverRelatedPersonEmail: $receiverRelatedPersonEmail, 
          receiverRelatedPersonFullname: $receiverRelatedPersonFullname, 
          receiverRelatedPersonPhone: $receiverRelatedPersonPhone, 
          relatedPersonEmail: $relatedPersonEmail, 
          relatedPersonFullname: $relatedPersonFullname, 
          relatedPersonPhone: $relatedPersonPhone, 
          senderCompanyName: $senderCompanyName, 
          senderFullname: $senderFullname, 
        }}) {
          placedorder {
                id
            }
        }
      }
`;

const PlaceOrder = () => {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [activeFormStep, setActiveFormStep] = useState(0);
    let [formdata, setFormdata] = useState(null);
    let [showSuccess, setShowSuccess] = useState(false);
    let [orderData, setOrderData] = useState(null);

    useEffect(() => {
        setOrderData(JSON.parse(localStorage.getItem('orderData')));
    }, []);

    useEffect(() => {
        if (orderData) {
            console.log(orderData);
            // localStorage.removeItem('orderData');
            setValue('parcelSrcAddress', orderData?.Shipper?.City);
            setValue('parcelSrcPostalCode', orderData?.Shipper?.PostalCode);


            setValue2('receiverAddress', orderData?.Recipient?.City);
            setValue2('receiverPostalCode', orderData?.Recipient?.PostalCode);
        }
    }, [orderData])

    const { register, handleSubmit, setValue, reset, watch, formState: { errors } } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, setValue: setValue2, reset: reset2, formState: { errors: errors2 } } = useForm();

    const [onMutationSubmit, { loading, error, data }] = useMutation(PLACEORDER, {
        onCompleted: data => {
            reset();
            reset2();
        }
    });

    const onFirstStepSubmit = (data) => {
        setFormdata({
            ...formdata,
            ...data,
            submit: false
        })
        setActiveFormStep(1);
    }

    const onSecondStepSubmit = (data) => {
        setFormdata({
            ...formdata,
            ...data,
            submit: true
        })
        setActiveFormStep(2);
    }

    const changeStep = (i) => {
        setActiveFormStep(i);
    }

    useEffect(() => {
        if (formdata?.submit) {
            sendFormData();
        }
    }, [formdata]);

    const sendFormData = (data) => {
        onMutationSubmit({
            variables: {
                senderCompanyName: formdata.senderCompanyName,
                senderFullname: formdata.senderFullname,

                parcelSrcAddress: formdata.parcelSrcAddress,
                parcelSrcPostalCode: formdata.parcelSrcPostalCode,
                parcelHeight: formdata.parcelHeight,
                parcelInvoiceCost: formdata.parcelInvoiceCost,
                parcelLength: formdata.parcelLength,
                parcelWeight: formdata.parcelWeight,
                parcelWidth: formdata.parcelWidth,

                receiverAddress: formdata.receiverAddress,
                receiverCompanyName: formdata.receiverCompanyName,
                receiverFullname: formdata.receiverFullname,
                receiverPostalCode: formdata.receiverPostalCode,
                receiverRelatedPersonEmail: formdata.receiverRelatedPersonEmail,
                receiverRelatedPersonFullname: formdata.receiverRelatedPersonFullname,
                receiverRelatedPersonPhone: formdata.countryPhoneCode2 + ' ' + formdata.receiverRelatedPersonPhone,//
                relatedPersonEmail: formdata.relatedPersonEmail,
                relatedPersonFullname: formdata.relatedPersonFullname,
                relatedPersonPhone: formdata.countryPhoneCode1 + ' ' + formdata.relatedPersonPhone,//


            }
        });

        axios.post(process.env.REACT_APP_BASE_URL_PURE + '/email', {
            to: 'elnur.isazade@knexel.com',
            // from: 'joelrobuchon@strapi.io',
            // cc: 'helenedarroze@strapi.io',
            // bcc: 'ghislainearabian@strapi.io',
            // replyTo: 'annesophiepic@strapi.io',
            subject: 'DHL - 3-cü ölkədən daşınmalar',
            text: 'Hello world!',
            html: `
            <div>
              <h2>Göndərən şirkət və ya fiziki şəxs</h2>
              <strong>Ad, soyad:</strong>
              <span>${formdata.senderFullname}</span>
              <br/>
              <strong>Şirkət adı:</strong>
              <span>${formdata.senderCompanyName}</span>
              <br/>
              <br/>
              
              <h2>Bağlamanın götürüləcəyi ünvan</h2>
              <strong>Ünvan:</strong>
              <span>${formdata.parcelSrcAddress}</span>
              <br/>
              <strong>ZIP Kodu:</strong>
              <span>${formdata.parcelSrcPostalCode}</span>
              <br/>
              <br/>
              
              <h2>Əlaqədar şəxs</h2>
              <strong>Ad, soyad:</strong>
              <span>${formdata.relatedPersonFullname}</span>
              <br/>
              <strong>Telefon:</strong>
              <span>${formdata.countryPhoneCode1 + ' ' + formdata.relatedPersonPhone}</span>
              <br/>
              <strong>Email</strong>
              <span>${formdata.relatedPersonEmail}</span>
              <br/>
              <br/>
              
              <h2>Yük haqqında məlumat</h2>
              <strong>Çəki (kg):</strong>
              <span>${formdata.parcelWeight}</span>
              <br/>
              <strong>En (cm):</strong>
              <span>${formdata.parcelWidth}</span>
              <br/>
              <strong>Hündürlük (cm):</strong>
              <span>${formdata.parcelHeight}</span>
              <br/>
              <strong>Uzunluq (cm):</strong>
              <span>${formdata.parcelLength}</span>
              <br/>
              <strong>İnvoys dəyəri:</strong>
              <span>${formdata.parcelInvoiceCost}</span>
              <br/>
              <br/>
              <hr/>
              <br/>
              <br/>
              <h2>Alıcı şirkət və ya fiziki şəxs</h2>
              <strong>Ad, soyad:</strong>
              <span>${formdata.receiverFullname}</span>
              <br/>
              <strong>Şirkət adı:</strong>
              <span>${formdata.receiverCompanyName}</span>
              <br/>
              <br/>
    
              <h2>Bağlamanın çatdırılacağı ünvan</h2>
              <strong>Ünvan:</strong>
              <span>${formdata.receiverAddress}</span>
              <br/>
              <strong>ZIP Kodu:</strong>
              <span>${formdata.receiverPostalCode}</span>
              <br/>
              <strong>Əlaqədar şəxs ad, soyad:</strong>
              <span>${formdata.receiverRelatedPersonFullname}</span>
              <br/>
              <strong>Telefon:</strong>
              <span>${formdata.countryPhoneCode2 + ' ' + formdata.receiverRelatedPersonPhone}</span>
              <br/>
              <strong>Email:</strong>
              <span>${formdata.receiverRelatedPersonEmail}</span>
            </div>
          `,
        })
            .then(function (response) {
                reset();
                reset2();
                setShowSuccess(true);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    if (!showSuccess) {
        return (
            <div className="page_data">
                <h2 className="data_head">Sifariş yerləşdir</h2>
                <div className="stepper_form">
                    <div className="stepper">
                        <div className="stepper stepper--flexbox">
                            <input className="stepper__input" id="stepper-flex-0" name="stepper-flex" type="radio" value="0" onChange={() => changeStep(0)} disabled={!(activeFormStep > 0)} checked={activeFormStep === 0} />
                            <div className="stepper__step">
                                <label className="stepper__button" htmlFor="stepper-flex-0">Göndərən</label>
                            </div>
                            <input className="stepper__input" id="stepper-flex-1" name="stepper-flex" type="radio" value="1" onChange={() => changeStep(1)} disabled={!(activeFormStep > 1)} checked={activeFormStep === 1} />
                            <div className="stepper__step">
                                <label className="stepper__button" htmlFor="stepper-flex-1">Qəbul edən</label>
                            </div>
                        </div>

                    </div>
                    <form className={`form_first_step ${activeFormStep !== 0 ? "display--none" : ""}`} onSubmit={handleSubmit(onFirstStepSubmit)}>
                        <div className="info_bar">
                            <div className="info_icon_light">
                                <img src={InfoLightIcon} alt="" />
                            </div>
                            DİQQƏT: Məlumatlar yalnız ingilis dilində daxil edilməlidir!
                        </div>
                        <div className="form_item">
                            <p className="form_head">Göndərən şirkət və ya fiziki şəxs</p>
                            <div className="two_inputs">

                                <div className="validating">
                                    <input className={`form_input ${errors.senderFullname ? "error" : ""}`} type="text" placeholder="Ad, soyad *"
                                        {...register("senderFullname", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            }
                                        })} />
                                    {errors.senderFullname ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>

                                <div className="validating">
                                    <input className={`form_input ${errors.senderCompanyName ? "error" : ""}`} type="text" placeholder="Şirkət adı"
                                        {...register("senderCompanyName", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            }
                                        })} />
                                    {errors.senderCompanyName ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form_item">
                            <p className="form_head">Bağlamanın götürüləcəyi ünvan</p>
                            <div className="two_inputs">
                                <div className="validating">
                                    <input className={`form_input ${errors.parcelSrcAddress ? "error" : ""}`} type="text" placeholder="Ünvan *"
                                        {...register("parcelSrcAddress", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            }
                                        })} />
                                    {errors.parcelSrcAddress ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                                <div className="validating">
                                    <input className={`form_input ${errors.parcelSrcPostalCode ? "error" : ""}`} type="text" placeholder="ZIP Kodu *"
                                        {...register("parcelSrcPostalCode", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            }
                                        })} />
                                    {errors.parcelSrcPostalCode ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form_item">
                            <p className="form_head">Əlaqədar şəxs </p>
                            <div className="two_inputs">
                                <div className="validating">
                                    <input className={`form_input ${errors.relatedPersonFullname ? "error" : ""}`} type="text" placeholder="Ad, soyad *"
                                        {...register("relatedPersonFullname", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            }
                                        })} />
                                    {errors.relatedPersonFullname ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                                <div className="validating">
                                    <div className="number_input">
                                        <select className="country_code"
                                            {...register("countryPhoneCode1", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                }
                                            })}>
                                            <option value="994">994</option>
                                            <option value="1">000</option>
                                            <option value="2">000</option>
                                        </select>
                                        <input className={`form_input number ${errors.relatedPersonPhone ? "error" : ""}`} type="text" placeholder="__ ___ __ __ *"
                                            {...register("relatedPersonPhone", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                }
                                            })} />
                                    </div>
                                    {errors.relatedPersonPhone ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form_item">
                            <div className="two_inputs">
                                <div className="validating">
                                    <input className={`form_input ${errors.relatedPersonEmail ? "error" : ""}`} type="text" placeholder="E-poçt *"
                                        {...register("relatedPersonEmail", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            },
                                            pattern: {
                                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                                message: 'Invalid Email'
                                            }
                                        })} />
                                    {errors.relatedPersonEmail ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                                <div></div>
                            </div>
                        </div>
                        <p className="form_head">Yük haqqında məlumat  </p>
                        <div className="five_inputs">

                            <div className="validating">
                                <input className={`form_input sm_input ${errors.parcelWeight ? "error" : ""}`} type="number" placeholder="Çəki (kg) *"
                                    {...register("parcelWeight", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })} />
                            </div>
                            <div className="validating">
                                <input className={`form_input sm_input ${errors.parcelWidth ? "error" : ""}`} type="number" placeholder="En (cm) *"
                                    {...register("parcelWidth", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })} />
                            </div>
                            <div className="validating">
                                <input className={`form_input sm_input ${errors.parcelHeight ? "error" : ""}`} type="number" placeholder="Hündürlük (cm) *"
                                    {...register("parcelHeight", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })} />
                            </div>
                            <div className="validating">
                                <input className={`form_input sm_input ${errors.parcelLength ? "error" : ""}`} type="number" placeholder="Uzunluq (cm) **"
                                    {...register("parcelLength", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })} />
                            </div>
                            <div className="validating">
                                <input className={`form_input sm_input ${errors.parcelInvoiceCost ? "error" : ""}`} type="text" placeholder="İnvoys dəyəri *"
                                    {...register("parcelInvoiceCost", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })} />
                            </div>
                        </div>
                        <div className="form_submit_parent">
                            <button className="form_submit" type="submit">
                                Növbəti
                            </button>
                        </div>
                    </form>


                    <form className={`form_second_step ${activeFormStep !== 1 ? "display--none" : ""}`} onSubmit={handleSubmit2(onSecondStepSubmit)}>
                        <div className="form_item">
                            <p className="form_head">Alıcı şirkət və ya fiziki şəxs:</p>
                            <div className="two_inputs">
                                <div className="validating">
                                    <input className={`form_input ${errors2.receiverFullname ? "error" : ""}`} type="text" placeholder="Ad, soyad *"
                                        {...register2("receiverFullname", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            }
                                        })} />
                                    {errors2.receiverFullname ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                                <div className="validating">
                                    <input className={`form_input ${errors2.receiverCompanyName ? "error" : ""}`} type="text" placeholder="Şirkət adı"
                                        {...register2("receiverCompanyName", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            }
                                        })} />
                                    {errors2.receiverCompanyName ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form_item">
                            <p className="form_head">Bağlamanın çatdırılacağı ünvan</p>
                            <div className="two_inputs">
                                <div className="validating">
                                    <input className={`form_input ${errors2.receiverAddress ? "error" : ""}`} type="text" placeholder="Ünvan *"
                                        {...register2("receiverAddress", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            }
                                        })} />
                                    {errors2.receiverAddress ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                                <div className="validating">
                                    <input className={`form_input ${errors2.receiverPostalCode ? "error" : ""}`} type="text" placeholder="ZIP Kodu *"
                                        {...register2("receiverPostalCode", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            }
                                        })} />
                                    {errors2.receiverPostalCode ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form_item">
                            <p className="form_head">Əlaqədar şəxs </p>
                            <div className="two_inputs">
                                <div className="validating">
                                    <input className={`form_input ${errors2.receiverRelatedPersonFullname ? "error" : ""}`} type="text" placeholder="Ad, soyad *"
                                        {...register2("receiverRelatedPersonFullname", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            }
                                        })} />
                                    {errors2.receiverRelatedPersonFullname ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>

                                <div className="validating">
                                    <div className="number_input">
                                        <select className="country_code"
                                            {...register2("countryPhoneCode2", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                }
                                            })}>
                                            <option value="994">994</option>
                                            <option value="1">000</option>
                                            <option value="2">000</option>
                                        </select>
                                        <input className={`form_input number ${errors2.receiverRelatedPersonPhone ? "error" : ""}`} type="text" placeholder="__ ___ __ __ *"
                                            {...register2("receiverRelatedPersonPhone", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                }
                                            })} />
                                    </div>
                                    {errors2.receiverRelatedPersonPhone ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form_item">
                            <div className="two_inputs">
                                <div className="validating">
                                    <input className={`form_input ${errors2.receiverRelatedPersonEmail ? "error" : ""}`} type="text" placeholder="E-poçt *"
                                        {...register2("receiverRelatedPersonEmail", {
                                            required: {
                                                value: true,
                                                message: 'Required'
                                            },
                                            pattern: {
                                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                                message: 'Invalid Email'
                                            }
                                        })} />
                                    {errors2.receiverRelatedPersonEmail ?
                                        <p className="d-flex align-items--center">
                                            <img className="error_icon" src={ErrorIcon} alt="" />
                                            Form field error text.
                                        </p> : ''
                                    }
                                </div>
                                <div></div>
                            </div>
                        </div>

                        <div className="form_submit_parent">
                            <button className="form_submit ">
                                Növbəti
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    } else {
        return (
            <SuccessCard />
        )
    }
};

PlaceOrder.propTypes = {};

PlaceOrder.defaultProps = {};

export default PlaceOrder;
