import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PathRightIcon from '../../../assets/icons/path_right.svg';
import { useHistory } from 'react-router';

const Breadcrumbs = () => {
    let history = useHistory();
    const [pages, setPages] = useState([null]);

    useEffect(()=>{
        const pathname = history.location.pathname;
        const pathSlices = pathname.split('/');
        let pages;
        console.log(pages);
        let pageArr = [];

        if(pathname.includes('info')) {
            pages = pathSlices.slice(3);
            pages.forEach(page=>{
                pageArr.push({ name: page, path: pathname });
            })
        } else {
            pages = pathSlices.slice(2);
            pages.forEach(page=>{
                pageArr.push({ name: page, path: pathname });
            })
        }

        setPages([
            { name: 'Əsas səhifə', path: '' },
            ...pageArr
        ]);
    }, [history?.location]);

    return (
        <>
            <div className="breadcrumbs ">
                <div className="container breadcrumbs_info d-flex flex-direction--column justify-content--center">
                    <div className="breadcrumb_items">
                        {pages?.map((data, i) =>
                            <span key={'breadcrumb ' + i} className="d-flex">
                                <p className="breadcrumb_items_name">{data?.name}</p>
                                {/* <NavLink to={data?.path} className="breadcrumb_items_name">{data?.name}</NavLink> */}
                                {pages?.length - 1 > i ?
                                    <img className="breadcrumb_items_icon" src={PathRightIcon} alt="" />
                                    : ''
                                }
                            </span>
                        )}
                    </div>

                    <p className="breadcrumb_active">{pages[pages?.length - 1]?.name}</p>
                </div>
            </div>
        </>
    )
};

Breadcrumbs.propTypes = {};

Breadcrumbs.defaultProps = {};

export default Breadcrumbs;
