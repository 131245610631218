import React from "react";
import { useForm } from "react-hook-form";
import {
    gql,
    useMutation
} from "@apollo/client";

import ErrorIcon from "../../../../assets/icons/error.svg";

const CREATECALLCENTER = gql`
mutation CreateCallCenter ($fullname: String!, $phone: String!, $email: String!) {
  createCallCenter(input:
        { data: {
            fullname: $fullname,
            phone: $phone,
            email: $email,
        }}) {
            callCenter {
                id
            }
        }
      }
`;

const CallCenter = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const [onMutationSubmit, { loading, error, data }] = useMutation(CREATECALLCENTER, {
    onCompleted: data => {
        reset();
    }
});

  const onSubmit = (data) => {
    onMutationSubmit({
        variables: {
          fullname: data.fullname,
          email: data.email,
          phone: data.phone
        }
      })
  };

  return (
    <div className="page_data">
      <div className="calculate_container container">
        <p className="contact_form_head corp_head">
          DHL Express əməkdaşlarının sizinlə əlaqə saxlaması üçün aşağıdakı
          məlumatları daxil edin.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form_item">
            <p className="form_head">Tam adınız</p>
            <input
              className={`form_input single_input ${
                errors.fullname ? "error" : ""
              }`}
              type="text"
              placeholder="Ad, Soyad *"
              {...register("fullname", {
                required: {
                  value: true,
                  message: "Required",
                },
              })}
            />
            {errors.fullname ? (
              <p className="d-flex align-items--center">
                <img className="error_icon" src={ErrorIcon} alt="" />
                Form field error text.
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="form_item single_input">
            <p className="form_head">Telefon nömrəsi</p>
            <div className="number_input">
              <select className="country_code">
                <option value="">994</option>
                <option value="">000</option>
                <option value="">000</option>
              </select>
              <input
                className={`form_input number ${errors.phone ? "error" : ""}`}
                type="text"
                placeholder="__ ___ __ __ *"
                {...register("phone", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
              />
            </div>
            {errors.phone ? (
              <p className="d-flex align-items--center">
                <img className="error_icon" src={ErrorIcon} alt="" />
                Form field error text.
              </p>
            ) : (
              ""
            )}
          </div>

          <div className="form_item">
            <p className="form_head">Elektron poçt</p>
            <input
              className={`form_input single_input ${
                errors.email ? "error" : ""
              }`}
              type="text"
              placeholder="E-poçt *"
              {...register("email", {
                required: {
                  value: true,
                  message: "Required",
                },
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  message: "Invalid Email",
                },
              })}
            />
            {errors.email ? (
              <p className="d-flex align-items--center">
                <img className="error_icon" src={ErrorIcon} alt="" />
                Form field error text.
              </p>
            ) : (
              ""
            )}
          </div>

          <div className="form_submit_parent">
                <button
                  className="form_submit "
                  type="submit"
                >
                  Göndər
                </button>
              </div>
        </form>
      </div>
    </div>
  );
};

CallCenter.propTypes = {};

CallCenter.defaultProps = {};

export default CallCenter;
