import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";
import { useParams } from "react-router";
import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const CHANCE = gql`
query GetChance($_locale: String!, $slug: String!){
  blogsnews(where: {locale: $_locale slug: $slug}) {
      pageMainContent{
        title,
        description
      }
    }
  }
`;

// parceldeliveryterm

const ChanceDetail = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [chance, setChance] = useState(null);

  let { slug } = useParams();

  useQuery(CHANCE, {
    variables: {
      _locale: lang,
      slug
    },
    onCompleted(data) {
      if (data?.blogsnews) {
        setChance(data.blogsnews[0]);
      }
    }
  });

  return (
    <>
      <div className="container">
        <div className="page_data">
          <h2 className="data_head">{chance?.pageMainContent?.title}</h2>
          <ReactMarkdown className="data_desc">
            {chance?.pageMainContent?.description}
          </ReactMarkdown>
        </div>
      </div>
    </>
  )
};

ChanceDetail.propTypes = {};

ChanceDetail.defaultProps = {};

export default ChanceDetail;
