import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";
import { NavLink, useLocation } from 'react-router-dom';
import {
    useQuery,
    gql
} from "@apollo/client";

const ABOUTUS = gql`
query GetAboutUs($_locale: String!){
    aboutus(locale: $_locale) {
      bannertitle,
      bannerdescription,
      bannerimg{
        formats
      }
    }
  }
`;

const AboutUsBanner = () => {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [aboutUs, setAboutUs] = useState(null);

    useQuery(ABOUTUS, {
        variables: {
            _locale: lang
        },
        onCompleted(data) {
            if (data?.aboutus) {
                setAboutUs(data.aboutus);
            }
        }
    });

    return (
        // src={process.env.REACT_APP_BASE_URL_PURE + '/' + vacancyGeneralInfo?.image[0]?.formats?.medium?.url}
        <div className="about_banner" style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL_PURE + '/'+aboutUs?.bannerimg?.formats?.large?.url})` }}>
            <div className="container">
                <div className="about_banner_jumbotron">
                    <h1 className="jumbotron_header">{aboutUs?.bannertitle}</h1>
                    <ReactMarkdown className="jumbotron_desc">
                        {aboutUs?.bannerdescription}
                    </ReactMarkdown>
                </div>
            </div>
        </div>
    )
};

AboutUsBanner.propTypes = {};

AboutUsBanner.defaultProps = {};

export default AboutUsBanner;
