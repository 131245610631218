import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

import EyeIcon from '../../../../../../assets/icons/akar-icons_eye.svg';

const GALLERY = gql`
query GetGallery{
  gallery {
      image{
        formats,
        url
      }
    }
  }
`;

// parceldeliveryterm

const Gallery = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [gallery, setGallery] = useState(null);
  let [modalStatus, setModalStatus] = useState(false);
  let [modalImgUrl, setModalImgUrl] = useState('');

  useQuery(GALLERY, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      console.log(data);
      if (data?.gallery?.image) {
        setGallery(data.gallery.image);
      }
    }
  });

  const openModal = (i) => {
    setModalImgUrl(process.env.REACT_APP_BASE_URL_PURE + '/' + (gallery[i]?.formats?.large?.url || gallery[i]?.url));
    setModalStatus(true);
  }

  return (
    <>
      <div className="kiv_items">
        {gallery?.map((item, i) => {
          return (
            <div onClick={() => openModal(i)} key={'photo-' + i} className="kiv_item kiv_photo" style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL_PURE + '/' + (item?.formats?.medium?.url || item?.url)})` }}>
              <div className="overlay"></div>
              <img className="kiv_hover_action" src={EyeIcon} alt="" />
            </div>
          )
        })}
      </div>
      {modalStatus ? 
        <div className="img-modal">
          <div className="layer" onClick={() => setModalStatus(false)}></div>
          <span className="close-modal" onClick={() => setModalStatus(false)}>x</span>
          <img src={modalImgUrl}/>
        </div>
        : ''
      }
    </>
  )
};

Gallery.propTypes = {};

Gallery.defaultProps = {};

export default Gallery;
