import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const PARCELDELIVERYTERM = gql`
query GetParcelDeliveryTerm($_locale: String!){
  parceldeliveryterm(locale: $_locale) {
      info{
        title,
        description,
        image{
          formats
        }
      }
    }
  }
`;

// parceldeliveryterm

const ParcelDeliveryTerms = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [parcelDeliveryTerm, setParcelDeliveryTerm] = useState(null);

  useQuery(PARCELDELIVERYTERM, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if(data?.parceldeliveryterm?.info){
        setParcelDeliveryTerm(data.parceldeliveryterm.info);
      }
    }
  });

  return (
    <>
       <div className="page_data">
        <h2 className="data_head">{parcelDeliveryTerm?.title}</h2>
        <ReactMarkdown className="data_desc">
          {parcelDeliveryTerm?.description}
        </ReactMarkdown>
      </div>
    </>
  )
};

ParcelDeliveryTerms.propTypes = {};

ParcelDeliveryTerms.defaultProps = {};

export default ParcelDeliveryTerms;
