import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  useQuery,
  gql,
  useMutation
} from "@apollo/client";
import ReactMarkdown from "react-markdown";

import { useForm } from "react-hook-form";
import ErrorIcon from "../../../../../../assets/icons/error.svg"

import axios from "axios";
import SuccessCard from "../../../../successCard/SuccessCard";

const SERVICESRESTRICTIONS = gql`
query GetServicesRestrinctions($_locale: String!){
  servicesrestriction(locale: $_locale) {
      info{
        title,
        description,
        image{
          formats
        }
      }
    }
  }
`;

const CREATELOG = gql`
mutation CreateLog ($buyerCountry: String!, $buyerName: String!, $senderEmail: String!, $shipmentContent: String!, $shipmentCost: String!, $shipmentPurpose: String!, $postalCode: String!, $aboutCustomer: String!, $customerPhone: String!, $customerEmail: String!) {
  createRestrictionlog(input:
        { data: {
          buyerCountry: $buyerCountry
          buyerName: $buyerName
          senderEmail: $senderEmail
          shipmentContent: $shipmentContent
          shipmentCost: $shipmentCost
          shipmentPurpose: $shipmentPurpose
          postalCode: $postalCode
          aboutCustomer: $aboutCustomer
          customerPhone: $customerPhone
          customerEmail: $customerEmail
        }}) {
          restrictionlog {
                id
            }
        }
      }
`;


const Restrictions = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [serviesRestrictions, setServicesRestrictions] = useState(null);
  let [showSuccess, setShowSuccess] = useState(false);

  let [zipCodeType, setZipCodeType] = useState('number');

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

  useEffect(() => {
    let type = watch('buyerCountry') === 'GB' ? 'text' : 'number';
    setZipCodeType(type)
}, [watch('buyerCountry')])


  useQuery(SERVICESRESTRICTIONS, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if (data?.servicesrestriction?.info) {
        setServicesRestrictions(data.servicesrestriction.info);
      }
    }
  });

  const [onMutationSubmit, { loading, error, data }] = useMutation(CREATELOG, {
    onCompleted: data => {
      reset();
    }
  });

  const onFormSubmit = (data) => {
    onMutationSubmit({
      variables: {
        buyerCountry: data.buyerCountry,
        buyerName: data.buyerName,
        senderEmail: data.senderEmail,
        shipmentContent: data.shipmentContent,
        shipmentCost: data.shipmentCost,
        shipmentPurpose: data.shipmentPurpose,
        postalCode: data.postalCode,
        aboutCustomer: data.aboutCustomer,
        customerPhone: data.customerPhone,
        customerEmail: data.customerEmail,
      }
    });

    axios.post(process.env.REACT_APP_BASE_URL_PURE + '/email', {
      to: 'elnur.isazade@knexel.com',
      // from: 'joelrobuchon@strapi.io',
      // cc: 'helenedarroze@strapi.io',
      // bcc: 'ghislainearabian@strapi.io',
      // replyTo: 'annesophiepic@strapi.io',
      subject: 'DHL - Alıcı ölkənin göndərişin tərkibinə görə tətbiq etdiyi, məhdudiyyət və qadağalar',
      text: 'Hello world!',
      html: `
        <div>
          <strong>Alıcı ölkə:</strong>
          <span>${data.buyerCountry}</span>
          <br/>
          <strong>Alıcının və ya şirkətin adı:</strong>
          <span>${data.buyerName}</span>
          <br/>
          <strong>Göndəricinin və ya şirkətin adı:</strong>
          <span>${data.senderEmail}</span>
          <br/>
          <strong>Göndərişin tərkibi:</strong>
          <span>${data.shipmentContent}</span>
          <br/>
          <strong>Göndərişin dəyəri:</strong>
          <span>${data.shipmentCost}</span>
          <br/>
          <strong>Göndərişin məqsədi:</strong>
          <span>${data.shipmentPurpose}</span>
          <br/>
          <strong>Poçt indeksi</strong>
          <span>${data.postalCode}</span>
          <br/>
          <strong>Müştəri haqqında:</strong>
          <span>${data.aboutCustomer}</span>
          <br/>
          <strong>Müştəri telefon:</strong>
          <span>${data.customerPhone}</span>
          <br/>
          <strong>Müştəri email:</strong>
          <span>${data.customerEmail}</span>
        </div>
      `,
    })
      .then(function (response) {
        reset();
        setShowSuccess(true)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  if (!showSuccess) {
    return (
      <>
        <div className="page_data">
          <img className="page_img" src={process.env.REACT_APP_BASE_URL_PURE + '/' + serviesRestrictions?.image[0]?.formats?.large?.url} alt="" />
          <h2 className="data_head">{serviesRestrictions?.title}</h2>
          <ReactMarkdown className="data_desc">
            {serviesRestrictions?.description}
          </ReactMarkdown>

          <div className="stepper_form">
            <form className="form_first_step " onSubmit={handleSubmit(onFormSubmit)}>
              <div className="form_item">

                <div className="two_inputs no_margin">
                  <div>
                    <p className="form_head">Alıcı ölkə</p>
                    <input className={`form_input  ${errors.buyerCountry ? "error" : ""}`} type="text" placeholder="Rusiya *"
                      {...register("buyerCountry", {
                        required: {
                          value: true,
                          message: 'Required'
                        }
                      })} />

                    {errors.buyerCountry ?
                      <p className="d-flex align-items--center">
                        <img className="error_icon" src={ErrorIcon} alt="" />
                        Form field error text.
                      </p> : ''
                    }
                  </div>
                  <div>
                    <p className="form_head">Alıcının və ya şirkətin adı</p>
                    <input className={`form_input  ${errors.buyerName ? "error" : ""}`} type="text" placeholder="Ad *"
                      {...register("buyerName", {
                        required: {
                          value: true,
                          message: 'Required'
                        }
                      })} />

                    {errors.buyerName ?
                      <p className="d-flex align-items--center">
                        <img className="error_icon" src={ErrorIcon} alt="" />
                        Form field error text.
                      </p> : ''
                    }
                  </div>
                </div>
              </div>
              <div className="form_item">
                <p className="form_head">Göndəricinin və ya şirkətin adı</p>
                <div className="two_inputs">
                  <input className={`form_input ${errors.senderEmail ? "error" : ""}`} type="text" placeholder="E-poçt *"
                    {...register("senderEmail", {
                      required: {
                        value: true,
                        message: 'Required'
                      },
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                        message: 'Invalid Email'
                      }
                    })} />

                  {errors.senderEmail ?
                    <p className="d-flex align-items--center">
                      <img className="error_icon" src={ErrorIcon} alt="" />
                      Form field error text.
                    </p> : ''
                  }
                  <div></div>
                </div>
              </div>
              <div className="form_item">

                <div className="two_inputs no_margin">
                  <div>
                    <p className="form_head">Göndərişin tərkibi</p>
                    <input className={`form_input  ${errors.shipmentContent ? "error" : ""}`} type="text" placeholder="Göndərişin tərkibi *"
                      {...register("shipmentContent", {
                        required: {
                          value: true,
                          message: 'Required'
                        }
                      })} />
                    {errors.shipmentContent ?
                      <p className="d-flex align-items--center">
                        <img className="error_icon" src={ErrorIcon} alt="" />
                        Form field error text.
                      </p> : ''
                    }
                  </div>
                  <div>
                    <p className="form_head">Göndərişin dəyəri</p>
                    <input className={`form_input  ${errors.shipmentContent ? "error" : ""}`} type="text" placeholder="İnvoys dəyəri *"
                      {...register("shipmentCost", {
                        required: {
                          value: true,
                          message: 'Required'
                        }
                      })} />
                    {errors.shipmentCost ?
                      <p className="d-flex align-items--center">
                        <img className="error_icon" src={ErrorIcon} alt="" />
                        Form field error text.
                      </p> : ''
                    }
                  </div>
                </div>
              </div>
              <div className="form_item">

                <div className="two_inputs no_margin">
                  <div>
                    <p className="form_head">Göndərişin məqsədi </p>
                    <input className={`form_input  ${errors.shipmentPurpose ? "error" : ""}`} type="text" placeholder="Məsələn: Hədiyyə  *"
                      {...register("shipmentPurpose", {
                        required: {
                          value: true,
                          message: 'Required'
                        }
                      })} />
                    {errors.shipmentPurpose ?
                      <p className="d-flex align-items--center">
                        <img className="error_icon" src={ErrorIcon} alt="" />
                        Form field error text.
                      </p> : ''
                    }
                  </div>
                  <div>
                    <p className="form_head">Poçt indeksi </p>
                    <input className={`form_input  ${errors.postalCode ? "error" : ""}`} type="text" placeholder="ZIP Kodu *"
                      {...register("postalCode", {
                        required: {
                          value: true,
                          message: 'Required'
                        }
                      })} />
                    {errors.postalCode ?
                      <p className="d-flex align-items--center">
                        <img className="error_icon" src={ErrorIcon} alt="" />
                        Form field error text.
                      </p> : ''
                    }
                  </div>
                </div>
              </div>
              <div className="form_item">
                <p className="form_head">Müştəri haqqında</p>
                <input className={`form_input single_input ${errors.aboutCustomer ? "error" : ""}`} type="text" placeholder="Ad, soyad *"
                  {...register("aboutCustomer", {
                    required: {
                      value: true,
                      message: 'Required'
                    }
                  })} />
                {errors.aboutCustomer ?
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p> : ''
                }
              </div>
              <div className="form_item">
                <div className="two_inputs">
                  <div className="number_input">
                    <select className="country_code">
                      <option value="">994</option>
                      <option value="">000</option>
                      <option value="">000</option>
                    </select>
                    <input className={`form_input number ${errors.customerPhone ? "error" : ""}`} type="text" placeholder="__ ___ __ __ *"
                      {...register("customerPhone", {
                        required: {
                          value: true,
                          message: 'Required'
                        }
                      })} />
                    {errors.customerPhone ?
                      <p className="d-flex align-items--center">
                        <img className="error_icon" src={ErrorIcon} alt="" />
                        Form field error text.
                      </p> : ''
                    }
                  </div>
                  <input className={`form_input ${errors.customerEmail ? "error" : ""}`} type="text" placeholder="E-poçt"
                    {...register("customerEmail", {
                      required: {
                        value: true,
                        message: 'Required'
                      },
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                        message: 'Invalid Email'
                      }
                    })} />
                  {errors.customerEmail ?
                    <p className="d-flex align-items--center">
                      <img className="error_icon" src={ErrorIcon} alt="" />
                      Form field error text.
                    </p> : ''
                  }
                </div>
              </div>
              <div className="form_submit_parent ">
                <button className="form_submit " type="submit">
                  Növbəti
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <SuccessCard />
    )
  }
};

Restrictions.propTypes = {};

Restrictions.defaultProps = {};

export default Restrictions;
