import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Logo from '../../../../assets/images/logo.svg';
import ArrowDown from '../../../../assets/icons/arrow_down.svg';
import ArrowIcon from '../../../../assets/icons/arrow.svg';
import DeliveryMan from '../../../../assets/icons/delivery-man.svg';
import Hamburger from '../../../../assets/icons/burger_icon.svg';
import Search from '../../../../assets/icons/search.svg';
import User from '../../../../assets/icons/user.svg';
import CloseIcon from '../../../../assets/icons/close.svg';
import { NavLink, useLocation } from 'react-router-dom';

const Header = () => {
    let loc = useLocation();
    const lang = loc.pathname.split('/')[1];
    let [showMobileMenu, setShowMobileMenu] = useState(false);
    let [activeMobileDropdown, setActiveMobileDropdown] = useState('');

    const toggleMobileMenuDropdown = (menu) => {
        activeMobileDropdown !== menu ? setActiveMobileDropdown(menu) : setActiveMobileDropdown('');
    }

    return (
        <>
            <div className={`mob_menu ${showMobileMenu ? "active" : ""}`}>
                <div className="menu_head d-flex align-items--center justify-content--flex-end">
                     {/*<input type="search" className="menu_search" placeholder="search" />*/}
                    <img className="cursor--pointer" src={CloseIcon} alt="" onClick={() => setShowMobileMenu(false)} />
                </div>
                <div className="menu_content">
                    <div className="login_lang">
                        <div className="login">
                            <img src={User} alt="" />
                            <a href='https://mydhl.express.dhl/index/en/mobile.html' target="_blank">Müştəri girişi</a>
                        </div>
                        <div className="lang">
                            <select className="language">
                                <option value="994">AZ</option>
                                <option value="1">EN</option>
                                <option value="2">RU</option>
                            </select>
                        </div>
                    </div>
                    <div className="menu_items">
                        <hr />
                        <div onClick={() => toggleMobileMenuDropdown('menu-1')} className={`${activeMobileDropdown === 'menu-1' ? "menu_item_selected" : "menu_item_non_selected"}`}>
                            <div className="menu_item_name d-flex align-items--center justify-content--space-between">
                                <p className="menu_name">HAQQIMIZDA</p>
                                <img src={ArrowIcon} alt="" />
                            </div>
                            <ul className="menu_opened_items">
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/about-company/about-us`}>Şirkət haqqında</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/about-company/vacancies`}>Vakansiyalar</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/about-company/social-responsibility`}>Korporativ sosial məsuliyyət</NavLink>
                                </li>
                                <li>Məxfilik siyasəti</li>
                            </ul>
                        </div>
                        <hr />
                        <div className="menu_item_non_selected">
                            <div className="menu_item_name d-flex align-items--center justify-content--space-between">
                                <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/for-corporate`} className="menu_name">KORPORATİV MÜŞTƏRİLƏR ÜÇÜN</NavLink>
                            </div>
                        </div>
                        <hr />
                        <div onClick={() => toggleMobileMenuDropdown('menu-2')} className={`${activeMobileDropdown === 'menu-2' ? "menu_item_selected" : "menu_item_non_selected"}`}>
                            <div className="menu_item_name d-flex align-items--center justify-content--space-between">
                                <p className="menu_name">MEDİA MƏRKƏZİ</p>
                                <img src={ArrowIcon} alt="" />
                            </div>
                            <ul className="menu_opened_items">
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/media-center/chances`}>Fürsətlər</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/kiv`}>KIV</NavLink>
                                </li>
                            </ul>
                        </div>
                        <hr />
                        <div className="menu_item_non_selected">
                            <div className="menu_item_name d-flex align-items--center justify-content--space-between">
                                <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/contact`}>ƏLAQƏ</NavLink>
                            </div>
                        </div>
                        <hr />
                        <div className="menu_item_non_selected">
                            <div className="menu_item_name d-flex align-items--center justify-content--space-between">
                                <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/delivery/deliver-transfer-terms`} className="menu_name">Daşınma və Çatdırılma şərtləri</NavLink>
                            </div>
                        </div>
                        <hr />
                        <div onClick={() => toggleMobileMenuDropdown('menu-3')} className={`${activeMobileDropdown === 'menu-3' ? "menu_item_selected" : "menu_item_non_selected"}`}>
                            <div className="menu_item_name d-flex align-items--center justify-content--space-between">
                                <p className="menu_name">Gömrük bəyannaməsi</p>
                                <img src={ArrowIcon} alt="" />
                            </div>
                            <ul className="menu_opened_items">
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)}  to={`/${lang}/info/customs-declaration/import`} activeClassName="active_item">İdxal</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/customs-declaration/export`} activeClassName="active_item">İxrac</NavLink>
                                </li>
                            </ul>
                        </div>
                        <hr />
                        <div onClick={() => toggleMobileMenuDropdown('menu-4')} className={`${activeMobileDropdown === 'menu-4' ? "menu_item_selected" : "menu_item_non_selected"}`}>
                            <div className="menu_item_name d-flex align-items--center justify-content--space-between">
                                <p className="menu_name">Faydalı məlumatlar</p>
                                <img src={ArrowIcon} alt="" />
                            </div>
                            <ul className="menu_opened_items">
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/usefull/parcel-delivery-terms`}>Daşınma şərtləri - bağlamalar üçün</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/usefull/document-delivery-terms`}>Daşınma şərtləri - sənədlər üçün</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/usefull/terminology`}>Terminalogiya</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/usefull/faq`}>Ən çox verilən suallar</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/usefull/downloads`}>Yükləmələr</NavLink>
                                </li>
                            </ul>
                        </div>
                        <hr />
                        <div className="menu_item_non_selected">
                            <div className="menu_item_name d-flex align-items--center justify-content--space-between">
                                <NavLink onClick={() => setShowMobileMenu(false)} to={`/${lang}/info/blocked-products`} className="menu_name">DHL vasitəsi  ilə daşınması qadağan olan mallar</NavLink>
                            </div>
                        </div>
                        <button className="call_courier_mobile d-flex justify-content--center align-items--center">
                            <img className="delivery_man" src={DeliveryMan} alt="" />
                            KURYER ÇAĞIR
                        </button>
                    </div>

                </div>
            </div>

            <div className="main_header ">
                <div className="main_header_content container d-flex justify-content--space-between align-items--center">
                    <NavLink to={`/`} className="logo">
                        <img src={Logo} alt="" />
                    </NavLink>
                    <div className="main_header_menu">
                        <nav className="main_header_menu_nav" role="navigation">
                            <ul className="menu_nav_items">
                                <li className="menu_nav_item">
                                    <NavLink to={`/${lang}/info/delivery/deliver-transfer-terms`}>Daşınma və Çatdırılma şərtləri</NavLink></li>
                                <li className="menu_nav_item"><a href="#">Gömrük
                                    bəyannaməsi </a>
                                    <img className="arrow_down" src={ArrowDown} alt="" />
                                    <ul className="dropdown">
                                        <li>
                                            <NavLink className="menu_nav_item dropdown_item" to={`/${lang}/info/customs-declaration/import`} activeClassName="active_item">İdxal</NavLink>
                                        </li>
                                        <li >
                                            <NavLink className="menu_nav_item dropdown_item" to={`/${lang}/info/customs-declaration/export`} activeClassName="active_item">İxrac</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="menu_nav_item">
                                    <NavLink to={`/${lang}/info/usefull`}>Faydalı məlumatlar</NavLink>
                                    <img className="arrow_down" src={ArrowDown} alt="" />
                                    <ul className="dropdown">
                                        <li >
                                            <NavLink className="menu_nav_item dropdown_item" to={`/${lang}/info/usefull/parcel-delivery-terms`}>Daşınma şərtləri - bağlamalar üçün</NavLink>
                                        </li>
                                        <li >
                                            <NavLink className="menu_nav_item dropdown_item" to={`/${lang}/info/usefull/document-delivery-terms`}>Daşınma şərtləri - sənədlər üçün</NavLink>
                                        </li>
                                        <li >
                                            <NavLink className="menu_nav_item dropdown_item" to={`/${lang}/info/usefull/terminology`}>Terminalogiya</NavLink>
                                        </li>
                                        <li >
                                            <NavLink className="menu_nav_item dropdown_item" to={`/${lang}/info/usefull/faq`}>Ən çox verilən suallar</NavLink>
                                        </li>
                                        <li >
                                            <NavLink className="menu_nav_item dropdown_item" to={`/${lang}/info/usefull/downloads`}>Yükləmələr</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className="menu_nav_item">
                                    <NavLink to={`/${lang}/info/blocked-products`}>DHL vasitəsi  ilə daşınması qadağan olan mallar</NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="main_header_action">
                        <button className="call_courier d-flex justify-content--space-between align-items--center">
                            <img className="delivery_man" src={DeliveryMan} alt="" />
                            KURYER ÇAĞIR
                        </button>
                        <div className="mobile_menu" onClick={() => setShowMobileMenu(true)}>
                            <img src={Hamburger} alt="" />
                        </div>
                    </div>
                </div>

            </div>
            <div className="secondary_nav container d-flex align-items--center justify-content--space-between">
                <div className="secondary_menu">
                    <nav className="main_header_menu_nav" role="navigation">
                        <ul className="menu_nav_items">
                            <li className="menu_nav_item secondary_menu_nav_item">
                                <NavLink to={`/${lang}/info/about-company`}>HAQQIMIZDA </NavLink>
                                <img className="arrow_down" src={ArrowDown} alt="" />
                                <ul className="dropdown">
                                    <li>
                                        <NavLink className="menu_nav_item secondary_menu_nav_item dropdown_item" to={`/${lang}/info/about-company/about-us`}>Şirkət haqqında</NavLink>
                                    </li>
                                    <li >
                                        <NavLink className="menu_nav_item secondary_menu_nav_item dropdown_item" to={`/${lang}/info/about-company/social-responsibility`}>Korporativ sosial məsuliyyət</NavLink>
                                    </li>
                                    <li >
                                        <NavLink className="menu_nav_item secondary_menu_nav_item dropdown_item" to={`/${lang}/info/about-company/vacancies`}>Vakansiyalar</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu_nav_item secondary_menu_nav_item">
                                <NavLink to={`/${lang}/for-corporate`}>KORPORATİV MÜŞTƏRİLƏR ÜÇÜN</NavLink>
                            </li>
                            <li className="menu_nav_item secondary_menu_nav_item">
                                <NavLink to={`/${lang}/media-center`}>MEDİA MƏRKƏZİ</NavLink>
                                <img className="arrow_down" src={ArrowDown} alt="" />
                                <ul className="dropdown">
                                    <li >
                                        <NavLink className="menu_nav_item secondary_menu_nav_item dropdown_item" to={`/${lang}/media-center/chances`}>Fürsətlər</NavLink>
                                    </li>
                                    <li >
                                        <NavLink className="menu_nav_item secondary_menu_nav_item dropdown_item" to={`/${lang}/kiv`}>KIV</NavLink>
                                    </li>
                                    {/*<li className="menu_nav_item secondary_menu_nav_item dropdown_item"><a href="#">Sub-3</a></li>*/}
                                </ul>
                            </li>
                            <li className="menu_nav_item secondary_menu_nav_item">
                                <NavLink to={`/${lang}/contact`}>ƏLAQƏ</NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="secondary_actions">
                    <ul className="menu_nav_items">
                        <li className="menu_nav_item secondary_menu_nav_item"><a href="#">AZ </a>
                            <img className="arrow_down" src={ArrowDown} alt="" />
                            {/* <ul className="dropdown">
                                <li className="menu_nav_item secondary_menu_nav_item dropdown_item"><a href="#">Sub-1</a></li>
                                <li className="menu_nav_item secondary_menu_nav_item dropdown_item"><a href="#">Sub-2</a></li>
                                <li className="menu_nav_item secondary_menu_nav_item dropdown_item"><a href="#">Sub-3</a></li>
                            </ul> */}
                        </li>
                    </ul>
                    {/*<img className="search_icon" src={Search} alt="" />*/}
                    <div className="login align-items--center">
                        <img className="user_icon" src={User} alt="" />
                        <a href='https://mydhl.express.dhl/index/en/mobile.html' target="_blank">Müştəri girişi</a>
                    </div>
                </div>
            </div>
        </>
    )
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
