import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";
import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const ABOUTUS = gql`
query GetAboutUs($_locale: String!){
    aboutus(locale: $_locale) {
      aboutustitle,
      aboutusdescription,
      statisticstitle,
      statistics{
        name,
        description
      },
      aboutusadditionaldescription,
      trackingtitle,
      additionalservicetitle,
      additionalserviceitem{
        name
      },
      additionalservicedecription,
      additionalservivces{
        description,
        img{
          formats,
          url
        }
      }
    }
  }
`;

const AboutUs = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [aboutUs, setAboutUs] = useState(null);

  useQuery(ABOUTUS, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if (data?.aboutus) {
        setAboutUs(data.aboutus);
      }
    }
  });

  return (
    <>
      <div className="page_data">
        <h2 className="data_head">{aboutUs?.aboutustitle}</h2>
        <ReactMarkdown className="data_desc">
          {aboutUs?.aboutusdescription}
        </ReactMarkdown>
        <p className="about_name">{aboutUs?.statisticstitle}</p>
        <div className="about_advances">
          {aboutUs?.statistics.map((item, i) => {
            return (
              <div key={'statistics-' + i} className="advances_item">
                <p className="item_count">{item.name}</p>
                <p className="item_desc">{item.description}</p>
              </div>
            )
          })}
        </div>
        <ReactMarkdown className="data_desc">
          {aboutUs?.aboutusadditionaldescription}
        </ReactMarkdown>

        <div className="track_offer">
          <p className="offer_head">{aboutUs?.trackingtitle}</p>
          <button className="track_action">DHL eTrack və Online İzləmə</button>
        </div>
        <p className="extra_services">{aboutUs?.additionalservicetitle}</p>
        <div
          className="extra_services_parts d-flex align-items--center justify-content--space-between mobile--flex-direction--column">
          {aboutUs?.additionalserviceitem?.map((item, i) => {
            return (
              <div key={'additionalserviceitem-'+i} className="part_item d-flex flex-direction--column align-items--center">
                <div className="part_circle d-flex align-items--center justify-content--center">
                  {i+1}
                </div>
                <p className="part_desc">{item.name}</p>
              </div>
            )
          })}
        </div>

        <ReactMarkdown className="services_desc">
          {aboutUs?.additionalservicedecription}
        </ReactMarkdown>

        {aboutUs?.additionalservivces.map((item, i) => {
          return (
            <div key={'additionalservivces-' + i} className="advances_card d-flex align-items--center justify-content--space-between">
              <div className="card_icon d-flex align-items--center justify-content--center">
                <img src={process.env.REACT_APP_BASE_URL_PURE + '/' + (item.img?.formats?.large?.url || item.img?.url)} alt="" />
              </div>
              <div className="card_descrip">
                <p>{item.description}</p>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
};

AboutUs.propTypes = {};

AboutUs.defaultProps = {};

export default AboutUs;
