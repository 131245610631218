import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  useQuery,
  gql,
  useMutation
} from "@apollo/client";
import ReactMarkdown from "react-markdown";

import { useForm } from "react-hook-form";
import ErrorIcon from "../../../../../../assets/icons/error.svg"

import axios from "axios";
import SuccessCard from "../../../../successCard/SuccessCard";

const CUSTOMDECLARATIONIMPORT = gql`
query GetcustomdeclartionImport($_locale: String!){
  customdeclarationimport(locale: $_locale) {
      info{
        title,
        description,
        image{
          formats
        }
      }
    }
  }
`;

const CREATELOG = gql`
mutation CreateLog ($fullname: String!, $email: String!, $phone: String!, $ticketnumber: String!) {
  createCustomsdeclarationimportlog(input:
        { data: {
            fullname: $fullname,
            email: $email,
            phone: $phone,
            ticketnumber: $ticketnumber
        }}) {
          customsdeclarationimportlog {
                id
            }
        }
      }
`;

const CustomDeclarationImport = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [customDeclartionImport, setCustomDeclartionImport] = useState(null);
  let [showSuccess, setShowSuccess] = useState(false);

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

  useQuery(CUSTOMDECLARATIONIMPORT, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if (data?.customdeclarationimport?.info) {
        setCustomDeclartionImport(data.customdeclarationimport.info);
      }
    }
  });

  const [onMutationSubmit, { loading, error, data }] = useMutation(CREATELOG, {
    onCompleted: data => {
      reset();
    }
  });

  const onFormSubmit = (data) => {
    onMutationSubmit({
      variables: {
        fullname: data.fullname,
        email: data.email,
        phone: data.phone,
        ticketnumber: data.qaimeNumber
      }
    })

    axios.post(process.env.REACT_APP_BASE_URL_PURE + '/email', {
      to: 'elnur.isazade@knexel.com',
      // from: 'joelrobuchon@strapi.io',
      // cc: 'helenedarroze@strapi.io',
      // bcc: 'ghislainearabian@strapi.io',
      // replyTo: 'annesophiepic@strapi.io',
      subject: 'DHL - Gömrük bəyannaməsi (Idxal)',
      text: 'Hello world!',
      html: `
        <div>
          <strong>Aviaqaimə nömrəsi:</strong>
          <span>${data.qaimeNumber}</span>
          <br/>
          <strong>Ad, soyad:</strong>
          <span>${data.fullname}</span>
          <br/>
          <strong>Telefon:</strong>
          <span>${data.phone}</span>
          <br/>
          <strong>Email:</strong>
          <span>${data.email}</span>
        </div>
      `,
    })
      .then(function (response) {
        reset();
        setShowSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  if (!showSuccess) {
    return (
      <>
        <div className="page_data">
          <img className="page_img" src={process.env.REACT_APP_BASE_URL_PURE + '/' + customDeclartionImport?.image[0]?.formats?.large?.url} alt="" />
          <h2 className="data_head">{customDeclartionImport?.title}</h2>
          <ReactMarkdown className="data_desc">
            {customDeclartionImport?.description}
          </ReactMarkdown>

          <div className="stepper_form">
            <form className="form_first_step " onSubmit={handleSubmit(onFormSubmit)}>

              <div className="form_item">
                <p className="form_head">Aviaqaimə nömrəsi</p>
                <div className="relative">
                  <input className={`form_input info_input ${errors.qaimeNumber ? "error" : ""}`} type="text" placeholder="Aviaqaimə nömrəsi *"
                    {...register("qaimeNumber", {
                      required: {
                        value: true,
                        message: 'Required'
                      }
                    })} />
                  <span className="icon" title="göndərişiniz DHL Express trərəfində təhvil alındıqda verilən sənədin (aviaqaimənin) üzərində qeyd olunan onrəqəməli izləmə nömrəsi (waybill number)"></span>
                </div>

                {errors.qaimeNumber ?
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p> : ''
                }
              </div>

              <div className="form_item">
                <input className={`form_input single_input ${errors.fullname ? "error" : ""}`} type="text" placeholder="Ad, soyad *"
                  {...register("fullname", {
                    required: {
                      value: true,
                      message: 'Required'
                    }
                  })} />
                {errors.fullname ?
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p> : ''
                }
              </div>
              <div className="form_item">
                <div className="two_inputs">
                  <div className="form_item">
                    <div className="number_input">
                      <select className="country_code"
                        {...register("countryCode", {
                          required: {
                            value: true,
                            message: 'Required'
                          }
                        })}
                      >
                        <option value="994">994</option>
                      </select>
                      <input className={`form_input number ${errors.phone ? "error" : ""}`} type="text" placeholder="__ ___ __ __ *"
                        {...register("phone", {
                          required: {
                            value: true,
                            message: 'Required'
                          }
                        })} />
                    </div>

                    {errors.phone ?
                      <p className="d-flex align-items--center">
                        <img className="error_icon" src={ErrorIcon} alt="" />
                        Form field error text.
                      </p> : ''
                    }
                  </div>

                  <div className="form_item">
                    {/* className={`half_input ${errors.email ? "error" : ""}`} */}
                    <input className="form_input" className={`form_input ${errors.email ? "error" : ""}`} type="text" placeholder="E-poçt"
                      {...register("email", {
                        required: {
                          value: true,
                          message: 'Required'
                        },
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                          message: 'Invalid Email'
                        }
                      })} />
                    {errors.email ?
                      <p className="d-flex align-items--center">
                        <img className="error_icon" src={ErrorIcon} alt="" />
                        Form field error text.
                      </p> : ''
                    }
                  </div>
                </div>
              </div>
              <div className="form_submit_parent">
                <button className="form_submit " type="submit">
                  Göndər
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <SuccessCard />
    )
  }
};

CustomDeclarationImport.propTypes = {};

CustomDeclarationImport.defaultProps = {};

export default CustomDeclarationImport;
