import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown"

import {
  useQuery,
  gql
} from "@apollo/client";

import BusinessAndTrade from '../../../../../../../assets/icons/business-and-trade.svg';
import CalendarYellow from '../../../../../../../assets/icons/calendar_yellow.svg';
import { useParams } from 'react-router';

const VACANCIES_DETAIL = gql`
query GetVacancies($_locale: String!, $slug: String!){
    vacancies(where: {_locale: $_locale, slug: $slug}) {
      id,
      slug,
      name,
      startDate,
      expireDate,
      location,
      requirements
    }
  }
`;

const VacancyDetails = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [vacancyDetails, setVacancyDetails] = useState(null);

  let { slug } = useParams();
  const { detailsLoading, detailsError, detailsData } = useQuery(VACANCIES_DETAIL, {
    variables: { slug, _locale: lang },
    onCompleted: data => {
      console.log(data);
      setVacancyDetails(data.vacancies[0])
    }
  });

  return (
    <>
      <div className="page_data">
        <h2 className="data_head">{vacancyDetails?.name}</h2>
        <div className="vacancy_info">
          <div className="vacancy_desc">
            <img className="vacancy_icon" src={BusinessAndTrade} alt="" />
            <p className="vacancy_name">{vacancyDetails?.location}</p>
          </div>
          <div className="vacancy_desc">
            <img className="vacancy_icon" src={CalendarYellow} alt="" />
            <p className="vacancy_name">{vacancyDetails?.expireDate}</p>
          </div>
          <div className="vacancy_desc">
            <img className="vacancy_icon" src={CalendarYellow} alt="" />
            <p className="vacancy_name">{vacancyDetails?.startDate}</p>
          </div>
        </div>
        <ReactMarkdown className="data_desc">
          {vacancyDetails?.requirements}
        </ReactMarkdown>
      </div>
    </>
  )
};

VacancyDetails.propTypes = {};

VacancyDetails.defaultProps = {};

export default VacancyDetails;
