import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const UNIVERSALDELIVERY = gql`
query GetUniversalDelivery($_locale: String!){
  universaldelivery(locale: $_locale) {
      info{
        title,
        description,
        image{
          formats
        }
      }
    }
  }
`;

const UniversalDelivery = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [universalDelivery, setUniversalDelivery] = useState(null);

  useQuery(UNIVERSALDELIVERY, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if(data?.universaldelivery?.info){
        setUniversalDelivery(data.universaldelivery.info);
      }
    }
  });

  return (
    <>
      <div className="page_data">
        <h2 className="data_head">{universalDelivery?.title}</h2>
        <ReactMarkdown className="data_desc">
          {universalDelivery?.description}
        </ReactMarkdown>
      </div>
    </>
  )
};

UniversalDelivery.propTypes = {};

UniversalDelivery.defaultProps = {};

export default UniversalDelivery;
