import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

// assets/icons/download_yellow.svg
import DownloadYellowIcon from '../../../../../../assets/icons/download_yellow.svg';

const DOWNLOADS = gql`
query GetDownloads($_locale: String!){
  downloads(locale: $_locale) {
      item{
        img{
          url
        },
        description
      }
    }
  }
`;

// parceldeliveryterm

const Downloads = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [downloads, setDownloads] = useState(null);

  useQuery(DOWNLOADS, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if (data?.downloads) {
        setDownloads(data.downloads);
      }
    }
  });

  return (
    <>
      <div className="page_data">
        <div className="collapsible_list">
          {downloads?.map((download, i) => {
            return (
              <>
                <div className="download_item" key={'downloads-'+i}>
                  <p className="collap_head">{download.item?.description}</p>
                  <a target="_blank" download href={process.env.REACT_APP_BASE_URL_PURE + download.item.img?.url} className="download_action">
                    <p className="download_name">Yüklə</p>
                    <img src={DownloadYellowIcon} alt=""/>
                  </a>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>

// ${process.env.REACT_APP_BASE_URL_PURE + '/'+
  )
};

Downloads.propTypes = {};

Downloads.defaultProps = {};

export default Downloads;
