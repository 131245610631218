import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

import DownloadIcon from '../../../../../../assets/icons/ant-design_download-outlined.svg';

const LOGO = gql`
query GetLogos{
  logo {
      image{
        formats,
        url
      }
    }
  }
`;

// parceldeliveryterm

const Logo = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [logo, setLogo] = useState(null);

  useQuery(LOGO, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      console.log(data);
      if (data?.logo?.image) {
        setLogo(data.logo.image);
      }
    }
  });

  return (

    <div className="kiv_items">
      {logo?.map((item, i) => {
        return (
          // <div key={'photo-'+i} class="kiv_item kiv_photo" style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL_PURE + '/' + (item?.formats?.medium?.url || item?.url)})` }}>
          //   <div class="overlay"></div>
          //   <img class="kiv_hover_action" src={EyeIcon} alt=""/>
          // </div>

          <div className="kiv_item" key={'logo-'+i}>
            <img className="kiv_item_img" src={process.env.REACT_APP_BASE_URL_PURE + '/' + (item?.formats?.medium?.url || item?.url)} alt=""/>
            <a href={process.env.REACT_APP_BASE_URL_PURE + '/' + (item?.formats?.medium?.url || item?.url)} download class ="download_button">
            <img className="download_icon" src={DownloadIcon} alt=""/>  Yüklə
            </a>
          </div>
        )
      })}
    </div>
  )
};

Logo.propTypes = {};

Logo.defaultProps = {};

export default Logo;
