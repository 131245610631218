import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ArrowForward2Icon from '../../../../assets/icons/arrow-forward2.svg';
import { NavLink, useLocation } from 'react-router-dom';
import {
    useQuery,
    gql
} from "@apollo/client";
import ReactMarkdown from "react-markdown";
import Carousel from 'react-elastic-carousel';

const GETHOMEDATA = gql`
query GetHome($_locale: String!){
  home(locale: $_locale) {
      bannertitle,
      bannerimg {
          formats,
          url
      },
      advantage{
          title,
          description,
          image{
              formats,
              url
          }
      },
      ourservicestitle,
      ourservicesdescription,
      serviceitem{
        title,
        description,
        pageurl,
        img{
            formats,
            url
        }
      },
      opportunitiestitle,
      opportunitiesdescription,
      aboutcompany,
      sendparcelstepstitle,
      sendparcelstepsdescription,
      processSteps{
          description,
          img{
              url
          }
      }
    }
  }
`;

const CHANCES = gql`
query GetChanges($_locale: String!){
  blogsnews(locale: $_locale) {
      pageName,
      shortDesc,
      slug,
      pageMainContent{
        image{
          formats,
          url
        }
      }
    }
  }
`;

const Contact = () => {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [breakPoints] = useState([
        { width: 1, itemsToShow: 1, pagination: false },
        { width: 550, itemsToShow: 3, pagination: false },
        // { width: 991, itemsToShow: 4, pagination: false },
        { width: 1024, itemsToShow: 3, pagination: false }
    ]);

    let [homeData, setHomeData] = useState(null);
    let [chances, setChances] = useState(null);

    useQuery(GETHOMEDATA, {
        variables: {
            _locale: lang
        },
        onCompleted(data) {
            console.log(data);
            if (data?.home) {
                setHomeData(data.home);
            }
        }
    });

    useQuery(CHANCES, {
        variables: {
            _locale: lang
        },
        onCompleted(data) {
            if (data?.blogsnews) {
                setChances(data.blogsnews);
            }
        }
    });

    return (
        <div className="home">
            <section id="banner" className="home_banner" style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL_PURE + '/'+homeData?.bannerimg[0]?.url})` }}>
                <div className="container banner_data d-flex justify-content--center">
                    <h1 className="banner_jumbotron">
                        {homeData?.bannertitle}
                    </h1>
                    <div className="banner_actions">
                        <div className="actions_flex">
                            <NavLink to={`/${lang}/calculate`} className=" action calculate">QİYMƏT HESABLANMASI</NavLink>
                            <a href="https://express.dhl.ru/en/otslezhivanie/" target="_blank" className="action track">GÖNDƏRİŞİN İZLƏNMƏSİ</a>
                        </div>
                        
                        <div className="actions_order">
                            <NavLink to={`/${lang}/info/place-order`} className="place_order">SİFARİŞ YERLƏŞDİR</NavLink>
                        </div>
                    </div>
                </div>
            </section>

            <section id="home_brief_info">
                <div className="container brief_info">
                    {homeData?.advantage?.map((item, i) => {
                        return (
                            <div className="brief_info_item" key={'advantage-' + i}>
                                <img className="brief_info_item_icon" src={process.env.REACT_APP_BASE_URL_PURE + '/' + item.image[0]?.url} alt="" />
                                <p className="brief_info_item_name">{item.title}</p>
                                <ReactMarkdown className="brief_info_item_desc">
                                    {item?.description}
                                </ReactMarkdown>
                            </div>
                        )
                    })}
                </div>
            </section>

            <section id="home_services" className="home_services">
                <div className="container home_services_container">
                    <h2 className="home_services_head">{homeData?.ourservicestitle}</h2>
                    <ReactMarkdown className="home_services_desc">
                        {homeData?.ourservicesdescription}
                    </ReactMarkdown>
                    <div className="home_services_items">
                        {homeData?.serviceitem.map((item, i) => {
                            return (
                                <div className="home_services_item" key={'service-item-' + i}>
                                    <img className="home_services_item_icon" src={process.env.REACT_APP_BASE_URL_PURE + '/' + item.img[0]?.url} alt="" />
                                    <div className="name_height">
                                        <p className="home_services_item_name">{item.title}</p>
                                    </div>
                                    <ReactMarkdown className="home_services_item_desc">
                                        {item.description}
                                    </ReactMarkdown>
                                    <NavLink to={`/${lang + item.pageurl}`} className="opp_action services_action">
                                        Daha ətraflı <img className="forward" src={ArrowForward2Icon} alt="" />
                                    </NavLink>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section id="opportunities">
                <div className="container opportunities_container">
                    <div className="opportunities_brief">
                        <h3 className="opportunities_brief_name">{homeData?.opportunitiestitle}</h3>
                        <ReactMarkdown className="opportunities_brief_desc">
                            {homeData?.ourservicesdescription}
                        </ReactMarkdown>
                    </div>
                    <Carousel breakPoints={breakPoints} className="opportunities_slider">
                        {chances?.map((item, i) => (
                            <div className="opportunities_item" key={'chance-'+i}>
                                <div className="opportunities_img">
                                <img src={process.env.REACT_APP_BASE_URL_PURE + '/' + (item.pageMainContent?.image[0]?.formats?.medium?.url || item.pageMainContent?.image[0]?.url)} alt="" />
                                </div>
                                <div className="opportunities_info">
                                    <p className="opp_name">{item.pageName}</p>
                                    <p className="opp_desc">{item.shortDesc}</p>
                                    <NavLink to={`/${lang}/media-center/chances/${item.slug}`} className="opp_action">
                                        Daha ətraflı <img className="forward" src={ArrowForward2Icon} alt="" />
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                    {/* <div className="opportunities_slider"> */}
                    {/* <div className="opportunities_item">
                        <div className="opportunities_img">
                            <img src="assets/images/furset.jpg" alt="" />
                        </div>
                        <div className="opportunities_info">
                            <p className="opp_name">20 % endirim fürsəti</p>
                            <p className="opp_desc">Bağlamanızın göndərilməsini müəyyən saatlarda DHL Express veb saytından onlayn sifariş edərək 20 % endirim fürsətindən...</p>
                            <NavLink to={`/${lang}/media-center/chances/${item.slug}`} className="opp_action">
                                Daha ətraflı <img className="forward" src={ArrowForward2Icon} alt="" />
                            </NavLink>
                        </div>
                    </div> */}
                    {/* </div> */}
                </div>
            </section>

            <section id="about">
                <div className="container about_dhl">
                    <ReactMarkdown className="opportunities_brief_wrapper">
                        {homeData?.aboutcompany}
                    </ReactMarkdown>
                    <div className="about_stepper">
                        <p className="stepper_head">{homeData?.sendparcelstepstitle}</p>

                        <div className="divided">
                            {homeData?.processSteps?.map((item, i) => {
                                return (
                                    <>
                                        <div className="d-flex flex-direction--column align-items--center mobile--flex-direction--row mobile_fix">
                                            <img className="divider_icon" src={process.env.REACT_APP_BASE_URL_PURE + '/' + item.img?.url} alt="" />
                                            <p className="divider_name">{item.description}</p>
                                        </div>
                                        {homeData?.processSteps.length - 1 !== i ?
                                            <span className="divider"></span> : ''
                                        }
                                    </>
                                )
                            })}

                        </div>

                        <ReactMarkdown className="stepper_desc">
                            {homeData?.sendparcelstepsdescription}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
        </div>
    )
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
