import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import DhlBlankIcon from '../../../../../../assets/images/dhl_blank.svg';
import DhlMobileIcon from '../../../../../../assets/icons/dhl_mobile.svg';
import VideoIcon from '../../../../../../assets/images/video.svg';
import CheckIcon from '../../../../../../assets/icons/check.svg';
import arrowUp from '../../../../../../assets/icons/color.svg';
import arrowDown from '../../../../../../assets/icons/colord.svg';

import ReactMarkdown from "react-markdown";
import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const SOCIALRESPONSIBILITY = gql`
query GetSocialResponsibility($_locale: String!){
    socialresponsibility(locale: $_locale) {
      bannerimg{
        formats
      }
      logisticstitle,
      logisticsshortdescription,
      statistics{
        name,
        description
      },
      logisticsdecsription,
      solitionstitle,
      solitionsshortdescription,
      solitionsdescription,
      solutionvideoiframe,
      sections{
        sectionname,
        sectiondecsription
        item{
          Name,
          description,
          solution,
          moredetails
        }
      }
    }
  }
`;

const SocialResponsibility = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [socialResponsibility, setSocialResponsibility] = useState(null);

  useQuery(SOCIALRESPONSIBILITY, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if (data?.socialresponsibility) {
        setSocialResponsibility(data.socialresponsibility);
      }
    }
  });

  return (
    <>
      <div className="page_data">
        <img className="page_img" src={process.env.REACT_APP_BASE_URL_PURE + '/' + socialResponsibility?.bannerimg?.formats?.large?.url} alt="" />
        {/* <img className="page_img_mobile " src={DhlMobileIcon} alt="" /> */}
        <h2 className="data_head">{socialResponsibility?.logisticstitle}</h2>
        <p className="data_desc">{socialResponsibility?.logisticsshortdescription}</p>
        <div className="about_advances counts">
          {socialResponsibility?.statistics.map((item, i) => {
            return (
              <div className="advances_items" key={'l-statistics-' + i}>
                <p className="items_count">{item.name}</p>
                <ReactMarkdown className="item_desc">
                  {item?.description}
                </ReactMarkdown>
              </div>
            )
          })}
        </div>
        <ReactMarkdown className="social_desc">
          {socialResponsibility?.logisticsdecsription}
        </ReactMarkdown>

        <h2 className="dhl_green_name">{socialResponsibility?.solitionstitle}</h2>
        <p className="green_name">{socialResponsibility?.solitionsshortdescription}</p>
        <ReactMarkdown className="green_desc">
          {socialResponsibility?.solitionsdescription}
        </ReactMarkdown>
        <iframe className="video_frame" src={socialResponsibility?.solutionvideoiframe} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        {socialResponsibility?.sections.map((itemFr, i) => {
          return (
            <>
              <div className="collapse_data" key={'section-' + i}>
                <h2 className="collapse_head">{itemFr.sectionname}</h2>
                <ReactMarkdown className="green_desc">
                  {itemFr?.sectiondecsription}
                </ReactMarkdown>

                {itemFr.item?.map((section, j) => {
                  return (
                    <>
                      <div className="advanced_div">
                        <p className="advanced_name">{section.Name}</p>
                        <ReactMarkdown className="advanced_desc">
                          {section?.description}
                        </ReactMarkdown>
                      </div>
                      <div className="solution">
                      {/* solution */}
                      <ReactMarkdown>
                        {section.solution}
                      </ReactMarkdown>
                      </div>
                      {/* <div className="specials_item">
                        <img className="check" src={CheckIcon} alt="" />
                        <p className="check_name">Track your greenhouse gas emissions</p>
                      </div>
                      <div className="specials_item">
                        <img className="check" src={CheckIcon} alt="" />
                        <p className="check_name">Track your greenhouse gas emissions</p>
                      </div>
                      <div className="specials_item">
                        <img className="check" src={CheckIcon} alt="" />
                        <p className="check_name">Track your greenhouse gas emissions</p>
                      </div> */}


                      <div className="collapsible">
                        <div className='showHide'>
                          <input type="checkbox" id={"toggle-" + j} />

                          <label htmlFor={"toggle-" + j} className="collapsing">
                            <span className='expand'>

                              Show details
                            </span>
                            <span className="changeArrow arrow-up"><img src={arrowUp} alt=""/></span>
                            <span className="changeArrow arrow-dn"><img src={arrowDown} alt=""/></span>
                          </label>

                          <div className="fieldsetContainer">
                            {/*<fieldset id="fdstLorem">*/}
                              <ReactMarkdown>
                                {section.moredetails}
                              </ReactMarkdown>
                            {/*</fieldset>*/}
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </>
          )
        })}
      </div>
    </>
  )
};

SocialResponsibility.propTypes = {};

SocialResponsibility.defaultProps = {};

export default SocialResponsibility;
