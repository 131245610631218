import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const TERMINOLOGY = gql`
query GetTerminologyTerm($_locale: String!){
  terminology(locale: $_locale) {
      content
    }
  }
`;

// parceldeliveryterm

const Terminology = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [terminology, setTerminology] = useState(null);

  useQuery(TERMINOLOGY, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if(data?.terminology){
        setTerminology(data.terminology);
      }
    }
  });

  return (
    <>
       <div className="page_data">
        <ReactMarkdown className="data_desc">
          {terminology?.content}
        </ReactMarkdown>
      </div>
    </>
  )
};

Terminology.propTypes = {};

Terminology.defaultProps = {};

export default Terminology;
