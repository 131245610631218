import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import axios from "axios";

import AkarIconsCalendarIcon from '../../../../../../assets/icons/akar-icons_calendar.svg';
import MapaAndFlags from '../../../../../../assets/icons/maps-and-flags.svg';
import ArrowForwardDark from '../../../../../../assets/icons/arrow-forward-dark.svg';
import ArrowBack from '../../../../../../assets/icons/arrow_back.svg';
import ArrowForward from '../../../../../../assets/icons/arrow_forward.svg';
import { NavLink, useLocation } from 'react-router-dom';

import {
  useQuery,
  useLazyQuery,
  gql
} from "@apollo/client";

const VACANCIES = gql`
query GetVacancies($_locale: String!,$_start: Int!,$_limit: Int!){
    vacancies(where: {_locale: $_locale, _start: $_start, _limit: $_limit}) {
      id,
      name,
      slug,
      location,
      expireDate
    }
  }
`;

const VACANCYGENERALINFO = gql`
query GetVacancyGeneralInfo($_locale: String!){
  vacancygeneralinfo(locale: $_locale) {
      Info{
        title,
        description,
        image{
          formats
        }
      }
    }
  }
`;

let totalVacancyCount = 0;
let count = 0;

const Vacancies = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [vacancyGeneralInfo, setVacancyGeneralInfo] = useState(null);
  let [vacancyList, setVacancyList] = useState([]);
  let [queryParams, setQueryParams] = useState(null);
  let [paginationCount, setPaginationCount] = useState([]);
  let [activePaginationPage, setActivePaginationPage] = useState(0);

  useQuery(VACANCYGENERALINFO, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      console.log(data);
      if(data?.vacancygeneralinfo?.Info){
        setVacancyGeneralInfo(data.vacancygeneralinfo.Info);
      }
    }
  });

  const [getBlogs, { loading, error, data }] = useLazyQuery(VACANCIES, {
    variables: queryParams,
    fetchPolicy: "network-only",
    onCompleted: newData => {
      setVacancyList([...newData.vacancies])
    },

  });

  useEffect(() => {
    totalVacancyCount = 0;
    const itemCountQuery = `${process.env.REACT_APP_BASE_URL_PURE}/vacancies/count`;
    axios(itemCountQuery).then(resp => {
      totalVacancyCount = resp.data;
      setPaginationCount(Array.from(Array(Math.ceil(totalVacancyCount / 2)).keys()));
      getPage(0)
    });
  }, []);

  let timer;
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (queryParams?._locale) {
        getBlogs();
      }
    }, 200);
  }, [queryParams]);

  const seeMore = () => {
    let paramsData = {
      _locale: lang,
      _start: count,
      _limit: 2,
    }
    setQueryParams({ ...paramsData });
  }

  const getPage = (i) => {
    setActivePaginationPage(i);
    count = i * 2;
    seeMore();
  }

  const nextPage = () => {
    if (paginationCount.length > activePaginationPage + 1) {
      getPage(activePaginationPage + 1);
    }
  }
  const prevPage = () => {
    if (activePaginationPage > 0) {
      getPage(activePaginationPage - 1);
    }
  }

  return (
    <>
      <div className="page_data">
        <img className="page_img" src={process.env.REACT_APP_BASE_URL_PURE + '/' + vacancyGeneralInfo?.image[0]?.formats?.medium?.url} alt="" />
        {/* <img className="page_img_mobile " src={DhlMobile} alt="" /> */}
        <h2 className="data_head">{vacancyGeneralInfo?.title}</h2>
        <p className="data_desc">{vacancyGeneralInfo?.description}</p>
        <div className="vacancies_list">
          {vacancyList.map((vacancy, i) => {
            return (
              <div to={`/${lang}/info/about-company/vacancies/test`} key={`vacancy-${i}`} className="vacancies_card">
                <p className="card_name">{vacancy.id} {vacancy.name}</p>
                <div className="card_infos">
                  <div className="card_date">
                    <img className="date_icon" src={AkarIconsCalendarIcon} alt="" />
                    <p className="date_value">{vacancy.expireDate}</p>
                  </div>
                  <div className="card_date">
                    <img className="date_icon" src={MapaAndFlags} alt="" />
                    <p className="date_value">{vacancy.location}</p>
                  </div>
                </div>
                <NavLink to={`/${lang}/info/about-company/vacancies/${vacancy.slug}`} className="more_info">Daha ətraflı
                  <img className="arrow_forward" src={ArrowForwardDark} alt="" />
                </NavLink>
              </div>
            )
          })}


          <div className="vacancies_pagination">
            <div className="pagination_data">
              <img className="pagination_arrow_back" src={ArrowBack} alt="" onClick={() => prevPage()} />
              {paginationCount?.map((item) => {
                return (
                  <p onClick={() => getPage(item)} key={`paginate-${item}`} className={`pagination_count ${activePaginationPage === item ? "active-pagination" : ""}`}>{item + 1}</p>
                )
              })}
              <img className="pagination_arrow_forward" src={ArrowForward} alt="" onClick={() => nextPage()} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

Vacancies.propTypes = {};

Vacancies.defaultProps = {};

export default Vacancies;
