import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const VIDEOS = gql`
query GetVideos{
  videos {
      data{
        link
      }
    }
  }
`;

// parceldeliveryterm

const Videos = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [video, setVideo] = useState(null);

  useQuery(VIDEOS, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if (data?.videos) {
        setVideo(data.videos);
      }
    }
  });

  return (
    <div className="kiv_items">
      {video?.map((item, i) => {
        return (
          <div className="kiv_item kiv_photo" key={'video-'+i}>
            <iframe width="100%" height="100%" src={item?.data?.link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            {/* <div className="overlay"></div> */}
            {/* <img className="kiv_hover_action" src="assets/icons/bi_play-fill.svg" alt="" /> */}
          </div>
        )
      })}
    </div>
  )
};

Videos.propTypes = {};

Videos.defaultProps = {};

export default Videos;
