import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import PhoneIcon from '../../../../assets/icons/fluent_call-24-filled.svg';
import EmailIcon from '../../../../assets/icons/ic_baseline-email.svg';

import { NavLink, useLocation } from 'react-router-dom';
import {
    useQuery,
    gql,
    useMutation
} from "@apollo/client";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import { useForm } from "react-hook-form";
import ErrorIcon from "../../../../assets/icons/error.svg"
import SuccessCard from "../../successCard/SuccessCard";

import ReactMarkdown from "react-markdown"

const GETCORPORATEAGREEMENTCONTENT = gql`
query GetCorporateAgreementContent($_locale: String!){
    corporateagreementcontent(locale: $_locale) {
      content{
        title,
        description,
        image{
          formats
        }
      },
      phone1,
      phone2,
      email
    }
  }
`;

const CREATECORPORATEAGREEMENT = gql`
mutation CreateCorporateAgreement ($fullname: String!, $companyname: String!, $email: String!, $phone: String!, $address: String!, $city: String!, $postalcode: String!) {
  createCorporateagreement(input:
        { data: {
            fullname: $fullname,
            companyname: $companyname,
            email: $email,
            phone: $phone,
            address: $address
            city: $city
            postalcode: $postalcode
        }}) {
          corporateagreement {
                id
            }
        }
      }
`;

const ForCorporate = () => {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [agreementContent, setAgreementContent] = useState(null);
    let [showSuccess, setShowSuccess] = useState(false);

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

    useQuery(GETCORPORATEAGREEMENTCONTENT, {
        variables: {
            _locale: lang
        },
        onCompleted(data) {
            console.log(data);
            if (data?.corporateagreementcontent) {
                setAgreementContent(data.corporateagreementcontent);
            }
        }
    });

    const [onMutationSubmit, { loading, error, data }] = useMutation(CREATECORPORATEAGREEMENT, {
        onCompleted: data => {
            reset();
            setShowSuccess(true);
        }
    });

    const onFormSubmit = (data) => {
        onMutationSubmit({
            variables: {
                fullname: data.fullname,
                companyname: data.companyname,
                email: data.email,
                phone: data.phone,
                address: data.address,
                city: data.city,
                postalcode: data.postalcode
            }
        })
    }


    return (
        <>
            <Breadcrumbs />
            <div className="page_data">
                {/* <div className="corporative"> */}
                <div className="corporative bk-img" style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL_PURE + '/' + agreementContent?.content?.image[0]?.formats?.large?.url})` }}>
                    <div className="container corporative_content">
                        <div className="content_desc">
                            <p className="corporative_header">{agreementContent?.content?.title}</p>
                            <ReactMarkdown >
                                {agreementContent?.content?.description}
                            </ReactMarkdown>
                            {/* <p className="corporative_desc">Bizimlə əməkdaşlıq üçün aşağıdakı əlaqə vasitələrinə müraciət edə bilərsiniz:</p> */}
                            <div className="corporative_contact">
                                <div className="phone d-flex align-items--center">
                                    <img className="phone_icon" src={PhoneIcon} alt="" />
                                    <span>{agreementContent?.phone1}</span>
                                </div>
                                <div className="phone d-flex align-items--center">
                                    <img className="phone_icon" src={PhoneIcon} alt="" />
                                    <span>{agreementContent?.phone2}</span>
                                </div>
                            </div>
                            <div className="phone d-flex align-items--center">
                                <img className="phone_icon" src={EmailIcon} alt="" />
                                <span>{agreementContent?.email}</span>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
                {!showSuccess ?
                    <div className="contact_form container">
                        <p className="contact_form_head corp_head">Müqavilə bağlamaq üçün aşağıdakı formanı doldurun.</p>
                        <form className="form_first_step_corp " onSubmit={handleSubmit(onFormSubmit)}>
                            <div className="form_item">
                                <input className={`form_input single_input ${errors.fullname ? "error" : ""}`} type="text" placeholder="Ad, soyad *"
                                    {...register("fullname", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        }
                                    })} />
                                {errors.fullname ?
                                    <p className="d-flex align-items--center">
                                        <img className="error_icon" src={ErrorIcon} alt="" />
                                        Form field error text.
                                    </p> : ''
                                }
                            </div>
                            <div className="form_item">
                                <div className="two_inputs">
                                    <div>
                                        <div className="number_input">
                                            <select className="country_code">
                                                <option value="">994</option>
                                                <option value="">000</option>
                                                <option value="">000</option>
                                            </select>
                                            <input className={`form_input number ${errors.phone ? "error" : ""}`} type="text" placeholder="__ ___ __ __ *"
                                                {...register("phone", {
                                                    required: {
                                                        value: true,
                                                        message: 'Required'
                                                    }
                                                })} />
                                        </div>
                                        {errors.phone ?
                                            <p className="d-flex align-items--center">
                                                <img className="error_icon" src={ErrorIcon} alt="" />
                                                Form field error text.
                                            </p> : ''
                                        }
                                    </div>
                                    <div>
                                        <input className={`form_input ${errors.email ? "error" : ""}`} type="text" placeholder="E-poçt"
                                            {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                },
                                                pattern: {
                                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                                    message: 'Invalid Email'
                                                }
                                            })} />
                                        {errors.email ?
                                            <p className="d-flex align-items--center">
                                                <img className="error_icon" src={ErrorIcon} alt="" />
                                                Form field error text.
                                            </p> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_item">
                                <div className="two_inputs">
                                    <div>
                                        <input className={`form_input ${errors.companyname ? "error" : ""}`} type="text" placeholder="Şirkət adı *"
                                            {...register("companyname", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                }
                                            })} />
                                        {errors.companyname ?
                                            <p className="d-flex align-items--center">
                                                <img className="error_icon" src={ErrorIcon} alt="" />
                                                Form field error text.
                                            </p> : ''
                                        }
                                    </div>
                                    <div>
                                        <input className={`form_input ${errors.address ? "error" : ""}`} type="text" placeholder="Ünvan *"
                                            {...register("address", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                }
                                            })} />
                                        {errors.address ?
                                            <p className="d-flex align-items--center">
                                                <img className="error_icon" src={ErrorIcon} alt="" />
                                                Form field error text.
                                            </p> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_item">
                                <div className="two_inputs">
                                    <div>
                                        <input className="form_input" className={`form_input ${errors.city ? "error" : ""}`} type="text" placeholder="Şəhər *"
                                            {...register("city", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                }
                                            })} />
                                        {errors.city ?
                                            <p className="d-flex align-items--center">
                                                <img className="error_icon" src={ErrorIcon} alt="" />
                                                Form field error text.
                                            </p> : ''
                                        }
                                    </div>
                                    <div>
                                        <input className={`form_input ${errors.postalcode ? "error" : ""}`} type="text" placeholder="ZIP Kodu *"
                                            {...register("postalcode", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                }
                                            })} />
                                        {errors.postalcode ?
                                            <p className="d-flex align-items--center">
                                                <img className="error_icon" src={ErrorIcon} alt="" />
                                                Form field error text.
                                            </p> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_submit_parent">
                                <button className="form_submit ">
                                    Növbəti
                                </button>
                            </div>
                        </form>
                    </div>
                    : <SuccessCard />
                }
            </div>

        </>
    )
};

ForCorporate.propTypes = {};

ForCorporate.defaultProps = {};

export default ForCorporate;
