import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";

import axios from "axios";
import { useFieldArray, useForm } from "react-hook-form";

import BlockedProducts from "../blockedProducts/BlockedProducts";

import ErrorIcon from "../../../../assets/icons/error.svg";
import MapAndFlags1Icon from "../../../../assets/icons/maps-and-flags1.svg";
import AkarEditIcon from "../../../../assets/icons/akar-icons_edit.svg";
import Document1Icon from "../../../../assets/icons/document1.svg";
import Box1Icon from "../../../../assets/icons/box1.svg";
import Info1Icon from "../../../../assets/icons/info1.svg";
import CardsIcon from "../../../../assets/images/cards.svg";

const GETPROMOCODE = gql`
  query GetPromoCode($promoCode: String!) {
    promocodes(where: { promoCode: $promoCode }) {
      importaccountnumber
      exportaccountnumber
    }
  }
`;

const AviaReceipt = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split("/")[1]);
  let [activeFormStep, setActiveFormStep] = useState(0);
  let [accountNumber, setAccountNumber] = useState("963310321");

  let [formdata1, setFormdata1] = useState(null);
  let [formdata2, setFormdata2] = useState(null);
  let [formdata3, setFormdata3] = useState(null);
  let [formdata4, setFormdata4] = useState(null);
  let [parcelType, setParcelType] = useState(0);
  let [invoiceType, setInvoiceType] = useState(0);
  let [paymentType, setPaymentType] = useState(0);
  let [parcelCount, setParcelCount] = useState(0);

  let [blockedProductModal, setBlockedProductModal] = useState(false);
  let [packingType, setPackingType] = useState([]);

  let [countryList, setCountryList] = useState([]);
  let [invoiceData, setInvoiceData] = useState({
    count: 0,
    price: 0,
    curreny: "USD",
  });

  let [netWeight, setNetWeight] = useState(0);
  let [totalWeight, setTotalWeight] = useState(0);
  let [parcelWeight, setParcelWeight] = useState(0);

  let [calculationData, setCalculationData] = useState(null);
  let [calculationTotal, setCalculationTotal] = useState(0);

  let [paymentData, setPaymentData] = useState(null);

  let [receiverZipCodeType, setReceiverZipCodeType] = useState("number");
  let [senderZipCodeType, setSenderZipCodeType] = useState("number");

  let [senderCountryValue, setSenderCountryValue] = useState(null);
  let [receiverCountryValue, setReceiverCountryValue] = useState(null);

  let [hasCourierOption, setHasCourierOption] = useState(false);


  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    resetField,
    watch: watch2,
    getValues: getValues2,
    setValue: setValue2,
    reset: reset2,
    formState: { errors: errors2 },
    control,
  } = useForm();
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    watch: watch3,
    getValues: getValues3,
    setValue: setValue3,
    reset: reset3,
    formState: { errors: errors3 },
    control: control2,
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "parcel", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const {
    fields: fields3,
    append: append3,
    prepend,
    remove: remove3,
    swap,
    move,
    insert,
  } = useFieldArray({
    control: control2, // control props comes from useForm (optional: if you are using FormContext)
    name: "packing", // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    watch: watch4,
    getValues: getValues4,
    setValue: setValue4,
    reset: reset4,
    formState: { errors: errors4 },
    control: control4,
  } = useForm();

  const [getPromo] = useLazyQuery(GETPROMOCODE, {
    onCompleted(data) {
      if (data.promoCodes?.length > 0) {
        if (formdata1.sender.country === "AZ") {
          setAccountNumber(data.promoCodes[0].exportaccountnumber);
        } else if (formdata1.receiver.country === "AZ") {
          setAccountNumber(data.promoCodes[0].importaccountnumber);
        }
      }
    },
  });

  let [calculationDatas, setCalculationDatas] = useState(null);

  useEffect(() => {
    if(calculationDatas){
      console.log(calculationDatas);

      setValue('receiver.country', calculationDatas.Recipient.CountryCode)
      setValue('receiver.postalCode', calculationDatas.Recipient.PostalCode)
      setValue('receiver.city', calculationDatas.Recipient.City)
      setReceiverCountryValue(calculationDatas.Recipient.CountryCode)

      setValue('sender.country', calculationDatas.Shipper.CountryCode)
      setValue('sender.postalCode', calculationDatas.Shipper.PostalCode)
      setValue('sender.city', calculationDatas.Shipper.City)
      setSenderCountryValue(calculationDatas.Shipper.CountryCode)

      setParcelType(calculationDatas.PackageDatas[0].Length?1:0)

      
      console.log(calculationDatas.PackageDatas.length);

      for (let i = 0; i < calculationDatas.PackageDatas.length; i++) {
        addNewParcel3();
        
        if(calculationDatas.PackageDatas[0].Length){
          setPackingType([...Array(packingType.length)].map(x => 0))
          setValue3(`packing.${i}.weight`, +calculationDatas.PackageDatas[i].Weight);
          setValue3(`packing.${i}.length`, +calculationDatas.PackageDatas[i].Length);
          setValue3(`packing.${i}.width`, +calculationDatas.PackageDatas[i].Width);
          setValue3(`packing.${i}.height`, +calculationDatas.PackageDatas[i].Height);

        } else {
          setValue3(`packing.${i}.weight`, +calculationDatas.PackageDatas[i].Weight);
        }
      }

      calculateParcelCount();
      calculateParcelWeight();
    }
  },[calculationDatas])

  useEffect(() => {
    setHasCourierOption(window.location.href.includes('courier'))
    setCalculationDatas(JSON.parse(localStorage.getItem('orderData')));
    if (fields.length === 0) {
      addNewParcel();
    }
    axios
      .get("https://restcountries.com/v3.1/all")
      .then(function (response) {
        // handle success
        response.data.sort(function (a, b) {
          return a.name.common.localeCompare(b.name.common); //using String.prototype.localCompare()
        });
        setCountryList(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  useEffect(() => {
    let type = watch("sender.country") === "GB" ? "text" : "number";
    setSenderZipCodeType(type);
  }, [watch("sender.country")]);

  useEffect(() => {
    let type = watch("receiver.country") === "GB" ? "text" : "number";
    setReceiverZipCodeType(type);
  }, [watch("receiver.country")]);

  const addNewParcel = (index = null) => {
    if (index === null) {
      append({
        productDescription: "",
        count: 0,
        price: 0,
        currency: "USD",
        netWeight: 0,
        totalWeight: 0,
        country: null,
      });
    } else {
      let values = getValues2();

      append({
        productDescription: values.parcel[index].productDescription,
        count: values.parcel[index].count,
        price: values.parcel[index].price,
        currency: values.parcel[index].currency,
        netWeight: values.parcel[index].netWeight,
        totalWeight: values.parcel[index].totalWeight,
        country: values.parcel[index].country,
      });

      calculateInvoice();
      calculateNetWeight();
      calculateTotalWeight();
    }
  };

  const addNewParcel3 = (index = null) => {
    if (index === null) {
      append3({
        packingType: null,
        count: 1,
        weight: 0.5,
      });
      packingType.push(1);
      setPackingType([...packingType]);
      console.log(packingType);
    } else {
      let values = getValues3();

      append3({
        packingType: values.packing[index].packingType,
        count: values.packing[index].count,
        weight: values.packing[index].weight,
      });
      packingType.push(packingType[index]);
      setPackingType([...packingType]);

      calculateParcelCount();
      calculateParcelWeight();
    }
  };

  const deleteParcel = (index) => {
    remove(index);
  };

  const deleteParcel3 = (index) => {
    remove3(index);
  };

  const calculateInvoice = () => {
    let formdata = getValues2();
    let parcels = formdata.parcel;

    if (invoiceType === 0) {
      if (getValues2().parcel?.length > 0) {
        let invoicePrice = 0;
        let count = 0;
        parcels.forEach((item) => {
          count += +item.count || 0;
          invoicePrice += +item.count * (+item.price || 0);
        });

        setInvoiceData({
          count,
          price: invoicePrice || 0,
          currency: getValues2().parcel[0].currency,
        });
      }
    } else if (invoiceType === 1) {
      setInvoiceData({
        ...invoiceData,
        price: formdata.invoice,
      });
    }
  };

  const calculateNetWeight = () => {
    let parcels = getValues2().parcel;

    if (getValues2().parcel?.length > 0) {
      let weight = 0;

      parcels.forEach((item) => {
        weight += +item.netWeight || 0;
      });

      setNetWeight(weight);
    }
  };

  const calculateTotalWeight = () => {
    let parcels = getValues2().parcel;

    if (getValues2().parcel?.length > 0) {
      let weight = 0;

      parcels.forEach((item) => {
        weight += +item.totalWeight || 0;
      });

      setTotalWeight(weight);
    }
  };

  const calculateParcelCount = () => {
    let parcels = getValues3().packing;

    if (parcels?.length > 0) {
      let count = 0;

      parcels.forEach((item) => {
        count += +item.count || 0;
      });

      setParcelCount(count);
    }
  };

  const calculateParcelWeight = () => {
    let parcels = getValues3().packing;

    if (parcels?.length > 0) {
      let weight = 0;

      parcels.forEach((item) => {
        weight += +item.weight || 0;
      });

      setParcelWeight(weight);
    }
  };

  const changeInvoiceTyope = (i) => {
    setInvoiceType(i);
    if (i === 0) {
      if (fields.length > 0) {
        addNewParcel();
      }
    } else {
      resetField("parcel");
    }
  };

  const onFirstStepSubmit = (data) => {
    setFormdata1({
      ...data,
    });
    setActiveFormStep(1);

    getPromo({
      variables: {
        promoCode: data?.promoCode || "default",
      },
    });
  };

  const onSecondStepSubmit = (data) => {
    setFormdata2({
      ...data,
    });
    setActiveFormStep(2);
    if (fields3.length === 0) {
      addNewParcel3();
    }
  };

  const onFourthStepSubmit = (data) => {
    console.log(data);
    setFormdata4({
      ...data,
    });
    setActiveFormStep(4);
  };

  const onThirdStepSubmit = (data) => {
    console.log(data);
    setFormdata3({
      ...data,
    });
    setActiveFormStep(3);

    submitShipmentRequest(data);
  };

  const changeStep = (i) => {
    setActiveFormStep(i);
  };

  const handlePackTypeChange = (e, index) => {
    setPackingType([
      ...packingType.slice(0, index),
      e.target.value,
      ...packingType.slice(index + 1, packingType.length),
    ]);
  };

  const generateInvoiceNumber = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hh = today.getHours();
    let MM = today.getMinutes();
    let ss = today.getSeconds();
    let ms = today.getMilliseconds();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    if (hh < 10) hh = "0" + hh;
    if (MM < 10) MM = "0" + MM;
    if (ss < 10) ss = "0" + ss;
    if (ms < 10) ms = "0" + ms;

    const formattedDate =
      dd.toString() +
      mm.toString() +
      +hh.toString() +
      MM.toString() +
      ss.toString() +
      ms.toString();

    return formattedDate;
  };

  const formatDate = (date) => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hh = today.getHours();
    let MM = today.getMinutes() + 1;
    let ss = today.getSeconds();
    let timeZone = "GMT+04:00";
    // today.toTimeString().slice(9).split(' ')[0].replace('+', '')

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    if (hh < 10) hh = "0" + hh;
    if (MM < 10) MM = "0" + MM;
    if (ss < 10) ss = "0" + ss;

    return (
      yyyy +
      "-" +
      mm +
      "-" +
      dd +
      "T" +
      hh +
      ":" +
      MM +
      ":" +
      ss +
      " " +
      timeZone
    );
    // return timeZone;
  };

  const formatDateYMD = (date) => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    // today.toTimeString().slice(9).split(' ')[0].replace('+', '')

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    return yyyy + "-" + mm + "-" + dd;
    // return timeZone;
  };
  const submitShipmentRequest = (data) => {
    let reqData = {
      ShipmentRequest: {
        RequestedShipment: {
          Ship: {
            Shipper: {
              Contact: {
                PersonName: formdata1.sender.fullName,
                CompanyName: formdata1.sender.company,
                PhoneNumber: formdata1.sender.phone,
                EmailAddress: formdata1.sender.email,
              },
              Address: {
                StreetLines: formdata1.sender.address,
                StreetLines2: formdata1.sender.address2,
                StreetLines3: formdata1.sender.address3,
                City: formdata1.sender.city,
                PostalCode: formdata1.sender.postalCode,
                CountryCode: formdata1.sender.country,
              },
            },
            Recipient: {
              Contact: {
                PersonName: formdata1.receiver.fullName,
                CompanyName: formdata1.receiver.company,
                PhoneNumber: formdata1.receiver.phone,
                EmailAddress: formdata1.receiver.email,
              },
              Address: {
                StreetLines: formdata1.receiver.address,
                StreetLines2: formdata1.receiver.address2,
                StreetLines3: formdata1.receiver.address3,
                City: formdata1.receiver.city,
                PostalCode: formdata1.receiver.postalCode,
                CountryCode: formdata1.receiver.country,
              },
            },
          },

          ShipmentInfo: {
            DropOffType: "REGULAR_PICKUP",

            ServiceType: null,
            LocalServiceType: null,

            LabelTemplate: "ECOM_A4_RU_002", //"ECOM26_84_001", //"ECOM26_84_001",
            LabelType: "PDF",

            // "PaperlessTradeEnabled": false,

            LabelOptions: {
              // "RequestDHLCustomsInvoice": "Y",
              // "DHLCustomsInvoiceType": "PROFORMA_INVOICE"
            },
            Billing: {
              ShipperAccountNumber: accountNumber,
              ShippingPaymentType: "S",
            },

            Currency: formdata2.currency || "RUB",
            UnitOfMeasurement: "SI",
          },

          ShipTimestamp: formatDate(new Date()),
          PaymentInfo: "DAP",
          InternationalDetail: {
            Commodities: {
              // question
              Description:
                parcelType === 0
                  ? formdata2.documentDescription
                  : formdata2.sendingReason,
              CustomsValue: invoiceData?.price,
            },
            Content: parcelType === 0 ? "DOCUMENTS" : "NON_DOCUMENTS",

            ExportDeclaration: {
              InvoiceDate: formatDateYMD(new Date()),
              InvoiceNumber: generateInvoiceNumber(),
              ExportLineItems: {
                ExportLineItem: [],
              },
            },
          },
          Packages: {
            RequestedPackages: [],
          },
        },
      },
    };

    if (formdata2.hasProtection) {
      reqData.ShipmentRequest.RequestedShipment.ShipmentInfo.SpecialServices = [
        {
          Service: {
            ServiceType: "IB",
          },
        },
      ];
    }

    if (parcelType === 1 && invoiceType === 0) {
      formdata2.parcel.forEach((item, index) => {
        reqData.ShipmentRequest.RequestedShipment.InternationalDetail.ExportDeclaration.ExportLineItems.ExportLineItem.push(
          {
            ItemNumber: index + 1,
            Quantity: item.count,
            QuantityUnitOfMeasurement: "PCS",
            UnitPrice: item.price,
            NetWeight: item.netWeight,
            GrossWeight: item.totalWeight,
            ItemDescription: item.productDescription,
            ManufacturingCountryCode: item.country,
          }
        );
      });

      delete reqData.ShipmentRequest.RequestedShipment.InternationalDetail
        .Commodities.CustomsValue;
    } else {
      delete reqData.ShipmentRequest.RequestedShipment.InternationalDetail
        .ExportDeclaration;
      reqData.ShipmentRequest.RequestedShipment.ShipmentInfo.LabelOptions.RequestWaybillDocument =
        "Y";
    }

    if (parcelType === 0) {
      reqData.ShipmentRequest.RequestedShipment.ShipmentInfo.LocalServiceType =
        "D";
      reqData.ShipmentRequest.RequestedShipment.ShipmentInfo.ServiceType = "D";
    } else {
      reqData.ShipmentRequest.RequestedShipment.ShipmentInfo.LocalServiceType =
        "P";
      reqData.ShipmentRequest.RequestedShipment.ShipmentInfo.ServiceType = "P";
    }

    data?.packing.forEach((item, index) => {
      let dimensions;
      if (+packingType[index] === 0) {
        dimensions = {
          Length: +item.length,
          Width: +item.width,
          Height: +item.height,
        };
      } else {
        let parsedDimension;
        if (parcelType === 0) {
          parsedDimension =
            documentPackageList[packingType[index] - 1]?.dimensions.split(
              " x "
            );
        } else {
          parsedDimension =
            parcelPackageList[packingType[index] - 1]?.dimensions.split(" x ");
        }

        dimensions = {
          Length: +parsedDimension[0],
          Width: +parsedDimension[1],
          Height: +parsedDimension[2],
        };
      }
      reqData.ShipmentRequest.RequestedShipment.Packages.RequestedPackages.push(
        {
          "@number": item.count,
          Weight: item.weight,
          Dimensions: dimensions,
          // "CustomerReferences": "" // question
        }
      );
    });
    console.log(reqData);

    axios
      .post(`${process.env.REACT_APP_BASE_URL_PURE}/utils/shipment`, reqData)
      .then(function (response) {
        console.log(response);
        calculate(data);
      });
  };

  const calculate = (data) => {
    let calcData = [];

    let reqData = {
      RateRequest: {
        ClientDetails: null,
        RequestedShipment: {
          DropOffType: "REGULAR_PICKUP",
          NextBusinessDay: "N",
          Ship: {
            Shipper: {
              StreetLines: formdata1.sender.address,
              City: formdata1.sender.city, //baku
              PostalCode: formdata1.sender.postalCode,
              CountryCode: formdata1.sender.country,
            },
            Recipient: {
              StreetLines: formdata1.receiver.address,
              City: formdata1.receiver.city,
              PostalCode: formdata1.receiver.postalCode,
              CountryCode: formdata1.receiver.country,
            },
          },
          Packages: {
            RequestedPackages: [],
          },
          ShipTimestamp: new Date(),
          UnitOfMeasurement: "SI",
          Content: parcelType === 0 ? "DOCUMENTS" : "NON_DOCUMENTS",
          PaymentInfo: "DAP",
          Account: accountNumber,
        },
      },
    };
    // reqData.RateRequest.RequestedShipment.Packages.RequestedPackages

    data?.packing.forEach((item, index) => {
      let dimensions;
      if (+packingType[index] === 0) {
        dimensions = {
          Length: +item.length,
          Width: +item.width,
          Height: +item.height,
        };
      } else {
        let parsedDimension;
        if (parcelType === 0) {
          parsedDimension =
            documentPackageList[packingType[index] - 1]?.dimensions.split(
              " x "
            );
        } else {
          parsedDimension =
            parcelPackageList[packingType[index] - 1]?.dimensions.split(" x ");
        }

        dimensions = {
          Length: +parsedDimension[0],
          Width: +parsedDimension[1],
          Height: +parsedDimension[2],
        };
      }
      reqData.RateRequest.RequestedShipment.Packages.RequestedPackages.push({
        "@number": item.count,
        Weight: {
          Value: +item.weight,
        },
        Dimensions: dimensions,
        // "CustomerReferences": "" // question
      });
    });

    axios
      .post(`${process.env.REACT_APP_BASE_URL_PURE}/utils/rate`, reqData)
      .then(function (response) {
        // setCalculationData();
        // localStorage.setItem('orderData', JSON.stringify(userData));
        // // let sum = 0;

        let data = response.data?.RateResponse?.Provider[0]?.Service;

        setCalculationData([data]);

        // calcData.forEach(item => {
        //     sum += item?.TotalNet?.Amount;
        // })
        setTimeout(() => {
          setCalculationTotal(data?.TotalNet?.Amount);
        }, 400);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Pay = () => {
    const reqData = {
      amount: 100,
      back_ref: "https://dhl.knexel.com/",
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL_PURE}/utils/psign`, reqData)
      .then(function (response) {
        // https://testmpi.3dsecure.az/cgi-bin/cgi_link

        setPaymentData(response.data);

        document.getElementById("patmentBtn").click();

        // const params = new URLSearchParams(response.data);
        // axios
        //   .post(`https://testmpi.3dsecure.az/cgi-bin/cgi_link`, params, {
        //     headers: {
        //       "Content-Type": "application/x-www-form-urlencoded",
        //     },
        //   })
        //   .then((response) => {
        //     console.log(response);
        //     setPaymentData(response.data)
        //     // let myform = document.getElementById("paymentForm");
        //     // let form = new FormData(myform);

        //     // form.append("url", window.location.href);
        //     // return response;
        //   })
        //   .catch((er) => {
        //     console.log(er);
        //   });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveOrder = () => {
    const itemCountQuery = `https://dhl-cpanel.knexel.com/orders`;

    const data = {
      senderFullname: formdata1.sender.fullName,
      senderCompanyName: formdata1.sender.company,
      senderCountry: formdata1.sender.country,
      senderAddress1: formdata1.sender.address,
      senderAddress2: formdata1.sender.address2,
      senderAddress3: formdata1.sender.address3,
      parcelSrcPostalCode: formdata1.sender.postalCode,
      senderCity: formdata1.sender.city,
      senderDisctrict: formdata1.sender.area,
      senderEmail: formdata1.sender.email,
      senderPhone: formdata1.sender.phone,

      receiverFullname: formdata1.receiver.fullName,
      receiverCompanyName: formdata1.receiver.company,
      receiverCountry: formdata1.receiver.country,
      receiverAddress1: formdata1.receiver.address,
      receiverAddress2: formdata1.receiver.address2,
      receiverAddress3: formdata1.receiver.address3,
      receiverPostalCode: formdata1.receiver.postalCode,
      receiverCity: formdata1.receiver.city,
      receiverDistrict: formdata1.receiver.area,
      receiverEmail: formdata1.receiver.email,
      receiverPhone: formdata1.receiver.phone,

      parcelType: parcelType === 0 ? "document" : "parcel", // parcel or document
      senderAccountNumber: accountNumber,
      hasProtection: formdata2.hasProtection,
      documentDescription: formdata2.documentDescription,
      documentReference: formdata2.documentReference,
      invoiceType: invoiceType === 0 ? "new" : "existing", // new or existing
      sendingReason: formdata2.sendingReason,
      invoice: formdata2.invoice,
      invoiceTotalPrice: invoiceData?.price.toString(),
      currency: formdata2.currency,
      invoiceProductCount: invoiceData.count.toString(),
      invoiceNetWeight: netWeight.toString(),
      invoiceTotalWeight: totalWeight.toString(),
      invoiceAdditionalInformation: formdata2.additionalInformation,

      paymentType: paymentType === 0 ? "cash" : "card", // cash or card
      totalPayment:
        (formdata2?.hasProtection && parcelType === 0
          ? +calculationTotal + 4
          : calculationTotal).toString(),
      paymentStatus: "pending",

      totalPackageCount: parcelCount,
      totalPackageWeight: +parcelWeight,

      products: [],
      packages: [],

      courierAddress: formdata4?.address,
      courierTakePlace: formdata4?.courierDesc,
      courierTakePerson: formdata4?.fullName
    };

    formdata2.parcel.forEach((element) => {
      data.products.push({
        productdescription: element.productDescription,
        createdin: element.country,
        quantity: element.count,
        unitprice: element.price,
        netweight: element.netWeight,
        grossweight: element.totalWeight,
      });
    });

    formdata3?.packing.forEach((element, index) => {
      if (documentPackageList[packingType[index] - 1]) {
        let dimensions =
          documentPackageList[packingType[index] - 1]?.dimensions.split(" x ");
        data.packages.push({
          packingtype: documentPackageList[packingType[index] - 1]?.name,
          quantity: element.count,
          weight: element.weight,
          packlength: dimensions[0],
          packwidth: dimensions[1],
          packheight: dimensions[2],
        });
      } else {
        data.packages.push({
          packingtype: "My own package",
          quantity: element.count,
          weight: element.weight,
          packlength: element.length,
          packwidth: element.width,
          packheight: element.height,
        });
      }
    });

    axios.post(itemCountQuery, data).then(function (response) {
      console.log(response);
    });
    // onMutationSubmit({
    //   variables: {
    //     products: [
    //       {
    //         productdescription: 'Productdescription'
    //       }
    //     ]
    //   },
    // });
  };

  return (
    <div className="receipt container">
      <form
        className="hide"
        id="paymentForm"
        action="https://testmpi.3dsecure.az/cgi-bin/cgi_link"
      >
        <input value={paymentData?.AMOUNT} name="AMOUNT" type="hidden" />
        <input value={paymentData?.CURRENCY} name="CURRENCY" type="hidden" />
        <input value={paymentData?.ORDER} name="ORDER" type="hidden" />
        <input value={paymentData?.DESC} name="DESC" type="hidden" />
        <input
          value={paymentData?.MERCH_NAME}
          name="MERCH_NAME"
          type="hidden"
        />
        <input value={paymentData?.MERCH_URL} name="MERCH_URL" type="hidden" />
        <input value={paymentData?.TERMINAL} name="TERMINAL" type="hidden" />
        <input value={paymentData?.EMAIL} name="EMAIL" type="hidden" />
        <input value={paymentData?.TRTYPE} name="TRTYPE" type="hidden" />
        <input value={paymentData?.COUNTRY} name="COUNTRY" type="hidden" />
        <input value={paymentData?.MERCH_GMT} name="MERCH_GMT" type="hidden" />
        <input value={paymentData?.TIMESTAMP} name="TIMESTAMP" type="hidden" />
        <input value={paymentData?.NONCE} name="NONCE" type="hidden" />
        <input value={paymentData?.BACKREF} name="BACKREF" type="hidden" />
        <input value={paymentData?.LANG} name="LANG" type="hidden" />
        <input value={paymentData?.P_SIGN} name="P_SIGN" type="hidden" />

        <button id="patmentBtn" type="submit">
          submit
        </button>
      </form>

      <h1 onClick={() => Pay()}>Pay</h1>
      {blockedProductModal ? (
        <div className="modal-wrapper">
          <div
            className="layout"
            onClick={() => setBlockedProductModal(false)}
          ></div>
          <div className="content">
            <BlockedProducts />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="receipt_stepper stepper">
        <div className="stepper stepper--flexbox">
          <input
            className="stepper__input"
            id="stepper-flex-0"
            name="stepper-flex"
            type="radio"
            value="0"
            onChange={() => changeStep(0)}
            disabled={!(activeFormStep > 0)}
            checked={activeFormStep === 0}
          />
          <div className="stepper__step">
            <label className="stepper__button" htmlFor="stepper-flex-0">
              Məlumatlar
            </label>
          </div>
          <input
            className="stepper__input"
            id="stepper-flex-1"
            name="stepper-flex"
            type="radio"
            value="1"
            onChange={() => changeStep(1)}
            disabled={!(activeFormStep > 1)}
            checked={activeFormStep === 1}
          />
          <div className="stepper__step">
            <label className="stepper__button" htmlFor="stepper-flex-1">
              Göndəriş detallar
            </label>
          </div>
          <input
            className="stepper__input"
            id="stepper-flex-2"
            name="stepper-flex"
            type="radio"
            value="2"
            onChange={() => changeStep(2)}
            disabled={!(activeFormStep > 2)}
            checked={activeFormStep === 2}
          />
          <div className="stepper__step">
            <label className="stepper__button" htmlFor="stepper-flex-2">
              Qablaşdırma
            </label>
          </div>

          { hasCourierOption ? (
              <>
              <input
                className="stepper__input"
                id="stepper-flex-3"
                name="stepper-flex"
                type="radio"
                value="3"
                onChange={() => changeStep(3)}
                disabled={!(activeFormStep > 3)}
                checked={activeFormStep === 3}
              />
              <div className="stepper__step">
                <label className="stepper__button" htmlFor="stepper-flex-3">
                Kuryer çağır
                </label>
              </div>
              </>
            ):("")
          }

          <input
            className="stepper__input"
            id="stepper-flex-3"
            name="stepper-flex"
            type="radio"
            value="3"
            onChange={() => changeStep(3)}
            disabled={!(activeFormStep > 3)}
            checked={activeFormStep === (hasCourierOption?3:4)}
          />
          <div className="stepper__step">
            <label className="stepper__button" htmlFor={`stepper-flex-${hasCourierOption?3:4}`}>
              Ödəniş
            </label>
          </div>

          
        </div>
      </div>
      <form
        className={`receipt_first_step ${
          activeFormStep !== 0 ? "display--none" : ""
        }`}
        onSubmit={handleSubmit(onFirstStepSubmit)}
      >
        <div className="sender_form">
          <p className="form_header">Göndərən</p>
          <div>
            <div className="">
              <div className="validating single_input">
                <input
                  className={`form_input ${
                    errors.sender?.fullName ? "error" : ""
                  }`}
                  type="text"
                  placeholder="Ad, soyad *"
                  {...register("sender.fullName", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                {errors.sender?.fullName ? (
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form_item">
              <input
                className={`form_input single_input ${
                  errors.sender?.company ? "error" : ""
                }`}
                type="text"
                placeholder="Şirkət *"
                {...register("sender.company", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
              />
              {errors.sender?.company ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <select
                className={`form_input single_input ${
                  errors.sender?.country ? "error" : ""
                }`}
                placeholder="Ölkə *"
                {...register("sender.country", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
                value={senderCountryValue}
                onChange={(e)=>setSenderCountryValue(e.target.value)}
              >
                {countryList?.map((item, i) => {
                  return (
                    <option key={"countryCode-" + i} value={item.cca2}>
                      {item.name.common}
                    </option>
                  );
                })}
              </select>
              {errors.sender?.country ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <input
                className={`form_input single_input ${
                  errors.sender?.address ? "error" : ""
                }`}
                type="text"
                placeholder="Ünvan *"
                {...register("sender.address", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
              />
              {errors.sender?.address ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <input
                className={`form_input single_input ${
                  errors.sender?.address2 ? "error" : ""
                }`}
                type="text"
                placeholder="Ünvan 2"
                {...register("sender.address2", {
                  required: {
                    value: false,
                    message: "Required",
                  },
                })}
              />
              {errors.sender?.address2 ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <input
                className={`form_input single_input ${
                  errors.sender?.address3 ? "error" : ""
                }`}
                type="text"
                placeholder="Ünvan 3"
                {...register("sender.address3", {
                  required: {
                    value: false,
                    message: "Required",
                  },
                })}
              />
              {errors.sender?.address3 ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <div className="calc_inputs adjust_margin">
                <div>
                  <input
                    className={`form_input sm_input ${
                      errors.sender?.postalCode ? "error" : ""
                    }`}
                    type={senderZipCodeType}
                    placeholder="ZIP Kod *"
                    {...register("sender.postalCode", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  />
                  {errors.sender?.postalCode ? (
                    <p className="d-flex align-items--center">
                      <img className="error_icon" src={ErrorIcon} alt="" />
                      Form field error text.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <input
                    className={`form_input sm_input ${
                      errors.sender?.city ? "error" : ""
                    }`}
                    type="text"
                    placeholder="Şəhər *"
                    {...register("sender.city", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  />
                  {errors.sender?.city ? (
                    <p className="d-flex align-items--center">
                      <img className="error_icon" src={ErrorIcon} alt="" />
                      Form field error text.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <input
                    className={`form_input sm_input ${
                      errors.sender?.area ? "error" : ""
                    }`}
                    type="text"
                    placeholder="Rayon"
                    {...register("sender.area", {
                      required: {
                        value: false,
                        message: "Required",
                      },
                    })}
                  />
                  {errors.sender?.area ? (
                    <p className="d-flex align-items--center">
                      <img className="error_icon" src={ErrorIcon} alt="" />
                      Form field error text.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="form_item">
              <input
                className={`form_input single_input ${
                  errors.sender?.email ? "error" : ""
                }`}
                type="text"
                placeholder="E-poçt *"
                {...register("sender.email", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: "Invalid Email",
                  },
                })}
              />
              {errors.sender?.email ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <div className="number_input">
                <select className="country_code">
                  <option value="">994</option>
                  <option value="">000</option>
                  <option value="">000</option>
                </select>
                <input
                  className={`form_input number ${
                    errors.sender?.phone ? "error" : ""
                  }`}
                  type="text"
                  placeholder="__ ___ __ __ *"
                  {...register("sender.phone", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
              </div>
              {errors.sender?.phone ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
              {/* <div className="phone_form">
                                <select className="phone_type">
                                    <option value="">994</option>
                                    <option value="">000</option>
                                    <option value="">000</option>
                                </select>
                                <div className="number_input">
                                    <select className="country_code">
                                        <option value="">994</option>
                                        <option value="">000</option>
                                        <option value="">000</option>
                                    </select>
                                    <input className="form_input number" type="text" placeholder="__ ___ __ __ *" />
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
        <div className="receiver_form">
          <p className="form_header">Alan</p>
          <div>
            <div className="">
              <div className="validating single_input">
                <input
                  className={`form_input ${
                    errors.receiver?.fullName ? "error" : ""
                  }`}
                  type="text"
                  placeholder="Ad, soyad *"
                  {...register("receiver.fullName", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                {errors.receiver?.fullName ? (
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form_item">
              <input
                className={`form_input single_input ${
                  errors.receiver?.company ? "error" : ""
                }`}
                type="text"
                placeholder="Şirkət *"
                {...register("receiver.company", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
              />
              {errors.receiver?.company ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <select
                className={`form_input single_input ${
                  errors.receiver?.country ? "error" : ""
                }`}
                placeholder="Ölkə *"
                {...register("receiver.country", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
                value={receiverCountryValue}
                onChange={(e)=>setReceiverCountryValue(e.target.value)}
              >
                {countryList?.map((item, i) => {
                  return (
                    <option key={"countryCode-" + i} value={item.cca2}>
                      {item.name.common}
                    </option>
                  );
                })}
              </select>
              {errors.receiver?.country ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <input
                className={`form_input single_input ${
                  errors.receiver?.address ? "error" : ""
                }`}
                type="text"
                placeholder="Ünvan *"
                {...register("receiver.address", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
              />
              {errors.receiver?.address ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <input
                className={`form_input single_input ${
                  errors.receiver?.address2 ? "error" : ""
                }`}
                type="text"
                placeholder="Ünvan 2"
                {...register("receiver.address2", {
                  required: {
                    value: false,
                    message: "Required",
                  },
                })}
              />
              {errors.receiver?.address2 ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <input
                className={`form_input single_input ${
                  errors.receiver?.address3 ? "error" : ""
                }`}
                type="text"
                placeholder="Ünvan 3"
                {...register("receiver.address3", {
                  required: {
                    value: false,
                    message: "Required",
                  },
                })}
              />
              {errors.receiver?.address3 ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <div className="calc_inputs adjust_margin">
                <div>
                  <input
                    className={`form_input sm_input ${
                      errors.receiver?.postalCode ? "error" : ""
                    }`}
                    type={receiverZipCodeType}
                    placeholder="ZIP Kod *"
                    {...register("receiver.postalCode", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  />
                  {errors.receiver?.postalCode ? (
                    <p className="d-flex align-items--center">
                      <img className="error_icon" src={ErrorIcon} alt="" />
                      Form field error text.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <input
                    className={`form_input sm_input ${
                      errors.receiver?.city ? "error" : ""
                    }`}
                    type="text"
                    placeholder="Şəhər *"
                    {...register("receiver.city", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  />
                  {errors.receiver?.city ? (
                    <p className="d-flex align-items--center">
                      <img className="error_icon" src={ErrorIcon} alt="" />
                      Form field error text.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <input
                    className={`form_input sm_input ${
                      errors.receiver?.area ? "error" : ""
                    }`}
                    type="text"
                    placeholder="Rayon"
                    {...register("receiver.area", {
                      required: {
                        value: false,
                        message: "Required",
                      },
                    })}
                  />
                  {errors.receiver?.area ? (
                    <p className="d-flex align-items--center">
                      <img className="error_icon" src={ErrorIcon} alt="" />
                      Form field error text.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="form_item">
              <input
                className={`form_input single_input ${
                  errors.receiver?.email ? "error" : ""
                }`}
                type="text"
                placeholder="E-poçt *"
                {...register("receiver.email", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: "Invalid Email",
                  },
                })}
              />
              {errors.receiver?.email ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="form_item">
              <div className="number_input">
                <select className="country_code">
                  <option value="">994</option>
                  <option value="">000</option>
                  <option value="">000</option>
                </select>
                <input
                  className={`form_input number ${
                    errors.receiver?.phone ? "error" : ""
                  }`}
                  type="text"
                  placeholder="__ ___ __ __ *"
                  {...register("receiver.phone", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
              </div>
              {errors.receiver?.phone ? (
                <p className="d-flex align-items--center">
                  <img className="error_icon" src={ErrorIcon} alt="" />
                  Form field error text.
                </p>
              ) : (
                ""
              )}
              {/* <div className="phone_form">
                                <select className="phone_type">
                                    <option value="">994</option>
                                    <option value="">000</option>
                                    <option value="">000</option>
                                </select>
                                <div className="number_input">
                                    <select className="country_code">
                                        <option value="">994</option>
                                        <option value="">000</option>
                                        <option value="">000</option>
                                    </select>
                                    <input className="form_input number" type="text" placeholder="__ ___ __ __ *" />
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
        <div className="form_submit_parent">
          <button className="form_submit " type="submit">
            Növbəti
          </button>
        </div>
      </form>

      {activeFormStep > 0 ? (
        <div className="receipt_second_step">
          <div className="customer_data">
            <div className="sender_data">
              <p className="sender_data_name">Göndərən</p>
              <div className="customer_content">
                <img
                  className="customer_content_icon"
                  src={MapAndFlags1Icon}
                  alt=""
                />
                <div>
                  <p>{formdata1.sender.fullNam}</p>
                  <p>{formdata1.sender.company}</p>
                  <p>{formdata1.sender.country}</p>
                  <p>{formdata1.sender.address}</p>
                  <p>{formdata1.sender.address2}</p>
                  <p>{formdata1.sender.address3}</p>
                </div>
              </div>
            </div>
            <div className="receiver_data">
              <p className="sender_data_name">Alan</p>
              <div className="customer_content">
                <img
                  className="customer_content_icon"
                  src={MapAndFlags1Icon}
                  alt=""
                />
                <div>
                  <p>{formdata1.receiver.fullNam}</p>
                  <p>{formdata1.receiver.company}</p>
                  <p>{formdata1.receiver.country}</p>
                  <p>{formdata1.receiver.address}</p>
                  <p>{formdata1.receiver.address2}</p>
                  <p>{formdata1.receiver.address3}</p>
                </div>
              </div>
            </div>
            <div className="receiver_data">
              <button className="edit_button" onClick={() => changeStep(0)}>
                <img className="edit_icon" src={AkarEditIcon} alt="" />
                Redaktə et
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <form
        className={`receipt_second_step ${
          activeFormStep !== 1 ? "display--none" : ""
        }`}
        onSubmit={handleSubmit2(onSecondStepSubmit)}
      >
        <p className="sender_head">Göndəriş detalları</p>

        <div className="sender_tabs">
          <input
            type="radio"
            name="select"
            id="option-1"
            onChange={() => setParcelType(0)}
            checked={parcelType === 0}
          />
          <input
            type="radio"
            name="select"
            id="option-2"
            onChange={() => setParcelType(1)}
            checked={parcelType === 1}
          />
          <label htmlFor="option-1" className="option option-1">
            <div className="d-flex">
              <img className="doc_icon" src={Document1Icon} alt="" />
              <span>Sənəd</span>
            </div>
            <div className="dot"></div>
          </label>
          <label htmlFor="option-2" className="option option-2">
            <div className="d-flex">
              <img className="doc_icon" src={Box1Icon} alt="" />
              <span>Bağlama</span>
            </div>
            <div className="dot"></div>
          </label>
        </div>
        {parcelType === 0 ? (
          <>
            <div className="form_sm">
              <div className="form_item">
                <p className="form_head">Göndərdiyiniz sənədləri təsvir edin</p>
                <input
                  className={`form_input info_input ${
                    errors2.documentDescription ? "error" : ""
                  }`}
                  type="text"
                  placeholder="Sənədlərin təsviri"
                  {...register2("documentDescription", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                {errors2.documentDescription ? (
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="form_item">
                <p className="form_head">
                  İstinad (göndərmə etiketində / yol vərəqəsində görünür)
                </p>
                <input
                  className={`form_input info_input ${
                    errors2.documentReference ? "error" : ""
                  }`}
                  type="text"
                  placeholder="İstinad"
                  {...register2("documentReference", {
                    required: {
                      value: false,
                      message: "Required",
                    },
                  })}
                />
                {errors2.documentReference ? (
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="form_warning">
                <p className="form_head">Göndərişlərinizi qoruyun</p>
                <div className="box">
                  <input
                    id="one"
                    type="checkbox"
                    {...register2("hasProtection", {
                      required: {
                        value: false,
                        message: "Required",
                      },
                    })}
                  />
                  <span className="check"></span>
                  <label htmlFor="one">Göndərişlərimi qorumaq istəyirəm.</label>
                </div>
                <p>
                  In the rare event of physical loss of your documents, DHL will
                  compensate for the cost of recovery with a fixed lump sum of
                  100 USD.
                </p>
              </div>
              <p className="warning_info">
                Documents include legal, financial or business paperwork.Items
                with monetary value are NOT considered a document shipment.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="parcel_details form_sm">
              <div className="info_bar">
                <div className="info_icon">
                  <img src={Info1Icon} alt="" />
                </div>
                <p>
                  Göndərmə alıcı tərəfindən ödənilməli olan gömrük rüsumuna və
                  vergilərə tabedir.
                </p>
              </div>
              <p>İnvoys növü</p>
              <div className="radios">
                <label className="radio_box">
                  <input
                    type="radio"
                    name="radio-button"
                    value="css"
                    onChange={() => changeInvoiceTyope(0)}
                    checked={invoiceType === 0}
                  />
                  <span>İnvoys hazırla</span>
                </label>
                <label className="radio_box">
                  <input
                    type="radio"
                    name="radio-button"
                    value="no"
                    onChange={() => changeInvoiceTyope(1)}
                    checked={invoiceType === 1}
                  />
                  <span>Öz nvoysumdan istifadə et</span>
                </label>
              </div>
              <div className="form_item margin_adjust">
                <p className="form_head">Göndərmənin məqsədi nədir?</p>
                <select
                  className={`phone_type ${
                    errors2.sendingReason ? "error" : ""
                  }`}
                  {...register2("sendingReason", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                >
                  {sendingReasonList.map((item, i) => {
                    return (
                      <option key={"sendingReason-" + i} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errors2.sendingReason ? (
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>

            {invoiceType === 0 ? (
              <>
                {fields.map((field, index) => (
                  <div className="parcel_info" key={"parcelInfo-" + index}>
                    <div className="parcel_count">
                      <p>{index + 1}</p>
                    </div>
                    <div className="parcel_full_info">
                      <div className="parcel_head">
                        <p className="parcel_head_value">Məhsulun təsviri</p>
                        <p onClick={() => setBlockedProductModal(true)}>
                          Qadağan olunmuş məhsullara baxın
                        </p>
                      </div>
                      <input
                        className="form_input info_input parcel_input"
                        type="text"
                        placeholder="Məhsulun tam təsviri"
                        {...register2(`parcel.${index}.productDescription`)}
                      />
                      <div className="parcel_inputs">
                        <div className="form_item parcel_item">
                          <p className="form_head">Miqdar</p>
                          <input
                            className="form_input input_sm"
                            type="text"
                            placeholder=""
                            {...register2(`parcel.${index}.count`)}
                            onKeyUp={() => calculateInvoice()}
                          />
                        </div>
                        <div className="form_item parcel_item">
                          <p className="form_head">Vahid dəyəri</p>
                          <input
                            className="form_input input_sm"
                            type="text"
                            placeholder=""
                            {...register2(`parcel.${index}.price`)}
                            onKeyUp={() => calculateInvoice()}
                          />
                        </div>
                        {/* <select className="currency parcel_item" {...register2(`parcel.${index}.currency`)}>
                                                    <option value="usd">USD</option>
                                                    <option value="azn">AZN</option>
                                                    <option value="000">000</option>
                                                </select> */}
                        <div className="form_item parcel_item">
                          <p className="form_head">Xalis çəki</p>
                          <input
                            className="form_input info_input parcel_size"
                            type="text"
                            placeholder=""
                            {...register2(`parcel.${index}.netWeight`)}
                            onKeyUp={() => calculateNetWeight()}
                          />
                        </div>
                        <div className="form_item parcel_item">
                          <p className="form_head">Ümumi Çəki</p>
                          <input
                            className="form_input info_input parcel_size"
                            type="text"
                            placeholder=""
                            {...register2(`parcel.${index}.totalWeight`)}
                            onKeyUp={() => calculateTotalWeight()}
                          />
                        </div>
                        <div className="for_item">
                          <p className="form_head">Maddə harada hazırlanıb?</p>
                          <select
                            className="phone_type "
                            {...register2(`parcel.${index}.country`)}
                          >
                            {countryList?.map((item, i) => {
                              return (
                                <option
                                  key={"countryCode-" + i}
                                  value={item.cca2}
                                >
                                  {item.name.common}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      className="parcel_copy"
                      onClick={() => addNewParcel(index)}
                    >
                      <img
                        className="copy_icon"
                        src="assets/icons/akar-icons_copy.svg"
                        alt=""
                      />
                      <span>Kopyala</span>
                    </div>
                    {fields.length > 1 ? (
                      <div
                        className="parcel_copy"
                        onClick={() => deleteParcel(index)}
                      >
                        <img
                          className="copy_icon"
                          src="assets/icons/akar-icons_copy.svg"
                          alt=""
                        />
                        <span>sil</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
                <div className="parcel_add">
                  <div className="parcel_detal">
                    <p>Ümumi məhsul: {invoiceData.count}</p>
                    <p>Xalis çəki: {netWeight}</p>
                    <p>Ümumi çəki: {totalWeight}</p>
                  </div>
                  <button
                    type="button"
                    className="add_parcel"
                    onClick={() => addNewParcel()}
                  >
                    <img
                      className="add_icon"
                      src="assets/icons/plus-square1.svg"
                      alt=""
                    />
                    <p>Məhsul əlavə et</p>
                  </button>
                </div>
              </>
            ) : (
              <div className="form_item">
                <p className="form_head">Invoys</p>
                <input
                  className="form_input input_sm"
                  type="text"
                  placeholder=""
                  {...register2(`invoice`)}
                  onKeyUp={() => calculateInvoice()}
                />
              </div>
            )}

            {/* <div className="parcel_invoice">
                        </div> */}
            <div className="invoice_total">
              <div className="total_price_detail">
                <p>Ümumi invoys dəyəri: </p>
                <p>{invoiceData?.price}</p>
                <select className="parcel_item" {...register2(`currency`)}>
                  <option value="USD">USD</option>
                  <option value="AZN">AZN</option>
                </select>
              </div>
            </div>

            <div className="parcel_additional_info ">
              <p>Əlavə informasiya</p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                className="form_textarea form_input"
                {...register2("additionalInformation", {
                  required: {
                    value: false,
                    message: "Required",
                  },
                })}
              ></textarea>
            </div>
          </>
        )}
        <div className="form_submit_parent">
          <button className="form_submit " type="submit">
            Növbəti
          </button>
        </div>
      </form>

      {activeFormStep > 1 ? (
        <div className="receipt_second_step">
          <div className="customer_data">
            <div className="sender_data">
              {formdata2.documentDescription ? (
                <>
                  <p className="sender_data_name">Sənəd təsviri:</p>
                  <div className="customer_content">
                    <div>
                      <p>{formdata2.documentDescription}</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {formdata2.parcel?.length > 0 ? (
                    <p className="sender_data_name">
                      Məhsullar:{" "}
                      {formdata2.parcel?.map((item, i) => {
                        return (
                          <span>
                            {item.productDescription +
                              (formdata2.parcel.length - 1 > i ? ", " : "")}
                          </span>
                        );
                      })}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="customer_content">
                    <div>
                      <p>Ümumi dəyər:{invoiceData?.price}</p>
                      {/* <p>Ümumi dəyər:</p> */}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="receiver_data">
              <button className="edit_button" onClick={() => changeStep(1)}>
                <img className="edit_icon" src={AkarEditIcon} alt="" />
                Redaktə et
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <form
        className={`receipt_third_step ${
          activeFormStep !== 2 ? "display--none" : ""
        }`}
        onSubmit={handleSubmit3(onThirdStepSubmit)}
      >
        <p className="sender_head">Qablaşdırma</p>

        {fields3.map((item, index) => {
          return (
            <div
              className="parcel_info parcel_info_third_step"
              key={"packing-data-" + index}
            >
              <div className="parcel_full_info">
                <div className="parcel_inputs parcel_inputs_third">
                  <div className="form_item parcel_item">
                    <p className="form_head">Qablaşdırma</p>
                    <select
                      className="phone_type"
                      onChange={(e) => handlePackTypeChange(e, index)}
                      value={packingType[index]}
                    >
                      {parcelType === 0
                        ? documentPackageList.map((item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          })
                        : parcelPackageList.map((item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                    </select>
                  </div>
                  <div className="form_item parcel_item">
                    <p className="form_head">Miqdar</p>
                    <input
                      className="form_input input_sm"
                      type="text"
                      placeholder=""
                      onKeyUp={() => calculateParcelCount()}
                      {...register3(`packing.${index}.count`)}
                    />
                  </div>
                  <div className="form_item parcel_item">
                    <p className="form_head">Çəki (kg)</p>
                    <input
                      className="form_input input_sm"
                      type="text"
                      placeholder=""
                      onKeyUp={() => calculateParcelWeight()}
                      {...register3(`packing.${index}.weight`)}
                    />
                  </div>

                  {+packingType[index] !== 0 ? (
                    <div className="form_item parcel_item">
                      <p className="form_head">Ölçülər</p>
                      <p>
                        {parcelType === 0
                          ? documentPackageList[packingType[index] - 1]
                              ?.dimensions
                          : parcelPackageList[packingType[index] - 1]
                              ?.dimensions}{" "}
                        CM
                      </p>
                    </div>
                  ) : (
                    <div className="parcel_inputs">
                      <div className="form_item parcel_item">
                        <p className="form_head">Uzunluq</p>
                        <input
                          className="form_input input_sm"
                          type="text"
                          placeholder=""
                          {...register3(`packing.${index}.length`)}
                        />
                      </div>

                      <div className="form_item parcel_item">
                        <p className="form_head">En</p>
                        <input
                          className="form_input input_sm"
                          type="text"
                          placeholder=""
                          {...register3(`packing.${index}.width`)}
                        />
                      </div>
                      <div className="form_item parcel_item">
                        <p className="form_head">Hündürlük</p>
                        <input
                          className="form_input input_sm"
                          type="text"
                          placeholder=""
                          {...register3(`packing.${index}.height`)}
                        />
                      </div>
                      {/* <div className="form_item parcel_item">
                                                <p className="form_head">Ümumi Çəki</p>
                                                <input className="form_input input_sm" type="text" placeholder="" {...register3(`packing.${index}.totalWeight`)} />
                                            </div> */}
                    </div>
                  )}
                </div>
              </div>
              <div className="parcel_copy" onClick={() => addNewParcel3(index)}>
                <img
                  className="copy_icon"
                  src="assets/icons/akar-icons_copy.svg"
                  alt=""
                />
                <span>Kopyala</span>
              </div>
              {fields3?.length > 1 ? (
                <div
                  className="parcel_copy"
                  onClick={() => deleteParcel3(index)}
                >
                  <img
                    className="copy_icon"
                    src="assets/icons/akar-icons_copy.svg"
                    alt=""
                  />
                  <span>Sil</span>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
        <div className="parcel_add">
          <div className="parcel_detal">
            <p>Toplam:</p>
            <p>
              <b>{parcelCount}</b>
            </p>
            <p>
              <b>{parcelWeight} kg</b>
            </p>
          </div>
          <button
            className="add_parcel"
            type="button"
            onClick={() => addNewParcel3()}
          >
            <img
              className="add_icon"
              src="assets/icons/plus-square1.svg"
              alt=""
            />
            <p>Məhsul əlavə et</p>
          </button>
        </div>

        <div className="form_submit_parent">
          <button className="form_submit " type="submit">
            Növbəti
          </button>
        </div>
      </form>

      {activeFormStep > 2 ? (
        <div className="receipt_second_step">
          <div className="customer_data">
            <div className="sender_data">
              <p className="sender_data_name">Qablaşdırma</p>
              <div className="customer_content flex-wrap--wrap ">
                {formdata3.packing.map((item, index) => {
                  return (
                    <div className="w-100">
                      <p>
                        {+packingType[index] !== 0
                          ? parcelType === 0
                            ? documentPackageList[packingType[index] - 1]?.name
                            : parcelPackageList[packingType[index] - 1]?.name
                          : "My own package"}

                        {" - " +
                          item.count +
                          " ədəd - " +
                          item.weight +
                          " kg ("}
                        {+packingType[index] !== 0
                          ? (parcelType === 0
                              ? documentPackageList[packingType[index] - 1]
                                  ?.dimensions
                              : parcelPackageList[packingType[index] - 1]
                                  ?.dimensions) + " CM)"
                          : item.length +
                            " x " +
                            item.width +
                            " x " +
                            item.height +
                            " CM)"}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="receiver_data">
              <button className="edit_button" onClick={() => changeStep(2)}>
                <img className="edit_icon" src={AkarEditIcon} alt="" />
                Redaktə et
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}


      {hasCourierOption?
        <form
          className={`receipt_second_step ${
            activeFormStep !== 3 ? "display--none" : ""
          }`}
          onSubmit={handleSubmit4(onFourthStepSubmit)}
        >
          <div className="receipt_courier_step">
            <p className="sender_head">Kuryer çağır</p>
            
            <div className="form_sm">
              <div className="form_item">
                <p className="form_head">Ünvan</p>
                <input
                  className={`form_input info_input ${
                    errors4.address ? "error" : ""
                  }`}
                  type="text"
                  placeholder="Ünvan daxil edin"
                  {...register4("address", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                {errors4.address ? (
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="form_item">
                <p className="form_head">Haradan təhvil alınacaq?</p>
                <textarea
                  className={`form_input courier_text-area ${
                    errors4.courierDesc ? "error" : ""
                  }`}
                  type="text"
                  placeholder="Məsələn, Paşa bank biznes filialından, akkreditiv şöbəsi"
                  {...register4("courierDesc", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                {errors4.courierDesc ? (
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="form_item">
                <p className="form_head">Kimdən təhvil alınacaq?</p>
                <input
                  className={`form_input ${
                    errors4.fullName ? "error" : ""
                  }`}
                  type="text"
                  placeholder="Ad, Soyad"
                  {...register4("fullName", {
                    required: {
                      value: true,
                      message: "Required",
                    },
                  })}
                />
                {errors4.fullName ? (
                  <p className="d-flex align-items--center">
                    <img className="error_icon" src={ErrorIcon} alt="" />
                    Form field error text.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form_submit_parent">
            <button className="form_submit " type="submit">
              Növbəti
            </button>
          </div>
          </div>
        </form>
        :""
      }
      {hasCourierOption && activeFormStep > 3 ? (
        <div className="receipt_second_step">
          <div className="customer_data">
            <div className="sender_data">
              <p className="sender_data_name">Təhvil götürüləcək ünvan</p>
              <div className="customer_content">
                <div className="w-100">
                  <p>{formdata4.address}</p>
                  <p>{formdata4.fullName}</p>
                </div>
              </div>
            </div>
            <div className="receiver_data">
              <button className="edit_button" onClick={() => changeStep(3)}>
                <img className="edit_icon" src={AkarEditIcon} alt="" />
                Redaktə et
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        className={`receipt_fourth_step ${
          !(activeFormStep === (hasCourierOption?4:3) && calculationData !== null)
            ? "display--none"
            : ""
        }`}
      >
        <p className="form_header">Ödəniş</p>
        <div className="sender_tabs">
          <input
            type="radio"
            name="select"
            id="option-3"
            onChange={() => setPaymentType(0)}
            checked={paymentType === 0}
          />
          <input
            type="radio"
            name="select"
            id="option-4"
            onChange={() => setPaymentType(1)}
            checked={paymentType === 1}
          />
          <label htmlFor="option-3" className="option option-3">
            <div className="d-flex">
              <img className="doc_icon" src={Document1Icon} alt="" />
              <span>Nəğd ödəniş</span>
            </div>
            <div className="dot"></div>
          </label>
          <label htmlFor="option-4" className="option option-4">
            <div className="d-flex">
              <img className="doc_icon" src={Box1Icon} alt="" />
              <span>Kartla ödəniş</span>
            </div>
            <div className="dot"></div>
          </label>
        </div>
        <div className="info_wrapper">
          <div className="info_bar">
            <div className="info_icon">
              <img src={Info1Icon} alt="" />
            </div>
            <p>
              Göndərmə alıcı tərəfindən ödənilməli olan gömrük rüsumuna və
              vergilərə tabedir.
            </p>
          </div>
        </div>
        <div className="pay_with_card">
          <div className="card_head">
            <img src={CardsIcon} alt="" />
          </div>
          <div className="card_content">
            <div className="card_totals">
              {/* <p className="total_head">Ödəniş yekun.</p> */}
              {/* <div className="total_item">
                                <p className="total_item_head">Məhsulun dəyəri: </p>
                                <p className="total_item_currency">USD</p>
                                <p className="total_item_amount">567.00</p>
                            </div>
                            <div className="total_item">
                                <p className="total_item_head">Digər xərclər: </p>
                                <p className="total_item_currency">USD</p>
                                <p className="total_item_amount">567.00</p>
                            </div> */}
              <div className="total_item total_last">
                <p className="total_item_head">Catdirilma: </p>
                <p className="total_item_currency">
                  {calculationData ? calculationData[0]?.TotalNet.Currency : ""}
                </p>
                <p className="total_item_amount">
                  {formdata2?.hasProtection && parcelType === 0
                    ? +calculationTotal + 4
                    : calculationTotal}
                </p>

                {/* (formdata2.hasProtection && parcelType===0? (+calculationData[0]?.TotalNet.Currency+4) : +calculationData[0]?.TotalNet.Currency) */}
              </div>
              <div className="total_item total_last">
                <p className="total_item_head">EDV: </p>
                <p className="total_item_currency">
                  {calculationData ? calculationData[0]?.TotalNet.Currency : ""}
                </p>
                <p className="total_item_amount">
                  {formdata2?.hasProtection && parcelType === 0
                    ? ((+calculationTotal + 4) * 0.18).toFixed(2)
                    : (+calculationTotal * 0.18).toFixed(2)}
                </p>

                {/* (formdata2.hasProtection && parcelType===0? (+calculationData[0]?.TotalNet.Currency+4) : +calculationData[0]?.TotalNet.Currency) */}
              </div>

              <div className="total_item total_last">
                <p className="total_item_head">Toplam ödəniləcək: </p>
                <p className="total_item_currency">
                  {calculationData ? calculationData[0]?.TotalNet.Currency : ""}
                </p>
                <p className="total_item_amount">
                  {formdata2?.hasProtection && parcelType === 0
                    ? +calculationTotal +
                      4 +
                      +((+calculationTotal + 4) * 0.18).toFixed(2)
                    : +calculationTotal +
                      +(+calculationTotal * 0.18).toFixed(2)}
                </p>

                {/* (formdata2.hasProtection && parcelType===0? (+calculationData[0]?.TotalNet.Currency+4) : +calculationData[0]?.TotalNet.Currency) */}
              </div>

              <div className="form_submit_parent">
                <button
                  onClick={() => saveOrder()}
                  className="form_submit "
                  type="button"
                >
                  Tamamla
                </button>
              </div>
            </div>
            {/* <div className="card_details">
                            <div className="form_item">
                                <p className="form_head">Kart üzərindəki ad</p>
                                <input className="form_input info_input" type="text" placeholder="İstinad" />
                            </div>
                            <div className="form_item">
                                <p className="form_head">Kart üzərindəki ad</p>
                                <input className="form_input single_input" type="text" placeholder="0000 0000 0000 0000 *" />
                            </div>
                            <div className="form_item">
                                <div className="two_inputs">
                                    <input className="form_input" type="text" placeholder="Şirkət adı *" />
                                    <input className="form_input" type="text" placeholder="Ünvan *" />
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
        <div className="privacy_part">
          <p className="privacy_head">Şərtlər və Qaydalar</p>
          <p className="privacy_item">
            Ödəniş edərək - DHL <a href="">Daşınma qaydaları və şərtlərini</a>{" "}
            qəbul edirəm.
          </p>
          <p className="privacy_item">
            Və bu daşınmanın hər hansı bir{" "}
            <a href="">qadağan olunmuş maddələr</a> siyahısına daxil olmadığını
            təsdiq edirəm.
          </p>
        </div>
      </div>
      {/* <div className="form_submit_parent">
                <button className="form_submit ">
                    Növbəti
                </button>
            </div> */}
    </div>
  );
};

AviaReceipt.propTypes = {};

AviaReceipt.defaultProps = {};

export default AviaReceipt;

const sendingReasonList = [
  { name: "hədiyyə", value: "gift" },
  { name: "nümunə", value: "sample" },
  { name: "kommersiya məqsədli", value: "commercial" },
  {
    name: "şəxsi, satış üçün nəzərdə tutulmayıb",
    value: "personal, not for sale",
  },
  { name: "təmir üçün geri göndərilmə", value: "return for repair" },
  { name: "təmirdən sonra geri göndərilmə", value: "return after repair" },
  { name: "göndərənə qaytarmaq", value: "return to origin" },
  {
    name: "göndərənə kommersiya məqsədli qaytarmaq",
    value: "return to origin commercial",
  },
];

const documentPackageList = [
  {
    id: 1,
    name: "Card Envelope",
    weight: "0.5",
    dimensions: "35.00 x 27.50 x 2.00",
  },
  {
    id: 2,
    name: "Card Envelope 2",
    weight: "0.45",
    dimensions: "35.05 x 27.48 x 1.02",
  },
  {
    id: 3,
    name: "Express Envelope",
    weight: "0.3",
    dimensions: "31.70 x 23.80 x 1.00",
  },
  { id: 0, name: "My own package", weight: null, dimensions: null },
];

const parcelPackageList = [
  {
    id: 1,
    name: "Box 2 (Cube)",
    weight: "1",
    dimensions: "26.00 x 15.00 x 15.00",
  },
  {
    id: 2,
    name: "Box 2 (Flat)",
    weight: "1.5",
    dimensions: "34.00 x 33.00 x 6.00",
  },
  {
    id: 3,
    name: "Box 2 (Shoe Box)",
    weight: "1.5",
    dimensions: "34.00 x 19.00 x 11.00",
  },
  { id: 4, name: "Box 3", weight: "2", dimensions: "33.70 x 32.20 x 10.00" },
  { id: 5, name: "Box 4", weight: "5", dimensions: "33.70 x 32.20 x 18.00" },
  {
    id: 6,
    name: "Box 5 (Jumbo Small)",
    weight: "10",
    dimensions: "34.00 x 33.00 x 35.00",
  },
  { id: 7, name: "Box 6", weight: "15", dimensions: "41.70 x 35.90 x 36.90" },
  { id: 8, name: "Box 7", weight: "20", dimensions: "48.10 x 40.40 x 38.90" },
  {
    id: 9,
    name: "Box 8 (Jumbo Large)",
    weight: "25",
    dimensions: "55.00 x 45.00 x 41.00",
  },
  {
    id: 10,
    name: "Tube (Large)",
    weight: "5",
    dimensions: "97.60 x 17.60 x 15.20",
  },
  {
    id: 11,
    name: "Tube (Small)",
    weight: "2",
    dimensions: "47.50 x 15.50 x 13.40",
  },
  {
    id: 12,
    name: "Wine Box 1",
    weight: "1.5",
    dimensions: "39.00 x 14.10 x 14.10",
  },
  {
    id: 13,
    name: "Wine Box 2",
    weight: "3",
    dimensions: "38.20 x 27.40 x 14.10",
  },
  {
    id: 14,
    name: "Wine Box 3",
    weight: "5",
    dimensions: "40.60 x 14.10 x 38.20",
  },
  {
    id: 15,
    name: "Wine Box 6",
    weight: "10",
    dimensions: "40.60 x 27.40 x 38.30",
  },
  { id: 0, name: "My own package", weight: null, dimensions: null },
];
