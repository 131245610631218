import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

// import Logo from '../../../../../assets/images/logo.svg'; 
import AddressIcon from '../../../../assets/icons/address.svg';
import CallIcon from '../../../../assets/icons/fluent_call-24-filled.svg';
import EmailIcon from '../../../../assets/icons/ic_baseline-email.svg';
import CheckIcon from '../../../../assets/icons/check_badge.svg';
import { NavLink, useLocation } from 'react-router-dom';
import {
    useQuery,
    gql,
    useMutation
} from "@apollo/client";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import { useForm } from "react-hook-form";
import ErrorIcon from "../../../../assets/icons/error.svg"

// import { GoogleMap, Marker } from "react-google-maps"
// import withGoogleMap from "react-google-maps/lib/withGoogleMap";
// import withScriptjs from "react-google-maps/lib/withScriptjs";

const CREATECONTACT = gql`
mutation CreateContact ($name: String!, $surname: String!, $email: String!, $phone: String!, $message: String!) {
  createContact(input:
        { data: {
            name: $name,
            surname: $surname,
            email: $email,
            phone: $phone,
            message: $message
        }}) {
          contact {
                id
            }
        }
      }
`;

const Contact = () => {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [showSuccess, setShowSuccess] = useState(false);

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

    const [onMutationSubmit, { loading, error, data }] = useMutation(CREATECONTACT, {
        onCompleted: data => {
            reset();
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
            }, 3000);
        }
    });

    const onFormSubmit = (data) => {
        console.log(data);
        onMutationSubmit({
            variables: {
                name: data.name,
                surname: data.surname,
                email: data.email,
                phone: data.phone,
                message: data.message
            }
        })
    }

    // const MapWithAMarker = withScriptjs(withGoogleMap(props =>
    //     <GoogleMap
    //         defaultZoom={8}
    //         defaultCenter={{ lat: -34.397, lng: 150.644 }}
    //     >
    //         <Marker
    //             position={{ lat: -34.397, lng: 150.644 }}
    //         />
    //     </GoogleMap>
    // ));

    return (
        <>
            <Breadcrumbs />
            {/* <MapWithAMarker
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            /> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12151.99589859023!2d49.8628555!3d40.4088735!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xed0aa7ade5baf386!2sDHL%20Express%20Azerbaijan!5e0!3m2!1sen!2s!4v1654235156412!5m2!1sen!2s" height="400" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            {/* <div className="contact_banner" style="background: url('../../assets/images/map.jpg'); background-size: cover;"></div> */}
            <div className="container page_content contact_content">
                <div className="contact_address">
                    <p className="address_name">DHL Express Ofisi</p>
                    <div className="phone d-flex align-items--center address_item">
                        <img className="phone_icon" src={AddressIcon} alt="" />
                        <span>Street 1, 7 Əhməd Rəcəbli, Bakı 1075</span>
                    </div>
                    <div className="phone d-flex align-items--center address_item">
                        <img className="phone_icon" src={CallIcon} alt="" />
                        <div>
                        <span>(012) 493 47 14</span>
                        <br/>
                        <span>(012) 493 47 28</span>
                        </div>
                    </div>
                    <div className="phone d-flex align-items--center address_item">
                        <img className="phone_icon" src={EmailIcon} alt="" />
                        <span>azcommerce@dhl.com </span>
                    </div>
                </div>
                <div className="page_data">
                    <h2 className="data_head">Bizə yazın</h2>
                    <form onSubmit={handleSubmit(onFormSubmit)} className="contact_form ">
                        <div className=" ">
                            <div className="form_item">
                                <div className="two_inputs">
                                    <div>
                                        <input className={`form_input ${errors.name ? "error" : ""}`} type="text" placeholder="Ad *"
                                            {...register("name", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                }
                                            })} />
                                        {errors.name ?
                                            <p className="d-flex align-items--center">
                                                <img className="error_icon" src={ErrorIcon} alt="" />
                                                Form field error text.
                                            </p> : ''
                                        }
                                    </div>

                                    <div>
                                        <input className={`form_input ${errors.surname ? "error" : ""}`} type="text" placeholder="Soyad *"
                                            {...register("surname", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                }
                                            })} />
                                        {errors.surname ?
                                            <p className="d-flex align-items--center">
                                                <img className="error_icon" src={ErrorIcon} alt="" />
                                                Form field error text.
                                            </p> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_item">
                                <div className="two_inputs">
                                    <div>
                                        <div className="number_input">
                                            <select className="country_code">
                                                <option value="">994</option>
                                                <option value="">000</option>
                                                <option value="">000</option>
                                            </select>
                                            <input className={`form_input number ${errors.surname ? "error" : ""}`} type="text" placeholder="__ ___ __ __ *"
                                                {...register("phone", {
                                                    required: {
                                                        value: true,
                                                        message: 'Required'
                                                    }
                                                })} />
                                        </div>
                                        {errors.phone ?
                                            <p className="d-flex align-items--center">
                                                <img className="error_icon" src={ErrorIcon} alt="" />
                                                Form field error text.
                                            </p> : ''
                                        }
                                    </div>

                                    <div>
                                        <input className={`form_input ${errors.email ? "error" : ""}`} type="text" placeholder="E-poçt"
                                            {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: 'Required'
                                                },
                                                pattern: {
                                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                                    message: 'Invalid Email'
                                                }
                                            })} />
                                        {errors.email ?
                                            <p className="d-flex align-items--center">
                                                <img className="error_icon" src={ErrorIcon} alt="" />
                                                Form field error text.
                                            </p> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form_item">
                                <p className="message">Mesajınız</p>
                                <textarea name="" className="form_input form_textarea" cols="30" rows="10"
                                    placeholder="Mesajınızı buraya yazın"
                                    {...register("message", {
                                        // required: {
                                        //     value: true,
                                        //     message: 'Required'
                                        // }
                                    })}></textarea>
                            </div>
                            <div className="success_message d-flex justify-content--space-between align-items--center">
                                {showSuccess ?
                                    <div className="success_l d-flex">
                                        <img className="check_icon" src={CheckIcon} alt="" />
                                        <p>Mesajınız uğurla göndərildi.</p>
                                    </div> : ''
                                }
                            </div>
                            <div className="form_submit_parent m-0 justify-content--flex-end">
                                <button className="form_submit ">
                                    Növbəti
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
