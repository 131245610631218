import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import ReactMarkdown from "react-markdown";

import { NavLink, useLocation } from 'react-router-dom';
import {
  useQuery,
  gql
} from "@apollo/client";

const FAQ = gql`
query GetFaqs($_locale: String!){
  faqs(locale: $_locale) {
      item{
        name,
        description
      }
    }
  }
`;

// parceldeliveryterm

const Faq = () => {
  let loc = useLocation();
  let [lang, setLang] = useState(loc.pathname.split('/')[1]);
  let [faqs, setFaqs] = useState(null);

  useQuery(FAQ, {
    variables: {
      _locale: lang
    },
    onCompleted(data) {
      if (data?.faqs) {
        setFaqs(data.faqs);
      }
    }
  });

  return (
    <>
      <div className="page_data">
        <div className="collapsible_list">
          {faqs?.map((faq, i) => {
            return (
              <div className="collapsible" key={'faq-' + i}>
                <div className='showHide'>
                  <input type="checkbox" id={"colllapse-"+i} />

                  <label htmlFor={"colllapse-"+i} className="collap_faq">
                    <div className='expand'>
                      <p className="collap_head">{faq?.item?.name}</p>
                    </div>
                    <span className="changeArrow arrow-up toggle_collapse">-</span>
                    <span className="changeArrow arrow-dn toggle_collapse">+</span>
                  </label>

                  <div className="fieldsetContainersFaq">
                    <ReactMarkdown className="collap_desc">
                      {faq?.item?.description}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
};

Faq.propTypes = {};

Faq.defaultProps = {};

export default Faq;
